export const estadoInicialClasesValidacion = {
  nombre: "",
  a_paterno: "",
  a_materno: "",
  imss: "",
  file_imss: "",
  curp: "",
  file_curp: "",
  rfc: "",
  file_rfc: "",
  dir_fisc: "",
  dir: "",
  correo: "",
  tel: "",
  fecha_nac: "",
  identificacion: "",
  file_identificacion: "",
  file_acta: "",
  file_foto_inf: "",
  file_foto_fir: "",
  fecha_ing: "",
  puesto: "",
  departamento: "",
  empresa: "",
  fecha_mov: "",
  cuenta_nomina: "",
  numero_tarjeta: "",
  clabe_interbancaria: "",
  banco: "",
  beneficiario: "",
};

export type IClasesValidacion = typeof estadoInicialClasesValidacion;
