import api from "../api.service";

interface ISendSMSVerify {
  idEmpleado: number;
  telefono: string;
  nombre: string;
  empresa: string;
}

const sendSMSVerify = (params: ISendSMSVerify) => {
  const { idEmpleado, telefono, nombre, empresa } = params;
  return new Promise((resolve, reject) => {
    api
      .get(
        `empleado/validar/telefono?id_empleado=${idEmpleado}&telefono=${telefono}&nombre=${nombre}&empresa=${empresa}`
      )
      .then(() => {
        resolve("SMS de verificación enviado con éxito");
      })
      .catch((error) => {
        console.log(error);
        reject("Error al enviar el SMS de verificación");
      });
  });
};

export default sendSMSVerify;
