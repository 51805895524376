import React from "react";
import { Typography } from "@mui/material";
import imagenes from "../../constants/imagenes";

import "../notfound/NotFound.css";
const NotFound = () => {
  return (
    <div className="fondo">
      <div className="imagen">
        <div>
          <Typography
            sx={{ typography: { xl: "h3", xs: "h3" } }}
            className="texto404"
          >
            {" "}
            404{" "}
          </Typography>
        </div>
        <div>
          <img
            className="center filter-white mt-5"
            src={imagenes.ErrorSvg}
            alt="logo"
            height={400}
            width={700}
          />
        </div>
      </div>
      <div className="my-4">
        <Typography
          sx={{ typography: { xl: "h3", xs: "h5" } }}
          align="center"
          className="textoPage"
        >
          {" "}
          PAGE NOT FOUND{" "}
        </Typography>
      </div>
      <div>
        <Typography
          sx={{ typography: { xl: "h5", xs: "h6" } }}
          align="center"
          className="textoPage"
        >
          The requested URL doesn’t exist, was not found on this server.
          <span>Sorry</span>
        </Typography>
      </div>
      <div>
        <Typography
          sx={{ typography: { xl: "h1", xs: "h3" } }}
          align="center"
          className="textoPage"
        >
          {" "}
          ORBEIT{" "}
        </Typography>
      </div>
    </div>
  );
};

export default NotFound;
