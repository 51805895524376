export interface IUserLogin {
  id: string;
  username: string;
  password: string;
}

export interface IUser {
  id: string;
  username: string;
  accessToken: string;
}

export default (): IUser => {
  const user: IUser = JSON.parse(localStorage.getItem("user") || "{}");
  return user;
};
