import React, { useState } from "react";
import NavBar from "../navbar/NavBar";
import Subnavbar from "../subnavbar/Subnavbar";
import { Row, Col } from "react-bootstrap";
import { Typography, Button } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";

import axios from "axios";
import { API_PORTAL_URL } from "../../constants";
import jwt_decode from "jwt-decode";

import "./Employees.css";
import EmployeeTable from "./EmployeeTable";
import getUserLocal from "../../utils/getUserLocal";

interface IEmployees {
  id: number;
  id_empleado: string;
  status: number;
  status_label: string[];
  name: string;
  last_name: string;
  department: string;
  job: string;
  progress: number;
}

const Employees = () => {
  const location: any = useLocation();
  const history = useHistory();

  const user = getUserLocal();
  const [userType, setUserType] = useState("4");

  const [image, setImage] = useState("");

  const [errMssg, setErrMssg] = useState("");
  const [empleados, setEmpleados] = useState<IEmployees[]>([]);

  const id_empresa = location.search.replace("?/", "");

  React.useEffect(() => {
    setImage(getImage());

    if (user) {
      const decodedToken: any = jwt_decode(user.accessToken);

      setUserType(decodedToken.rol);
    }

    updateList();
  }, []);

  const updateList = () => {
    // console.log('Update');

    const archive_status = [
      "identificacion_status",
      "imss_status",
      "curp_status",
      "rfc_status",
      "acta_status",
      "foto_status",
      "firma_status",
      "correo_status",
      "telefono_status",
      "cuenta_status",
    ];

    axios
      .post(API_PORTAL_URL + "empleados", {
        id_empresa,
      })
      .then((response) => {
        const emps = [];

        for (let i = 0; i < response.data.length; i++) {
          const emp: any = response.data[i];

          const status = parseInt(emp.status);
          const status_labels = [
            ["Ingresado", "cir-gray"],
            ["Enviado", "cir-blue"],
            ["En Proceso de Validación", "cir-green"],
            ["Corregir", "cir-yellow"],
            ["Cancelado", "cir-red"],
            ["Validado", "cir-green"],
            ["Baja", "cir-brown"],
          ];
          let status_label;

          if (status === 2) {
            let max = 0;

            for (let j = 0; j < archive_status.length; j++) {
              max =
                parseInt(emp[archive_status[j]]) > max
                  ? parseInt(emp[archive_status[j]])
                  : max;
            }

            status_label =
              max === 0 ? status_labels[2] : status_labels[1 + max];
          } else {
            status_label = status_labels[status];
          }

          emps.push({
            id: emp.id,
            id_empleado: emp.id_empleado,
            status,
            status_label,
            name: emp.nombre,
            last_name: emp.apellido_paterno + " " + emp.apellido_materno,
            department: emp.departamento,
            job: emp.puesto,
            progress: parseInt(emp.avance),
          });
        }

        setEmpleados(emps);
      })
      .catch((response) => {
        setErrMssg(response.response.data.message);
      });
  };

  function getImage() {
    const comps = location.state.companies;
    const emp = comps.filter((e: any) => e.id_empresa === id_empresa);

    return emp[0].image;
  }

  const onNewClick = () => {
    history.push({
      pathname: "/captura",
      search: "/" + id_empresa,
      state: {
        companies: location.state.companies,
        image,
      },
    });
  };

  const onLogClick = () => {
    history.push({
      pathname: "/log",
      search: "/" + id_empresa,
      state: {
        companies: location.state.companies,
        image,
      },
    });
  };

  return (
    <div>
      <div className="fixed">
        <NavBar />

        <Subnavbar image={image} />

        <Row>
          <Col className="p-0">
            <Button
              className="new-btn"
              onClick={onNewClick}
              disabled={userType === "3" || userType === "4"}
            >
              Nuevo Empleado
            </Button>
          </Col>
          <Col className="p-0" hidden={userType === "3" || userType === "4"}>
            <Button className="new-btn log-btn" onClick={onLogClick}>
              Registro de Actividad
            </Button>
          </Col>
        </Row>

        {errMssg === "" ? (
          <EmployeeTable
            empleados={empleados}
            history={history}
            companies={location.state.companies}
            userType={userType}
            updateEmployees={updateList}
            image={image}
            id_empresa={id_empresa}
          />
        ) : (
          <Typography className="mt-5" variant="h5" align="center">
            {" "}
            {errMssg}{" "}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default Employees;
