import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import "./CompanyCard.css";
import { insertLog } from "../../actions/log-insert";
import { useHistory } from "react-router-dom";
import ICompanyCard from "./ICompanyCard";
import getTotalUsuarios from "../../services/api/getTotalUsuarios";
import getTotalAltas, {
  IGetTotalAltasResponse,
} from "../../services/api/getTotalAltas";

const CompanyCard = (props: ICompanyCard) => {
  const [totalUsuarios, setTotalUsuarios] = useState<number>();
  const [altas, setAltas] = useState<number>();
  const [validados, setValidados] = useState<number>();
  const [no_validados, setNoValid] = useState<number>();
  const [cancelados, setCancelados] = useState<number>();

  const history = useHistory();

  const images = require.context("../../assets/companies", true);

  React.useEffect(() => {
    const inicio = props.inicio;
    const fin = props.fin;

    const handleGetNumUsuariosEmpresa = (
      inicio: Date,
      fin: Date
    ): Promise<[number, IGetTotalAltasResponse]> => {
      return Promise.all([
        getTotalUsuarios(props.empresa.id_empresa),
        getTotalAltas({
          idEmpresa: props.empresa.id_empresa,
          inicio,
          fin,
        }),
      ]);
    };

    handleGetNumUsuariosEmpresa(inicio, fin).then((response) => {
      setTotalUsuarios(response[0]);
      setAltas(response[1].altas);
      setValidados(response[1].validados);
      setNoValid(response[1].noValidados);
      setCancelados(response[1].cancelados);
    });
  }, [props.empresa.id_empresa, props.inicio, props.fin]);

  const onClick = () => {
    insertLog(
      props.empresa.id_empresa,
      "EMPDEMO",
      "-",
      "-",
      "Ingreso al Listado"
    );

    history.push({
      pathname: "/empleados",
      search: "/" + props.empresa.id_empresa,
      state: { companies: props.companies },
    });
  };

  return (
    <Card className="card">
      <img className="img-card" src={images(props.empresa.image)} alt="logo" />

      <CardContent className="card-content">
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow key="1" className="odd-row">
                <TableCell className="data-label">Total de Usuarios:</TableCell>
                <TableCell align="left">{totalUsuarios}</TableCell>
              </TableRow>
              <TableRow key="2">
                <TableCell className="data-label">Altas:</TableCell>
                <TableCell align="left">{altas}</TableCell>
              </TableRow>
              <TableRow key="3" className="odd-row">
                <TableCell className="data-label">
                  Usuarios Validados:
                </TableCell>
                <TableCell align="left">{validados}</TableCell>
              </TableRow>
              <TableRow key="4">
                <TableCell className="data-label">
                  Faltantes de Validar:
                </TableCell>
                <TableCell align="left">{no_validados}</TableCell>
              </TableRow>
              <TableRow key="5" className="odd-row">
                <TableCell className="data-label">Cancelados:</TableCell>
                <TableCell align="left">{cancelados}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <CardActions>
        <Button className="more-btn" size="small" onClick={onClick}>
          Ver más
        </Button>
      </CardActions>
    </Card>
  );
};

export default CompanyCard;
