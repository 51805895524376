import React, { useState } from "react";

import { Row, Col } from "react-bootstrap";
import { Typography } from "@mui/material";
import DatePicker from "react-date-picker/dist/entry.nostyle";

import "./DatePickers.css";

const DatePickers = (props) => {
  const date = new Date();

  const dateEnd = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0
  );
  const dateStart = dateEnd - 1000 * 60 * 60 * 24 * 15;

  // var date2 = new Date(date);

  const [valueStart, setStart] = useState(new Date(dateStart));
  const [valueEnd, setEnd] = useState(new Date(dateEnd));

  React.useEffect(() => {
    props.onChange(valueStart, valueEnd);
  }, [valueStart, valueEnd, props]);

  return (
    <Row className="pickers py-3 px-5">
      <Col md={{ span: 1, offset: 6 }} className="pt-2">
        <Typography variant="h6" align="right">
          {" "}
          Inicio:{" "}
        </Typography>
      </Col>
      <Col md={{ span: 2 }}>
        <DatePicker onChange={setStart} value={valueStart} />
      </Col>
      <Col md={{ span: 1 }} className="pt-2">
        <Typography variant="h6" align="right">
          {" "}
          Fin:{" "}
        </Typography>
      </Col>
      <Col md={{ span: 2 }}>
        <DatePicker onChange={setEnd} value={valueEnd} />
      </Col>
    </Row>
  );
};

export default DatePickers;
