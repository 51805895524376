import React from "react";
import { Col, Row } from "react-bootstrap";
import { Typography, ButtonGroup, Button } from "@mui/material";
import { useHistory } from "react-router-dom";

import "./Subnavbar.css";

const Subnavbar = ({ image = "" }: { image?: string }) => {
  const images = require.context("../../assets/companies", true);
  const history = useHistory();

  return (
    <Row className="my-4 mx-5 align-items-center subnavbar">
      <Col md={{ span: 3 }} align="center">
        <Button onClick={() => history.push({ pathname: "/empresas" })}>
          {image === "" ? (
            <Typography
              id="title"
              sx={{ typography: { xl: "h1" }, fontSize: { lg: 75 } }}
              align="center"
            >
              {" "}
              ORBEIT{" "}
            </Typography>
          ) : (
            <img className="subnav-img" src={images(image)} alt="logo" />
          )}
        </Button>
      </Col>
      <Col md={{ span: 9 }}>
        <ButtonGroup id="btn-grp">
          <Button id="selected" className="btn textBotones">
            Empleados
          </Button>
          <Button className="btn textBotones">Reclutamiento</Button>
          <Button className="btn textBotones">Cumpleaños</Button>
          <Button className="btn textBotones">Vacaciones</Button>
        </ButtonGroup>
      </Col>
    </Row>
  );
};

export default Subnavbar;
