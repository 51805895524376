import React from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { Person } from "@mui/icons-material";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useHistory } from "react-router-dom";
import { logout } from "../../actions/auth";
import useGetEmpresas from "../../hooks/useGetEmpresas";
import getUserLocal from "../../utils/getUserLocal";

import "./NavBar.css";

const NavBar = () => {
  const user = getUserLocal();
  const [empresas, loading] = useGetEmpresas(user.id);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const logOut = () => {
    dispatch(logout());
  };

  const onCompanyClick = (id_empresa: string) => {
    history.push({
      pathname: "/empleados",
      search: "/" + id_empresa,
      state: { companies: empresas },
    });
    window.location.reload();
  };

  return (
    <Nav className="justify-content-end cherry py-2" variant="pills">
      <NavDropdown
        className="cherry text-white"
        title="Empresas "
        id="nav-dropdown"
        disabled={loading}
      >
        {empresas.map((empresa) => {
          return (
            <NavDropdown.Item
              className="text-white cherry"
              key={empresa.id_empresa}
              eventKey={empresa.id_empresa}
              onClick={() => onCompanyClick(empresa.id_empresa)}
            >
              {empresa.alias}
            </NavDropdown.Item>
          );
        })}
      </NavDropdown>
      <Nav.Item className="mx-4">
        <Nav.Link
          className="text-white"
          href="/login"
          eventKey="logout"
          id="logout"
          onClick={logOut}
        >
          <Person className="icon" />
          Cerrar Sesión
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default NavBar;
