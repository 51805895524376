import React from "react";
import { Typography, Box, CircularProgress } from "@mui/material";

import "./ProgressCircle.css";

const ProgressCircle = (props) => {
  const green = (props.greens * 100) / props.total;
  const yellow = (props.yellows * 100) / props.total;
  const red = (props.reds * 100) / props.total;

  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        value={green}
        size="140px"
        id="circle-green"
      />
      <CircularProgress
        variant="determinate"
        value={green + yellow}
        size="140px"
        id="circle-yellow"
      />
      <CircularProgress
        variant="determinate"
        value={green + yellow + red}
        size="140px"
        id="circle-red"
      />
      <CircularProgress
        variant="determinate"
        value={100}
        size="140px"
        id="circle-bg"
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          fontWeight="bold"
          id="percent"
        >
          {props.progress}%
        </Typography>
      </Box>
    </Box>
  );
};

export default ProgressCircle;
