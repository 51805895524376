import React, { useEffect, useState } from "react";
import NavBar from "../navbar/NavBar";
import { Row, Col, Form, ProgressBar, InputGroup } from "react-bootstrap";
import Subnavbar from "../subnavbar/Subnavbar";
import { Button, Input, Typography, IconButton } from "@mui/material/";
import { ArrowUpward, Plagiarism } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Document, Page } from "react-pdf";
import { ToastContainer } from "react-toastify";
import getUserLocal from "../../utils/getUserLocal";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useHistory } from "react-router-dom";
import { IArchivoMigracion } from "./migración";
import getMotivoMovimientos from "../../services/api/getMotivoMovimientos";
import dayjs from "dayjs";

import axios, { AxiosError } from "axios";
import {
  API_PORTAL_URL,
  ARCHIVOS_SOPORTADOS,
  CAMPOS_OBLIGATORIOS_CONST,
  CAMPOS_OBLIGATORIOS,
} from "../../constants";

import "./Capture.css";
import IMovimiento from "./IMovimiento";
import IPuesto from "./IPuesto";
import IDepartamento from "./IDepartamento";
import IMotivoMovimiento from "./IMotivoMovimiento";
import { ICompany } from "../companies/ICompany";
import {
  estadoInicialMensajes,
  excepcionNombres,
  IMensajes,
  mensajes,
  mensajesErrorOtros,
  mensajesFile,
} from "./mensajes";
import useGetEmpresas from "../../hooks/useGetEmpresas";
import { estadoInicialMigración } from "./migración";
import { crearEstadoInicialEmpleado, IEmpleado } from "./empleado";
import {
  estadoInicialClasesValidacion,
  IClasesValidacion,
} from "./clasesValidacion";
import { envioToast, guardadoToast, migradoToast } from "./notificaciones";
import getEmpresas from "../../services/api/getEmpresas";
import getMovimientos from "../../services/api/getMovimientos";
import getPuestos from "../../services/api/getPuestos";
import getDepartamentos from "../../services/api/getDepartamentos";
import validarRFC from "../../utils/validarRFC";
import validarTelefono from "../../utils/validarTelefono";
import validarNSS from "../../utils/calidarNSS";
import validarCURP from "../../utils/validarCURP";
import validarCorreo from "../../utils/validarCorreoElectronico";
import getValidarExistenciaCurp from "../../services/api/getValidacionCurp";

const Captura = () => {
  const idUser = getUserLocal().id;
  const history = useHistory();
  const location = useLocation<any>();
  const empresa_seleccionada = location.search.split("?/")[1];
  /* --------------------------------Hooks para llenar los Select--------------------------------- */
  const [departamentos, setDepartamentos] = useState<IDepartamento[]>([]);
  const [puestos, setPuestos] = useState<IPuesto[]>([]);
  const [movimientos, setMovimientos] = useState<IMovimiento[]>([]);
  const [disabledMotivo, setDisabledMotivo] = useState<boolean>(true);
  const [selectedMotivo, setSelectedMotivo] = useState<string>("MOTMOV0");
  const [motivoMovimientos, setMotivoMovimientos] = useState<
    IMotivoMovimiento[]
  >([]);
  const [empresas, cargando] = useGetEmpresas(idUser);
  const [todasEmpresas, setEmpresas] = useState<ICompany[]>([]);
  /* ------------------------------Hooks para modales y funcionalidades----------------------------- */
  const [openCURPModal, setOpenCURPModal] = useState<boolean>(false);
  const [nombreEmpresaSeleccionada, setNombreEmpresaSeleccionada] =
    useState<string>("");
  const [empresaEmpleado, setEmpresaEmpleado] = useState<string>("");
  const [openRepetidoEmpresaModal, setOpenRepetidoEmpresaModal] =
    useState<boolean>(false);
  const [botonGuardarClass, setBotonGuardarClass] =
    useState("estiloBotonGuardar");
  const [botonEnviarClass, setBotonEnviarClass] = useState<string>(
    "botonCapturaEnviarDesactivado"
  );
  const [idEmpleadoRepetido, setIdEmpleadoRepetido] = useState<string>("");
  /* -------------------Hooks para el funcionamiento de los botones de ver archivo------------------ */
  const [buttonFile, setButtonFile] = useState({
    file_imss: false,
    file_curp: false,
    file_rfc: false,
    file_identificacion: false,
    file_acta: false,
    file_foto_inf: false,
    file_foto_fir: false,
  });
  /* ----------------Hooks para saber si se conserva o no el archivo de la migracion---------------- */

  const [archivoMigracionActual, setArchivoMigracionActual] =
    useState<IArchivoMigracion>(estadoInicialMigración);

  // const id_empresa = location.search.replace('?/', ''); // <-- Aquí está el ID de la empresa
  /* ----------------------------Objeto para crear el nuevo empleado------------------------------- */

  const [EMPLEADO, setEmpleado] = useState<IEmpleado>(
    crearEstadoInicialEmpleado(empresa_seleccionada, idUser)
  );

  /* Excepción de campos para poder enviar la información del empleado a validar */
  const excepcionNombresEnviar = ["fecha_alta", "fecha_ant", "id_empleado"];
  /* ----------------------------Hooks para validar los campos obligatorios---------------------------- */
  const [mensajeError, setMensaje] = useState<IMensajes>(estadoInicialMensajes);
  const [classInvalid, setClassInvalid] = useState<IClasesValidacion>(
    estadoInicialClasesValidacion
  );
  /* ---------------------------------------Hooks y Funciones para imagenes y pdf------------------------------------------------ */
  const [openIMGModal, setOpenIMGModal] = useState(false);
  const [urlsImagen, setUrlsImagen] = useState<string>("");
  const [urlsPDF, setUrlsPDF] = useState<string>("");
  const [nombreImagenMostrada, setNombreImagenMostrada] = useState<string>("");
  const [esPDF, setEsPDF] = useState<boolean>(false);
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [archivosMigracions, setArchivosMigracion] =
    useState<IArchivoMigracion>({
      file_imss: "",
      file_curp: "",
      file_rfc: "",
      file_identificacion: "",
      file_acta: "",
      file_foto_inf: "",
      file_foto_fir: "",
    });

  /* ---Funciones para detectar los movimientos y dependiendo el elegido se cambien los motivos--- */
  function handleMovimientosChange(e: React.ChangeEvent<HTMLSelectElement>) {
    if (e.target.value !== "0") {
      setDisabledMotivo(false);
      getMotivoMovimientos(e.target.value);
      setSelectedMotivo("");
      setEmpleado((EMPLEADO) => ({
        ...EMPLEADO,
        [e.target.name]: e.target.value,
      }));
    } else {
      setDisabledMotivo(true);
      setSelectedMotivo("");
      setEmpleado((EMPLEADO) => ({
        ...EMPLEADO,
        [e.target.name]: "",
      }));
    }
  }

  function handleMotivoMovimientoChange(
    e: React.ChangeEvent<HTMLSelectElement>
  ) {
    if (e.target.value !== "0") {
      setEmpleado((EMPLEADO) => ({
        ...EMPLEADO,
        [e.target.name]: e.target.value,
      }));
      setSelectedMotivo(e.target.value);
    } else {
      setEmpleado((EMPLEADO) => ({
        ...EMPLEADO,
        [e.target.name]: "",
      }));
      setSelectedMotivo("");
    }
  }

  /* -------Función para detectar el cambio de los input y validar aquellos que son obligatorios-------- */
  function handleChangeInfo(e: React.ChangeEvent<HTMLInputElement> | any) {
    let { name, value } = e.target;
    const obligatorio = e.target.className.split(" ");
    validarEnviarGuardarEstado();
    if (obligatorio.includes("obligatorio")) {
      if (value === "") {
        setMensaje((mensajeError) => ({
          ...mensajeError,
          [name]: mensajes[name as keyof typeof mensajes],
        }));
        setClassInvalid((classInvalid) => ({
          ...classInvalid,
          [name]: "is-invalid",
        }));
      } else {
        setMensaje((mensajeError) => ({
          ...mensajeError,
          [name]: "",
        }));
        setClassInvalid((classInvalid) => ({
          ...classInvalid,
          [name]: "",
        }));
      }
    }
    /* Parte para pasar el nombre al beneficiario */
    if (name === "nombre") {
      if (
        value !== "" &&
        EMPLEADO.a_paterno !== "" &&
        EMPLEADO.a_materno !== ""
      ) {
        setEmpleado((EMPLEADO) => ({
          ...EMPLEADO,
          beneficiario:
            value + " " + EMPLEADO.a_paterno + " " + EMPLEADO.a_materno,
        }));
      }
    }
    if (name === "a_paterno") {
      if (value !== "" && EMPLEADO.nombre !== "" && EMPLEADO.a_materno !== "") {
        setEmpleado((EMPLEADO) => ({
          ...EMPLEADO,
          beneficiario:
            EMPLEADO.nombre + " " + value + " " + EMPLEADO.a_materno,
        }));
      }
    }
    if (name === "a_materno") {
      if (value !== "" && EMPLEADO.nombre !== "" && EMPLEADO.a_paterno !== "") {
        setEmpleado((EMPLEADO) => ({
          ...EMPLEADO,
          beneficiario:
            EMPLEADO.nombre + " " + EMPLEADO.a_paterno + " " + value,
        }));
      }
    }
    /* Parte para validar la CURP */
    if (name === "curp") {
      setBotonGuardarClass("estiloBotonGuardar");
      if (value === "" && obligatorio.includes("obligatorio")) {
        setMensaje((mensajeError) => ({
          ...mensajeError,
          [name]: mensajes[name as keyof typeof mensajes],
        }));
        setClassInvalid((classInvalid) => ({
          ...classInvalid,
          [name]: "is-invalid",
        }));
      } else {
        if (value !== "") {
          EMPLEADO.autorizacion_migracion = "0";
          value = value.toUpperCase();
          const [errCURP, msnCURP] = validarCURP(value);
          if (errCURP) {
            setMensaje((mensajeError) => ({
              ...mensajeError,
              [name]: msnCURP,
            }));
            setClassInvalid((classInvalid) => ({
              ...classInvalid,
              [name]: "is-invalid",
            }));
          } else {
            const validarCURPExistente = validarExistenciaCURP(value);
            setMensaje((mensajeError) => ({
              ...mensajeError,
              [name]: "",
            }));
            setClassInvalid((classInvalid) => ({
              ...classInvalid,
              [name]: "",
            }));
          }
        } else {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [name]: "",
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [name]: "",
          }));
        }
      }
    }
    /* Parte para validar el IMSS */
    if (name === "imss") {
      if (value === "" && obligatorio.includes("obligatorio")) {
        setMensaje((mensajeError) => ({
          ...mensajeError,
          [name]: mensajes[name as keyof typeof mensajes],
        }));
        setClassInvalid((classInvalid) => ({
          ...classInvalid,
          [name]: "is-invalid",
        }));
      } else {
        if (value !== "") {
          const [err, msn] = validarNSS(value);
          if (err) {
            setMensaje((mensajeError) => ({
              ...mensajeError,
              [name]: msn,
            }));
            setClassInvalid((classInvalid) => ({
              ...classInvalid,
              [name]: "is-invalid",
            }));
          } else {
            setMensaje((mensajeError) => ({
              ...mensajeError,
              [name]: "",
            }));
            setClassInvalid((classInvalid) => ({
              ...classInvalid,
              [name]: "",
            }));
          }
        } else {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [name]: "",
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [name]: "",
          }));
        }
      }
    }
    /* Parte para validar el RFC */
    if (name === "rfc") {
      if (value === "" && obligatorio.includes("obligatorio")) {
        setMensaje((mensajeError) => ({
          ...mensajeError,
          [name]: mensajes[name as keyof typeof mensajes],
        }));
        setClassInvalid((classInvalid) => ({
          ...classInvalid,
          [name]: "is-invalid",
        }));
      } else {
        if (value !== "") {
          value = value.toUpperCase();
          const [errRFC, msnRFC] = validarRFC(value);
          if (errRFC) {
            setMensaje((mensajeError) => ({
              ...mensajeError,
              [name]: msnRFC,
            }));
            setClassInvalid((classInvalid) => ({
              ...classInvalid,
              [name]: "is-invalid",
            }));
          } else {
            setMensaje((mensajeError) => ({
              ...mensajeError,
              [name]: "",
            }));
            setClassInvalid((classInvalid) => ({
              ...classInvalid,
              [name]: "",
            }));
          }
        } else {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [name]: "",
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [name]: "",
          }));
        }
      }
    }
    /* Validar el formato del correo, esta validación se pone acá por si el correo no es obligatorio */
    if (name === "correo") {
      if (value === "" && obligatorio.includes("obligatorio")) {
        setMensaje((mensajeError) => ({
          ...mensajeError,
          [name]: mensajes[name as keyof typeof mensajes],
        }));
        setClassInvalid((classInvalid) => ({
          ...classInvalid,
          [name]: "is-invalid",
        }));
      } else {
        if (!validarCorreo(value) && value !== "") {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [name]: "El formato del correo es inválido",
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [name]: "is-invalid",
          }));
        } else {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [name]: "",
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [name]: "",
          }));
        }
      }
    }
    /* Validar la longitud del teléfono, esta validación se pone acá por si el teléfono no es obligatorio */
    if (name === "tel") {
      if (value === "" && obligatorio.includes("obligatorio")) {
        setMensaje((mensajeError) => ({
          ...mensajeError,
          [name]: mensajes[name as keyof typeof mensajes],
        }));
        setClassInvalid((classInvalid) => ({
          ...classInvalid,
          [name]: "is-invalid",
        }));
      } else {
        if (value !== "" && !validarTelefono(value)) {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [name]: mensajesErrorOtros.errorTel,
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [name]: "is-invalid",
          }));
        } else {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [name]: "",
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [name]: "",
          }));
        }
      }
    }
    /* Parte para validar las longitudes de los datos bancarios */
    if (
      name === "cuenta_nomina" ||
      name === "clabe_interbancaria" ||
      name === "numero_tarjeta"
    ) {
      if (name === "cuenta_nomina") {
        if (value !== "" && validarCuentaNomina(value)) {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [name]: mensajesErrorOtros.errorCtaNom,
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [name]: "is-invalid",
          }));
        } else {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [name]: "",
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [name]: "",
          }));
        }
      } else if (name === "clabe_interbancaria") {
        if (value !== "" && validarCLABE(value)) {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [name]: mensajesErrorOtros.errorCLABE,
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [name]: "is-invalid",
          }));
        } else {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [name]: "",
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [name]: "",
          }));
        }
      } else {
        if (value !== "" && validarNumTar(value)) {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [name]: mensajesErrorOtros.errorNumTar,
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [name]: "is-invalid",
          }));
        } else {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [name]: "",
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [name]: "",
          }));
        }
      }
    }
    /* Parte para válidar los select de puesto y departamento */
    if (name === "puesto" || name === "departamento") {
      /* Se validan los campos select */
      if (value === "0") {
        setMensaje((mensajeError) => ({
          ...mensajeError,
          [name]: mensajes[name as keyof typeof mensajes],
        }));
        setClassInvalid((classInvalid) => ({
          ...classInvalid,
          [name]: "is-invalid",
        }));
      } else {
        setMensaje((mensajeError) => ({
          ...mensajeError,
          [name]: "",
        }));
        setClassInvalid((classInvalid) => ({
          ...classInvalid,
          [name]: "",
        }));
      }
    }
    /* Cuando se da click en enviar y guardar se pintan los mensajes de error, con este fragmento de código se eliminan si no es obligaotio */
    if (
      value !== "" &&
      !obligatorio.includes("obligatorio") &&
      !excepcionNombres.includes(name)
    ) {
      setMensaje((mensajeError) => ({
        ...mensajeError,
        [name]: "",
      }));
      setClassInvalid((classInvalid) => ({
        ...classInvalid,
        [name]: "",
      }));
    }
    setEmpleado((EMPLEADO) => ({
      ...EMPLEADO,
      [name]: value,
    }));
  }

  function maxLengthCheck(e: any) {
    if (e.target.value.length > e.target.maxLength)
      e.target.value = e.target.value.slice(0, e.target.maxLength);
  }

  /* Función que envía una petición al back para validar si el CURP ya esta registrado o no */
  const validarExistenciaCURP = async (curp: string) => {
    try {
      // await axios.post(API_PORTAL_URL + "validar/curp", {
      //   curp,
      //   id_empresa: empresa_seleccionada,
      // });
      await getValidarExistenciaCurp(curp, empresa_seleccionada);
    } catch (err) {
      const error = err as AxiosError;
      const mensajeErrorCURP = JSON.parse(error.request.response);
      switch (mensajeErrorCURP.error) {
        case "empleado_repetido":
          setEmpleado((EMPLEADO) => ({
            ...EMPLEADO,
            empleado_repetido: "1",
          }));
          setOpenCURPModal(true);
          setBotonGuardarClass("estiloBotonGuardarBloqueado");
          agregarNombreEmpresaEmpleadoRepetido(mensajeErrorCURP.id_empresa);
          setIdEmpleadoRepetido(mensajeErrorCURP.id_empleado);
          break;
        case "empleado_empresa_repetido":
          setEmpleado((EMPLEADO) => ({
            ...EMPLEADO,
            empleado_repetido_empresa: "1",
          }));
          setOpenRepetidoEmpresaModal(true);
          setBotonGuardarClass("estiloBotonGuardarBloqueado");
          break;
        default:
          break;
      }
      setMensaje((mensajeError) => ({
        ...mensajeError,
        curp: mensajeErrorCURP.message,
      }));
      setClassInvalid((classInvalid) => ({
        ...classInvalid,
        curp: "is-invalid",
      }));
    }
  };

  /* Función para detectar el cambio de los botones de archivos y validar el tipo y peso de los mismos */
  function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    /* Variables y connstantes a utilizar */
    const file = e.target.files || new FileList();
    const name = e.target.id;
    const pesoMaximo = 3; // Peso máximo soportado por los archivos
    const obligatorio = e.target.parentElement?.className.split(" ") || [];
    /* Se limpia lo guardado si existe algo */
    setEmpleado((EMPLEADO) => ({
      ...EMPLEADO,
      [name]: {
        file: "",
        file_name: "",
      },
    }));
    /* Si se agrega un archivo se verifica si todos los campos ya se llenarom */
    validarEnviarGuardarEstado();
    /* Se valida si se cargo un archivo o no */
    if (file.length > 0) {
      const pesoArchivo = file[0].size / 1000000;
      if (!validarExtension(file)) {
        // Se valida si el archivo es soportado
        setMensaje((mensajeError) => ({
          ...mensajeError,
          [name]: mensajesFile.mensaje_general_tipo,
        }));
        setClassInvalid((classInvalid) => ({
          ...classInvalid,
          [name]: "is-invalid",
        }));
        return;
      }
      if (pesoArchivo > pesoMaximo) {
        // Se valida si tiene un peso menor a 3mb
        setMensaje((mensajeError) => ({
          ...mensajeError,
          [name]: mensajesFile.mensaje_general_tam,
        }));
        setClassInvalid((classInvalid) => ({
          ...classInvalid,
          [name]: "is-invalid",
        }));
        return;
      }
      // Si pasa ambas validaciones se quitan los mensajes de error y se cambia el texto del botón por el nombre del archivo
      setEmpleado((EMPLEADO) => ({
        ...EMPLEADO,
        [name]: {
          file: file[0],
          file_name: file[0].name,
        },
      }));
      // En caso de que haya un archivo de migracion y este se cambie se eliminará el que este guardado en base de datos
      setArchivoMigracionActual((archivoMigracionActual) => ({
        ...archivoMigracionActual,
        [name]: false,
      }));
      setMensaje((mensajeError) => ({
        ...mensajeError,
        [name]: "",
      }));
      setClassInvalid((classInvalid) => ({
        ...classInvalid,
        [name]: "",
      }));
      setButtonFile((buttonFile) => ({
        ...buttonFile,
        [name]: true,
      }));
    } else {
      // En caso de que no se carge un archivo se valida si es obligatorio
      if (obligatorio.includes("obligatorio")) {
        // Si es obligatorio se mostrará el mensaje de error
        setMensaje((mensajeError) => ({
          ...mensajeError,
          [name]: mensajesFile[name as keyof typeof mensajesFile],
        }));
        setClassInvalid((classInvalid) => ({
          ...classInvalid,
          [name]: "is-invalid",
        }));
      } else {
        // Si no es obligatorio se quitan los mensajes de error
        setMensaje((mensajeError) => ({
          ...mensajeError,
          [name]: "",
        }));
        setClassInvalid((classInvalid) => ({
          ...classInvalid,
          [name]: "",
        }));
      }
      setButtonFile((buttonFile) => ({
        ...buttonFile,
        [name]: false,
      }));
    }
  }

  /* Función para validar si el archivo ingresado cumple con las extensiones soportadas */
  function validarExtension(file: FileList) {
    const extension = file[0].name.split(".").pop() || "";
    const isSupported = ARCHIVOS_SOPORTADOS.includes(extension);
    return isSupported;
  }

  /* Función donde se validarán todos los datos obligatorios al guardar el empleado */
  function validarDatos() {
    let errores = false;
    CAMPOS_OBLIGATORIOS_CONST.map((campo) => {
      /* Se validan los campos normales */
      if (campo.substring(0, 4) !== "file") {
        if (EMPLEADO[campo] === "") {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [campo]: mensajes[campo as keyof typeof mensajes],
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [campo]: "is-invalid",
          }));
          errores = true;
        } else {
          if (campo === "imss") {
            /* Parte para validar el NSS */
            const [err, msn] = validarNSS(EMPLEADO[campo]);
            if (err) {
              setMensaje((mensajeError) => ({
                ...mensajeError,
                [campo]: msn,
              }));
              setClassInvalid((classInvalid) => ({
                ...classInvalid,
                [campo]: "is-invalid",
              }));
              errores = true;
            } else {
              setMensaje((mensajeError) => ({
                ...mensajeError,
                [campo]: "",
              }));
              setClassInvalid((classInvalid) => ({
                ...classInvalid,
                [campo]: "",
              }));
            }
          } else if (campo === "curp") {
            /* Parte para validar la CURP */
            const [errCURP, msnCURP] = validarCURP(EMPLEADO[campo]);
            if (errCURP) {
              setMensaje((mensajeError) => ({
                ...mensajeError,
                [campo]: msnCURP,
              }));
              setClassInvalid((classInvalid) => ({
                ...classInvalid,
                [campo]: "is-invalid",
              }));
              errores = true;
            } else {
              setMensaje((mensajeError) => ({
                ...mensajeError,
                [campo]: "",
              }));
              setClassInvalid((classInvalid) => ({
                ...classInvalid,
                [campo]: "",
              }));
            }
          } else if (campo === "puesto" || campo === "departamento") {
            /* Se validan los campos select */
            if (EMPLEADO[campo] === "0") {
              setMensaje((mensajeError) => ({
                ...mensajeError,
                [campo]: mensajes[campo],
              }));
              setClassInvalid((classInvalid) => ({
                ...classInvalid,
                [campo]: "is-invalid",
              }));
              errores = true;
            }
          }
        }
      } else {
        /* Se validan los campos de archivos */
        if (EMPLEADO[campo].file === "") {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [campo]: mensajesFile[campo],
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [campo]: "is-invalid",
          }));
          errores = true;
        }
      }
    });
    return errores;
  }

  /* -------------------------------------Función para enviar la información a guardar----------------------- */
  const handleGuardarEmpleado = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validarDatos()) {
      axios
        .post(API_PORTAL_URL + "nuevo/empleado", EMPLEADO, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (EMPLEADO.autorizacion_migracion === "1") {
            migradoToast();
          } else {
            guardadoToast();
          }
          setTimeout(() => {
            history.push({
              pathname: "/empleados",
              search: "/" + empresa_seleccionada,
              state: { companies: empresas },
            });
          }, 1100);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  /* ------------------------------------Funciones para los modales------------------------------------------- */
  /* Función para cerrar el modal del CURP */
  const closeCURPModal = () => {
    setOpenCURPModal(false);
  };

  const closeRepetidoEmpresaModal = () => {
    setOpenRepetidoEmpresaModal(false);
  };
  /* Función para migrar al empleado, es decir, traer toda su información y la coloca en los campos del front. */
  const migrarEmpleado = () => {
    axios
      .post(API_PORTAL_URL + "consultar/empleado", {
        id_empleado: idEmpleadoRepetido,
      })
      .then((response) => {
        const datos_empleado_migracion = response.data.datos_empleado[0];
        const formato_fecha_nacimiento =
          datos_empleado_migracion.fecha_nacimiento !== null &&
          datos_empleado_migracion.fecha_nacimiento !==
            "2000-01-01T06:00:00.000Z"
            ? new Date(datos_empleado_migracion.fecha_nacimiento)
                .toISOString()
                .slice(0, 10)
            : "";
        const formato_fecha_ingreso =
          datos_empleado_migracion.fecha_ingreso !== null &&
          datos_empleado_migracion.fecha_ingreso !== "2000-01-01T06:00:00.000Z"
            ? new Date(datos_empleado_migracion.fecha_ingreso)
                .toISOString()
                .slice(0, 10)
            : "";
        const formato_fecha_alta =
          datos_empleado_migracion.fecha_alta !== null
            ? new Date(datos_empleado_migracion.fecha_alta)
                .toISOString()
                .slice(0, 10)
            : "";
        setEmpleado((EMPLEADO) => ({
          ...EMPLEADO,
          nombre: datos_empleado_migracion.nombre,
          a_paterno: datos_empleado_migracion.apellido_paterno,
          a_materno: datos_empleado_migracion.apellido_materno,
          imss: datos_empleado_migracion.numero_imss,
          curp: datos_empleado_migracion.curp,
          rfc: datos_empleado_migracion.rfc,
          dir_fisc: datos_empleado_migracion.direccion_fiscal,
          dir: datos_empleado_migracion.direccion,
          correo: datos_empleado_migracion.correo_electronico,
          tel: datos_empleado_migracion.telefono,
          fecha_nac: formato_fecha_nacimiento,
          identificacion:
            datos_empleado_migracion.identificacion !== null
              ? datos_empleado_migracion.identificacion
              : "",
          fecha_ing: formato_fecha_ingreso,
          puesto: datos_empleado_migracion.id_puesto,
          departamento: datos_empleado_migracion.id_departamento,
          cuenta_nomina:
            datos_empleado_migracion.cuenta_bancaria !== null
              ? datos_empleado_migracion.cuenta_bancaria
              : "",
          beneficiario:
            datos_empleado_migracion.beneficiario_cuenta !== null
              ? datos_empleado_migracion.beneficiario_cuenta
              : "",
          clabe_interbancaria:
            datos_empleado_migracion.clabe_interbancaria !== null
              ? datos_empleado_migracion.clabe_interbancaria
              : "",
          numero_tarjeta:
            datos_empleado_migracion.numero_tarjeta !== null
              ? datos_empleado_migracion.numero_tarjeta
              : "",
          banco:
            datos_empleado_migracion.banco !== null
              ? datos_empleado_migracion.banco
              : "",
          autorizacion_migracion: "1",
          id_empleado: idEmpleadoRepetido,
          fecha_alta: formato_fecha_alta,
        }));
        /* Se obtienen los archivos que estén registrados en la base de datos */
        /* ------------------Primeramente se obtiene el archivo del Imss-------------------- */
        if (datos_empleado_migracion.ruta_numero_imss !== "") {
          obtenerArchivoGuardado(
            datos_empleado_migracion.ruta_numero_imss,
            "file_imss"
          );
          setButtonFile((buttonFile) => ({
            ...buttonFile,
            file_imss: true,
          }));
          setArchivoMigracionActual((archivoMigracionActual) => ({
            ...archivoMigracionActual,
            file_imss: true,
          }));
        }
        /* ------------------Se obiene el archivo de la CURP-------------------------- */
        if (datos_empleado_migracion.ruta_curp !== "") {
          obtenerArchivoGuardado(
            datos_empleado_migracion.ruta_curp,
            "file_curp"
          );
          setButtonFile((buttonFile) => ({
            ...buttonFile,
            file_curp: true,
          }));
          setArchivoMigracionActual((archivoMigracionActual) => ({
            ...archivoMigracionActual,
            file_curp: true,
          }));
        }
        /* ------------------Se obiene el archivo del RFC-------------------------- */
        if (datos_empleado_migracion.ruta_rfc !== "") {
          obtenerArchivoGuardado(datos_empleado_migracion.ruta_rfc, "file_rfc");
          setButtonFile((buttonFile) => ({
            ...buttonFile,
            file_rfc: true,
          }));
          setArchivoMigracionActual((archivoMigracionActual) => ({
            ...archivoMigracionActual,
            file_rfc: true,
          }));
        }
        /* ------------------Se obiene el archivo de la identificacion-------------------------- */
        if (datos_empleado_migracion.ruta_identificacion !== "") {
          obtenerArchivoGuardado(
            datos_empleado_migracion.ruta_identificacion,
            "file_identificacion"
          );
          setButtonFile((buttonFile) => ({
            ...buttonFile,
            file_identificacion: true,
          }));
          setArchivoMigracionActual((archivoMigracionActual) => ({
            ...archivoMigracionActual,
            file_identificacion: true,
          }));
        }
        /* ------------------Se obiene el archivo del Acta-------------------------- */
        if (datos_empleado_migracion.acta_nacimiento !== "") {
          obtenerArchivoGuardado(
            datos_empleado_migracion.acta_nacimiento,
            "file_acta"
          );
          setButtonFile((buttonFile) => ({
            ...buttonFile,
            file_acta: true,
          }));
          setArchivoMigracionActual((archivoMigracionActual) => ({
            ...archivoMigracionActual,
            file_acta: true,
          }));
        }
        /* ------------------Se obiene el archivo de la foto infantil-------------------------- */
        if (datos_empleado_migracion.foto_infantil !== "") {
          obtenerArchivoGuardado(
            datos_empleado_migracion.foto_infantil,
            "file_foto_inf"
          );
          setButtonFile((buttonFile) => ({
            ...buttonFile,
            file_foto_inf: true,
          }));
          setArchivoMigracionActual((archivoMigracionActual) => ({
            ...archivoMigracionActual,
            file_foto_inf: true,
          }));
        }
        /* ------------------Se obiene el archivo de la foto firma-------------------------- */
        if (datos_empleado_migracion.foto_firma !== "") {
          obtenerArchivoGuardado(
            datos_empleado_migracion.foto_firma,
            "file_foto_fir"
          );
          setButtonFile((buttonFile) => ({
            ...buttonFile,
            file_foto_fir: true,
          }));
          setArchivoMigracionActual((archivoMigracionActual) => ({
            ...archivoMigracionActual,
            file_foto_fir: true,
          }));
        }
        setMensaje((mensajeError) => ({
          ...mensajeError,
          curp: "",
        }));
        setClassInvalid((classInvalid) => ({
          ...classInvalid,
          curp: "",
        }));
        closeCURPModal();
        setBotonGuardarClass("estiloBotonGuardar");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /* ----------------------Función para obtener el documento guardado en la base de datos----------------------- */
  async function obtenerArchivoGuardado(url: string, file: string) {
    const request = await axios
      .post(API_PORTAL_URL + "get/files", {
        responseType: "arraybuffer",
        ruta_archivo: url,
      })
      .then((response) => {
        setArchivosMigracion((archivosMigracions) => ({
          ...archivosMigracions,
          [file]: response,
        }));
        const extension = response.headers["content-type"].split("/");
        setEmpleado((EMPLEADO) => ({
          ...EMPLEADO,
          [file]: {
            file: "actual",
            file_name: file + "." + extension[1],
          },
        }));
      })
      .catch((response_1) => {
        return response_1;
      });
  }

  /* ----------------------Función para pasar un archivo de base64 a un arreglo de buffer----------------------- */
  function base64ToArrayBuffer(base64: string) {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }
  /* -----------------------Función para agregar el nombre de la empresa del empleado repetido--------------------- */
  function agregarNombreEmpresaEmpleadoRepetido(id_empresa: string) {
    todasEmpresas.forEach((element) => {
      if (element.id_empresa == id_empresa) {
        setEmpresaEmpleado(element.alias);
      }
    });
  }
  /* --------------------------------Funciones para el envío y guardado de datos-------------------------------- */
  /* Función para validar que todos los campos estén llenos al momento de envíar un dato */
  function enviarGuardarInfo() {
    let errores = false;
    const nombresDatos = Object.keys(EMPLEADO);
    let banderaCamposBancarios = false;
    nombresDatos.map((campoEmpleado) => {
      /* Se validan los campos normales */
      if (
        campoEmpleado.substring(0, 4) !== "file" &&
        !excepcionNombresEnviar.includes(campoEmpleado)
      ) {
        if (
          campoEmpleado === "cuenta_nomina" ||
          campoEmpleado === "clabe_interbancaria" ||
          campoEmpleado === "numero_tarjeta"
        ) {
          if (EMPLEADO[campoEmpleado] !== "") {
            banderaCamposBancarios = true;
            if (campoEmpleado === "cuenta_nomina") {
              if (validarCuentaNomina(EMPLEADO[campoEmpleado])) {
                errores = true;
              }
            } else if (campoEmpleado === "clabe_interbancaria") {
              if (validarCLABE(EMPLEADO[campoEmpleado])) {
                errores = true;
              }
            } else if (campoEmpleado === "numero_tarjeta") {
              if (validarNumTar(EMPLEADO[campoEmpleado])) {
                errores = true;
              }
            }
          }
        } else if (EMPLEADO[campoEmpleado] === "") {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [campoEmpleado]: mensajes[campoEmpleado],
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [campoEmpleado]: "is-invalid",
          }));
          errores = true;
        } else {
          if (campoEmpleado === "imss") {
            /* Parte para validar el NSS */
            const [err, msn] = validarNSS(EMPLEADO[campoEmpleado]);
            if (err) {
              errores = true;
            }
          } else if (campoEmpleado === "curp") {
            /* Parte para validar la CURP */
            const [errCURP] = validarCURP(EMPLEADO[campoEmpleado]);
            if (errCURP) {
              errores = true;
            } else {
              if (EMPLEADO.empleado_repetido_empresa !== "0") {
                /* Si el empleado ya existe en la empresa, no se dejará enviar ni guardar */
                errores = true;
              } /* Si el empleado ya existe en otra empresa pero no se hará una migración no se dejará envíar ni guardar */ else if (
                EMPLEADO.empleado_repetido !== "0" &&
                EMPLEADO.autorizacion_migracion === "0"
              ) {
                errores = true;
              }
            }
          } else if (campoEmpleado === "rfc") {
            /* Parte para validar el RFC */
            const [errRFC, msnRFC] = validarRFC(EMPLEADO[campoEmpleado]);
            if (errRFC) {
              errores = true;
            }
          } else if (
            campoEmpleado === "puesto" ||
            campoEmpleado === "departamento"
          ) {
            /* Se validan los campos select */
            if (EMPLEADO[campoEmpleado] === "0") {
              setMensaje((mensajeError) => ({
                ...mensajeError,
                [campoEmpleado]: mensajes[campoEmpleado],
              }));
              setClassInvalid((classInvalid) => ({
                ...classInvalid,
                [campoEmpleado]: "is-invalid",
              }));
              errores = true;
            }
          }
        }
      } else {
        /* Se validan los campos de archivos */
        if (EMPLEADO[campoEmpleado].file === "") {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [campoEmpleado]: mensajesFile[campoEmpleado],
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [campoEmpleado]: "is-invalid",
          }));
          errores = true;
        }
      }
    });
    if (!errores && banderaCamposBancarios) {
      axios
        .post(API_PORTAL_URL + "nuevo/empleado", EMPLEADO, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          envioToast();
          setTimeout(() => {
            history.push({
              pathname: "/empleados",
              search: "/" + empresa_seleccionada,
              state: { companies: location.state.companies },
            });
          }, 1500);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  /* Función para activar o desactivar el botón de enviar y guardar */
  function validarEnviarGuardarEstado() {
    let errores = false;
    const nombresDatos = Object.keys(EMPLEADO);
    let banderaCamposBancarios = false;
    nombresDatos.map((e, key) => {
      /* Se validan los campos normales */
      if (e.substring(0, 4) !== "file" && !excepcionNombresEnviar.includes(e)) {
        if (
          e === "cuenta_nomina" ||
          e === "clabe_interbancaria" ||
          e === "numero_tarjeta"
        ) {
          if (EMPLEADO[e] !== "") {
            banderaCamposBancarios = true;
            if (e === "cuenta_nomina") {
              if (validarCuentaNomina(EMPLEADO[e])) {
                errores = true;
              }
            } else if (e === "clabe_interbancaria") {
              if (validarCLABE(EMPLEADO[e])) {
                errores = true;
              }
            } else if (e === "numero_tarjeta") {
              if (validarNumTar(EMPLEADO[e])) {
                errores = true;
              }
            }
          }
        } else if (
          e !== "cuenta_nomina" &&
          e !== "clabe_interbancaria" &&
          e !== "numero_tarjeta" &&
          EMPLEADO[e] === ""
        ) {
          errores = true;
        } else {
          if (e === "imss") {
            /* Parte para validar el NSS */
            const [err, msn] = validarNSS(EMPLEADO[e]);
            if (err) {
              errores = true;
            }
          } else if (e === "curp") {
            /* Parte para validar la CURP */
            const [errCURP, msnCURP] = validarCURP(EMPLEADO[e]);
            if (errCURP) {
              errores = true;
            } else {
              if (EMPLEADO.empleado_repetido_empresa !== "0") {
                /* Si el empleado ya existe en la empresa, no se dejará enviar ni guardar */
                errores = true;
              } /* Si el empleado ya existe en otra empresa pero no se hará una migración no se dejará envíar ni guardar */ else if (
                EMPLEADO.empleado_repetido !== "0" &&
                EMPLEADO.autorizacion_migracion === "0"
              ) {
                errores = true;
              }
            }
          } else if (e === "rfc") {
            /* Parte para validar el RFC */
            const [errRFC, msnRFC] = validarRFC(EMPLEADO[e]);
            if (errRFC) {
              errores = true;
            }
          } else if (e === "puesto" || e === "departamento") {
            /* Se validan los campos select */
            if (EMPLEADO[e] === "0") {
              errores = true;
            }
          }
        }
      } else {
        /* Se validan los campos de archivos */
        if (EMPLEADO[e].file === "") {
          errores = true;
        }
      }
    });
    
    if (!errores && banderaCamposBancarios) {
      setBotonEnviarClass("botonCapturaEnviar");
    } else {
      setBotonEnviarClass("botonCapturaEnviarDesactivado");
    }

    setEmpleado((EMPLEADO) => ({
      ...EMPLEADO,
      status_envio: "0",
    }));
  }

  /* -----------------------------Funciones para válidar los datos bancarios----------------------------- */
  function validarCuentaNomina(cuenta_nomina: string) {
    let error = false;
    if (cuenta_nomina.length !== 10) {
      error = true;
    }
    return error;
  }

  function validarCLABE(cuenta_clabe: string) {
    let error = false;
    if (cuenta_clabe.length !== 18) {
      error = true;
    }
    return error;
  }

  function validarNumTar(numero_tarjeta: string) {
    let error = false;
    if (numero_tarjeta.length !== 16) {
      error = true;
    }
    return error;
  }

  /* ------------------------------Funciones para visualizar imagenes------------------------------------ */
  /* Función para mostrar imagen cargada al dar click en el botón de previsualizar archivo */
  const openImage = (id_file: string) => {
    setNumPages(0);
    setPageNumber(1);
    let nombreImagen = "";
    if (archivoMigracionActual[id_file]) {
      const arrayBuffer = base64ToArrayBuffer(archivosMigracions[id_file].data);
      const blob = new Blob([arrayBuffer], {
        type: archivosMigracions[id_file].headers["content-type"],
      });
      const url = URL.createObjectURL(blob);
      if (
        archivosMigracions[id_file].headers["content-type"] ===
        "application/pdf"
      ) {
        setEsPDF(true);
        setUrlsPDF(url);
      } else {
        setEsPDF(false);
        setUrlsImagen(url);
      }
      console.log(url);
    } else {
      const file = EMPLEADO[id_file].file;
      const url = URL.createObjectURL(file);
      if (file.type === "application/pdf") {
        setEsPDF(true);
        setUrlsPDF(url);
      } else {
        setEsPDF(false);
        setUrlsImagen(url);
      }
    }
    switch (id_file) {
      case "file_imss":
        nombreImagen = "del IMSS";
        break;
      case "file_curp":
        nombreImagen = "de la CURP";
        break;
      case "file_rfc":
        nombreImagen = "del RFC";
        break;
      case "file_identificacion":
        nombreImagen = "de la identificación";
        break;
      case "file_acta":
        nombreImagen = "del acta de nacimiento";
        break;
      case "file_foto_inf":
        nombreImagen = "de la foto";
        break;
      case "file_foto_fir":
        nombreImagen = "de la firma";
        break;
    }
    setNombreImagenMostrada(nombreImagen);
    setOpenIMGModal(true);
  };
  /* Función para cerrar el modal de la visualización de la imagen */
  function closeIMGModal(): void {
    setOpenIMGModal(false);
  }

  /* Funciones para realizar el conteo de las páginas del PDF */
  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () => {
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
  };

  const goToNextPage = () => {
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);
  };

  /* UseEffect con funciones que se deben cargar en el renderizado de la página */
  useEffect(() => {
    getDepartamentos().then((departamentos) => {
      setDepartamentos(departamentos);
    });

    getPuestos().then((puestos) => {
      setPuestos(puestos);
    });

    getMovimientos().then((movimientos) => {
      setMovimientos(movimientos);
    });

    getEmpresas({ empresa_seleccionada }).then((response) => {
      const [empresas, empresaSeleccionadaAlias] = response;
      setEmpresas(empresas);
      setNombreEmpresaSeleccionada(empresaSeleccionadaAlias);
    });

    getMotivoMovimientos(EMPLEADO.movimiento).then((motivos) => {
      setMotivoMovimientos(motivos);
    });
  }, []);

  const now = 0;
  return (
    <div>
      <Dialog
        open={openCURPModal}
        onClose={closeCURPModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title text-center">
          {"Empleado existente en la empresa " + empresaEmpleado + "."}
        </DialogTitle>
        <DialogContent className="alert-dialog-content">
          <DialogContentText id="alert-dialog-description">
            {"¿Desea Migrar el Empleado con el CURP " +
              EMPLEADO.curp +
              " de la empresa " +
              empresaEmpleado +
              "?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="alert-dialog-actions">
          <Button
            className="cancel-btn mx-5 px-5 py-2"
            onClick={closeCURPModal}
          >
            Cancelar
          </Button>
          <Button
            className="delete-btn mx-5 px-5 py-2"
            onClick={migrarEmpleado}
            autoFocus
          >
            Migrar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openRepetidoEmpresaModal}
        onClose={closeRepetidoEmpresaModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"El empleado ya esta registrado en la empresa " +
            nombreEmpresaSeleccionada}
        </DialogTitle>
        <DialogContent className="alert-dialog-content">
          <DialogContentText id="alert-dialog-description">
            Empleado Repetido!
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            No se podrá registrar el empleado, verifique el CURP!
          </DialogContentText>
        </DialogContent>
        <DialogActions className="alert-dialog-actions">
          <Button
            className="cancel-btn mx-5 px-5 py-2"
            onClick={closeRepetidoEmpresaModal}
            autoFocus
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openIMGModal}
        onClose={closeIMGModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title text-center">
          {"Imagen del archivo " + nombreImagenMostrada}
        </DialogTitle>
        <DialogContent className="alert-dialog-content">
          {esPDF ? (
            <div>
              <nav>
                <button onClick={goToPrevPage}>Anterior</button>
                <button onClick={goToNextPage}>Siguiente</button>
                <p>
                  Página {pageNumber} de {numPages}
                </p>
              </nav>

              <Document
                file={urlsPDF}
                onLoadSuccess={(e) => onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} />
              </Document>
            </div>
          ) : (
            <DialogContentText id="alert-dialog-description">
              <img src={`${urlsImagen}`} />
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions className="alert-dialog-actions">
          <Button className="cancel-btn mx-5 px-5 py-2" onClick={closeIMGModal}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <ToastContainer
        position="bottom-center"
        theme="colored"
        autoClose={1000}
        closeButton={false}
        closeOnClick
      />

      <NavBar />

      <Subnavbar image={location.state.image} />

      <Row className="content">
        <Col className="content">
          <Row className="">
            <div className="col-md-10">
              <ProgressBar
                variant="success"
                now={now}
                label={`${now}%`}
                className="my-4 mx-5 alturaProgress"
              />
            </div>
            <div className="col-md-2 marginBotonEnvir">
              <button
                type="submit"
                className={`btn-lg ${botonEnviarClass}`}
                onClick={enviarGuardarInfo}
              >
                <span>Enviar y Guardar</span>
              </button>
            </div>
          </Row>
          <Row className="creme-bckgrnd">
            <Form className="row-login-form" onSubmit={handleGuardarEmpleado}>
              <Row className="titulos">
                <Typography
                  className="textoTitulos"
                  variant="h6"
                  fontWeight="bold"
                >
                  Información Básica
                </Typography>
              </Row>
              <Row className="mb-3 ms-2 me-2 mt-4">
                <Form.Group as={Col} md="4">
                  <InputGroup>
                    <InputGroup.Text id="nombre" className="estiloInputs">
                      Nombre{CAMPOS_OBLIGATORIOS.includes("nombre") ? "*" : ""}:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      aria-describedby="nombre"
                      name="nombre"
                      id="nombre"
                      className={`estiloInputsText ${
                        CAMPOS_OBLIGATORIOS.includes("nombre")
                          ? "obligatorio"
                          : ""
                      } ${classInvalid.nombre ? classInvalid.nombre : ""}`}
                      value={EMPLEADO.nombre}
                      onChange={handleChangeInfo}
                      maxLength={30}
                      onInput={maxLengthCheck}
                    />
                    <div className="invalid-feedback">
                      {mensajeError.nombre}
                    </div>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <InputGroup>
                    <InputGroup.Text id="a_paterno" className="estiloInputs">
                      Apellido Paterno
                      {CAMPOS_OBLIGATORIOS.includes("a_paterno")
                        ? "*"
                        : ""}:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      aria-describedby="a_paterno"
                      name="a_paterno"
                      className={`estiloInputsText ${
                        CAMPOS_OBLIGATORIOS.includes("a_paterno")
                          ? "obligatorio"
                          : ""
                      } ${
                        classInvalid.a_paterno ? classInvalid.a_paterno : ""
                      }`}
                      value={EMPLEADO.a_paterno}
                      onChange={handleChangeInfo}
                      maxLength={30}
                      onInput={maxLengthCheck}
                    />
                    <div className="invalid-feedback">
                      {mensajeError.a_paterno}
                    </div>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <InputGroup>
                    <InputGroup.Text id="a_materno" className="estiloInputs">
                      Apellido Materno
                      {CAMPOS_OBLIGATORIOS.includes("a_materno")
                        ? "*"
                        : ""}:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      aria-describedby="a_materno"
                      name="a_materno"
                      className={`estiloInputsText ${
                        CAMPOS_OBLIGATORIOS.includes("a_materno")
                          ? "obligatorio"
                          : ""
                      } ${
                        classInvalid.a_materno ? classInvalid.a_materno : ""
                      }`}
                      value={EMPLEADO.a_materno}
                      onChange={handleChangeInfo}
                      maxLength={30}
                      onInput={maxLengthCheck}
                    />
                    <div className="invalid-feedback">
                      {mensajeError.a_materno}
                    </div>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className="mb-3 ms-2 me-2 mt-4">
                <Form.Group as={Col} md="2">
                  <InputGroup>
                    <InputGroup.Text id="imss" className="estiloInputs">
                      IMSS{CAMPOS_OBLIGATORIOS.includes("imss") ? "*" : ""}:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="number"
                      aria-describedby="imss"
                      name="imss"
                      className={`estiloInputsText ${
                        CAMPOS_OBLIGATORIOS.includes("imss")
                          ? "obligatorio"
                          : ""
                      } ${classInvalid.imss ? classInvalid.imss : ""}`}
                      value={EMPLEADO.imss}
                      onChange={handleChangeInfo}
                      maxLength={11}
                      onInput={maxLengthCheck}
                    />
                    <div className="invalid-feedback">{mensajeError.imss}</div>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="2">
                  <Row>
                    <InputGroup className="centrarBotones">
                      <label
                        htmlFor="file_imss"
                        className={`${classInvalid.file_imss}`}
                      >
                        <Input
                          // accept="image/*,.pdf"
                          id="file_imss"
                          type="file"
                          className={`${
                            CAMPOS_OBLIGATORIOS.includes("file_imss")
                              ? "obligatorio"
                              : ""
                          }`}
                          sx={{ display: { xl: "none", xs: "none" } }}
                          onChange={handleFileChange}
                        />
                        <Button
                          variant="contained"
                          component="span"
                          className="formatoBotonArchivos"
                          startIcon={<ArrowUpward />}
                        >
                          {`${
                            EMPLEADO.file_imss.file_name === ""
                              ? "Cargar Archivo"
                              : EMPLEADO.file_imss.file_name
                          }`}
                        </Button>
                      </label>
                      {buttonFile.file_imss ? (
                        <IconButton
                          size="medium"
                          className="estiloIconArchivo"
                          name="imss_button"
                          onClick={() => openImage("file_imss")}
                        >
                          <Plagiarism className="estiloIconoArchivoDos" />
                        </IconButton>
                      ) : (
                        ""
                      )}
                      <div className="invalid-feedback">
                        {mensajeError.file_imss}
                      </div>
                    </InputGroup>
                  </Row>
                </Form.Group>
                <Form.Group as={Col} md="2">
                  <InputGroup>
                    <InputGroup.Text id="curp" className="estiloInputs">
                      CURP{CAMPOS_OBLIGATORIOS.includes("curp") ? "*" : ""}:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      aria-describedby="curp"
                      name="curp"
                      className={`estiloInputsText ${
                        CAMPOS_OBLIGATORIOS.includes("curp")
                          ? "obligatorio"
                          : ""
                      } ${classInvalid.curp ? classInvalid.curp : ""}`}
                      value={EMPLEADO.curp}
                      onChange={handleChangeInfo}
                      maxLength={18}
                      onInput={maxLengthCheck}
                    />
                    <div className="invalid-feedback">{mensajeError.curp}</div>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="2">
                  <InputGroup className="centrarBotones">
                    <label
                      htmlFor="file_curp"
                      className={`${classInvalid.file_curp}`}
                    >
                      <Input
                        // accept="image/*,.pdf"
                        id="file_curp"
                        type="file"
                        className={`${
                          CAMPOS_OBLIGATORIOS.includes("file_curp")
                            ? "obligatorio"
                            : ""
                        }`}
                        sx={{ display: { xl: "none", xs: "none" } }}
                        onChange={handleFileChange}
                      />
                      <Button
                        variant="contained"
                        component="span"
                        className="formatoBotonArchivos"
                        startIcon={<ArrowUpward />}
                      >
                        {`${
                          EMPLEADO.file_curp.file_name === ""
                            ? "Cargar Archivo"
                            : EMPLEADO.file_curp.file_name
                        }`}
                      </Button>
                    </label>
                    {buttonFile.file_curp ? (
                      <IconButton
                        size="medium"
                        className="estiloIconArchivo"
                        name="curp_button"
                        onClick={() => openImage("file_curp")}
                      >
                        <Plagiarism className="estiloIconoArchivoDos" />
                      </IconButton>
                    ) : (
                      ""
                    )}
                    <div className="invalid-feedback">
                      {mensajeError.file_curp}
                    </div>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="2">
                  <InputGroup>
                    <InputGroup.Text id="rfc" className="estiloInputs">
                      RFC{CAMPOS_OBLIGATORIOS.includes("rfc") ? "*" : ""}:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      aria-describedby="rfc"
                      name="rfc"
                      className={`estiloInputsText ${
                        CAMPOS_OBLIGATORIOS.includes("rfc") ? "obligatorio" : ""
                      } ${classInvalid.rfc ? classInvalid.rfc : ""}`}
                      value={EMPLEADO.rfc}
                      onChange={handleChangeInfo}
                      maxLength={13}
                      onInput={maxLengthCheck}
                    />
                    <div className="invalid-feedback">{mensajeError.rfc}</div>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="2">
                  <InputGroup className="centrarBotones">
                    <label
                      htmlFor="file_rfc"
                      className={`${classInvalid.file_rfc}`}
                    >
                      <Input
                        // accept="image/*,.pdf"
                        id="file_rfc"
                        type="file"
                        className={`${
                          CAMPOS_OBLIGATORIOS.includes("file_rfc")
                            ? "obligatorio"
                            : ""
                        }`}
                        sx={{ display: { xl: "none", xs: "none" } }}
                        onChange={handleFileChange}
                      />
                      <Button
                        variant="contained"
                        component="span"
                        className="formatoBotonArchivos"
                        startIcon={<ArrowUpward />}
                      >
                        {`${
                          EMPLEADO.file_rfc.file_name === ""
                            ? "Cargar Archivo"
                            : EMPLEADO.file_rfc.file_name
                        }`}
                      </Button>
                    </label>
                    {buttonFile.file_rfc ? (
                      <IconButton
                        size="medium"
                        className="estiloIconArchivo"
                        name="rfc_button"
                        onClick={() => openImage("file_rfc")}
                      >
                        <Plagiarism className="estiloIconoArchivoDos" />
                      </IconButton>
                    ) : (
                      ""
                    )}
                    <div className="invalid-feedback">
                      {mensajeError.file_rfc}
                    </div>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className="mb-3 ms-2 me-2 mt-4">
                <Form.Group as={Col} md="12">
                  <InputGroup>
                    <InputGroup.Text id="dir_fisc" className="estiloInputs">
                      Dirección Fiscal
                      {CAMPOS_OBLIGATORIOS.includes("dir_fisc")
                        ? "*"
                        : ""}:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      aria-describedby="dir_fisc"
                      name="dir_fisc"
                      className={`estiloInputsText ${
                        CAMPOS_OBLIGATORIOS.includes("dir_fisc")
                          ? "obligatorio"
                          : ""
                      } ${classInvalid.dir_fisc ? classInvalid.dir_fisc : ""}`}
                      value={EMPLEADO.dir_fisc}
                      onChange={handleChangeInfo}
                      maxLength={130}
                      onInput={maxLengthCheck}
                    />
                    <div className="invalid-feedback">
                      {mensajeError.dir_fisc}
                    </div>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className="mb-3 ms-2 me-2 mt-4">
                <Form.Group as={Col} md="12">
                  <InputGroup>
                    <InputGroup.Text id="dir" className="estiloInputs">
                      Dirección{CAMPOS_OBLIGATORIOS.includes("dir") ? "*" : ""}:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      aria-describedby="dir"
                      name="dir"
                      className={`estiloInputsText ${
                        CAMPOS_OBLIGATORIOS.includes("dir") ? "obligatorio" : ""
                      } ${classInvalid.dir ? classInvalid.dir : ""}`}
                      value={EMPLEADO.dir}
                      onChange={handleChangeInfo}
                      maxLength={130}
                      onInput={maxLengthCheck}
                    />
                    <div className="invalid-feedback">{mensajeError.dir}</div>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className="mb-3 ms-2 me-2 mt-4">
                <Form.Group as={Col} md="4">
                  <InputGroup>
                    <InputGroup.Text id="correo" className="estiloInputs">
                      Correo{CAMPOS_OBLIGATORIOS.includes("correo") ? "*" : ""}:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="email"
                      aria-describedby="correo"
                      name="correo"
                      className={`estiloInputsText ${
                        CAMPOS_OBLIGATORIOS.includes("correo")
                          ? "obligatorio"
                          : ""
                      } ${classInvalid.correo ? classInvalid.correo : ""}`}
                      value={EMPLEADO.correo}
                      onChange={handleChangeInfo}
                      maxLength={40}
                      onInput={maxLengthCheck}
                    />
                    <div className="invalid-feedback">
                      {mensajeError.correo}
                    </div>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <InputGroup>
                    <InputGroup.Text id="tel" className="estiloInputs">
                      Teléfono{CAMPOS_OBLIGATORIOS.includes("tel") ? "*" : ""}:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="number"
                      aria-describedby="tel"
                      name="tel"
                      className={`estiloInputsText ${
                        CAMPOS_OBLIGATORIOS.includes("tel") ? "obligatorio" : ""
                      } ${classInvalid.tel ? classInvalid.tel : ""}`}
                      value={EMPLEADO.tel}
                      onChange={handleChangeInfo}
                      maxLength={10}
                      onInput={maxLengthCheck}
                    />
                    <div className="invalid-feedback">{mensajeError.tel}</div>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <InputGroup>
                    <InputGroup.Text id="fecha_nac" className="estiloInputs">
                      Fecha de Nacimiento
                      {CAMPOS_OBLIGATORIOS.includes("fecha_nac")
                        ? "*"
                        : ""}:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="date"
                      aria-describedby="fecha_nac"
                      name="fecha_nac"
                      min="1900-01-01"
                      max={dayjs().subtract(18, "year").format("YYYY-MM-DD")}
                      className={`estiloInputsText ${
                        CAMPOS_OBLIGATORIOS.includes("fecha_nac")
                          ? "obligatorio"
                          : ""
                      } ${
                        classInvalid.fecha_nac ? classInvalid.fecha_nac : ""
                      }`}
                      value={EMPLEADO.fecha_nac}
                      onChange={handleChangeInfo}
                    />
                    <div className="invalid-feedback">
                      {mensajeError.fecha_nac}
                    </div>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className="mb-4 ms-2 me-2 mt-4">
                <Form.Group as={Col} md="3" style={{ marginTop: "1rem" }}>
                  <InputGroup>
                    <InputGroup.Text
                      id="identificacion"
                      className="estiloInputs"
                    >
                      INE/Pasaporte/Cédula
                      {CAMPOS_OBLIGATORIOS.includes("identificacion")
                        ? "*"
                        : ""}
                      :{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      aria-describedby="identificacion"
                      name="identificacion"
                      className={`estiloInputsText ${
                        CAMPOS_OBLIGATORIOS.includes("identificacion")
                          ? "obligatorio"
                          : ""
                      } ${
                        classInvalid.identificacion
                          ? classInvalid.identificacion
                          : ""
                      }`}
                      value={EMPLEADO.identificacion}
                      onChange={handleChangeInfo}
                      maxLength={20}
                      onInput={maxLengthCheck}
                    />
                    <div className="invalid-feedback">
                      {mensajeError.identificacion}
                    </div>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="2" style={{ marginTop: "2rem" }}>
                  <InputGroup className="centrarBotones">
                    <label
                      htmlFor="file_identificacion"
                      className={`${
                        classInvalid.file_identificacion
                          ? classInvalid.file_identificacion
                          : ""
                      }`}
                    >
                      <Input
                        // accept="image/*,.pdf"
                        id="file_identificacion"
                        type="file"
                        className={`${
                          CAMPOS_OBLIGATORIOS.includes("file_identificacion")
                            ? "obligatorio"
                            : ""
                        }`}
                        sx={{ display: { xl: "none", xs: "none" } }}
                        onChange={handleFileChange}
                      />
                      <Button
                        variant="contained"
                        component="span"
                        className="formatoBotonArchivos"
                        startIcon={<ArrowUpward />}
                      >
                        {`${
                          EMPLEADO.file_identificacion.file_name === ""
                            ? "Cargar Archivo"
                            : EMPLEADO.file_identificacion.file_name
                        }`}
                      </Button>
                    </label>
                    {buttonFile.file_identificacion ? (
                      <IconButton
                        size="medium"
                        className="estiloIconArchivo"
                        name="identificacion_button"
                        onClick={() => openImage("file_identificacion")}
                      >
                        <Plagiarism className="estiloIconoArchivoDos" />
                      </IconButton>
                    ) : (
                      ""
                    )}
                    <div className="invalid-feedback">
                      {mensajeError.file_identificacion}
                    </div>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="2" style={{ marginTop: "-0.3rem" }}>
                  <InputGroup className="centrarBotones">
                    <InputGroup.Text
                      id="acta"
                      className={`estiloInputs ${
                        buttonFile.file_acta
                          ? "claseTextoActaBoton"
                          : "claseTextoActaSinBoton"
                      }`}
                    >
                      Acta de Nacimiento
                      {CAMPOS_OBLIGATORIOS.includes("file_acta") ? "*" : ""}:
                    </InputGroup.Text>
                    <label
                      htmlFor="file_acta"
                      className={`${classInvalid.file_acta}`}
                    >
                      <Input
                        // accept="image/*,.pdf"
                        id="file_acta"
                        type="file"
                        className={`${
                          CAMPOS_OBLIGATORIOS.includes("file_acta")
                            ? "obligatorio"
                            : ""
                        }`}
                        sx={{ display: { xl: "none", xs: "none" } }}
                        onChange={handleFileChange}
                      />
                      <Button
                        variant="contained"
                        component="span"
                        className="formatoBotonArchivos"
                        startIcon={<ArrowUpward />}
                      >
                        {`${
                          EMPLEADO.file_acta.file_name === ""
                            ? "Cargar Archivo"
                            : EMPLEADO.file_acta.file_name
                        }`}
                      </Button>
                    </label>
                    {buttonFile.file_acta ? (
                      <IconButton
                        size="medium"
                        className="estiloIconArchivo"
                        name="acta_button"
                        onClick={() => openImage("file_acta")}
                      >
                        <Plagiarism className="estiloIconoArchivoDos" />
                      </IconButton>
                    ) : (
                      ""
                    )}
                    <div className="invalid-feedback">
                      {mensajeError.file_acta}
                    </div>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="2" style={{ marginTop: "-0.3rem" }}>
                  <InputGroup className="centrarBotones">
                    <InputGroup.Text
                      id="foto_inf"
                      className={`estiloInputs ${
                        buttonFile.file_foto_inf
                          ? "claseTextoFotoInfBoton"
                          : "claseTextoFotoInfSinBoton"
                      }`}
                    >
                      Foto Infantil
                      {CAMPOS_OBLIGATORIOS.includes("file_foto_inf") ? "*" : ""}
                      :
                    </InputGroup.Text>
                    <label
                      htmlFor="file_foto_inf"
                      className={`${classInvalid.file_foto_inf}`}
                    >
                      <Input
                        // accept="image/*,.pdf"
                        id="file_foto_inf"
                        type="file"
                        className={`${
                          CAMPOS_OBLIGATORIOS.includes("file_foto_inf")
                            ? "obligatorio"
                            : ""
                        }`}
                        sx={{ display: { xl: "none", xs: "none" } }}
                        onChange={handleFileChange}
                      />
                      <Button
                        variant="contained"
                        component="span"
                        className="formatoBotonArchivos"
                        startIcon={<ArrowUpward />}
                      >
                        {`${
                          EMPLEADO.file_foto_inf.file_name === ""
                            ? "Cargar Archivo"
                            : EMPLEADO.file_foto_inf.file_name
                        }`}
                      </Button>
                    </label>
                    {buttonFile.file_foto_inf ? (
                      <IconButton
                        size="medium"
                        className="estiloIconArchivo"
                        name="foto_inf_button"
                        onClick={() => openImage("file_foto_inf")}
                      >
                        <Plagiarism className="estiloIconoArchivoDos" />
                      </IconButton>
                    ) : (
                      ""
                    )}
                    <div className="invalid-feedback">
                      {mensajeError.file_foto_inf}
                    </div>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="2" style={{ marginTop: "-0.3rem" }}>
                  <InputGroup className="centrarBotones">
                    <InputGroup.Text
                      id="foto_fir"
                      className={`estiloInputs ${
                        buttonFile.file_foto_inf
                          ? "claseTextoFotoFirBoton"
                          : "claseTextoFotoFirSinBoton"
                      }`}
                    >
                      Foto Firma
                      {CAMPOS_OBLIGATORIOS.includes("file_foto_fir") ? "*" : ""}
                      :
                    </InputGroup.Text>
                    <label
                      htmlFor="file_foto_fir"
                      className={`${classInvalid.file_foto_fir}`}
                    >
                      <Input
                        // accept="image/*,.pdf"
                        id="file_foto_fir"
                        type="file"
                        className={`${
                          CAMPOS_OBLIGATORIOS.includes("file_foto_fir")
                            ? "obligatorio"
                            : ""
                        }`}
                        sx={{ display: { xl: "none", xs: "none" } }}
                        onChange={handleFileChange}
                      />
                      <Button
                        variant="contained"
                        component="span"
                        className="formatoBotonArchivos"
                        startIcon={<ArrowUpward />}
                      >
                        {`${
                          EMPLEADO.file_foto_fir.file_name === ""
                            ? "Cargar Archivo"
                            : EMPLEADO.file_foto_fir.file_name
                        }`}
                      </Button>
                    </label>
                    {buttonFile.file_foto_fir ? (
                      <IconButton
                        size="medium"
                        className="estiloIconArchivo"
                        name="foto_fir_button"
                        onClick={() => openImage("file_foto_fir")}
                      >
                        <Plagiarism className="estiloIconoArchivoDos" />
                      </IconButton>
                    ) : (
                      ""
                    )}
                    <div className="invalid-feedback">
                      {mensajeError.file_foto_fir}
                    </div>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className="titulos">
                <Typography
                  className="textoTitulos"
                  variant="h6"
                  fontWeight="bold"
                >
                  Ingreso
                </Typography>
              </Row>
              <Row className="mb-4 ms-2 me-2 mt-4">
                <Form.Group as={Col} md="3">
                  <InputGroup>
                    <InputGroup.Text id="fecha_ing" className="estiloInputs">
                      Fecha de Ingreso
                      {CAMPOS_OBLIGATORIOS.includes("fecha_ing")
                        ? "*"
                        : ""}:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="date"
                      aria-describedby="fecha_ing"
                      name="fecha_ing"
                      className={`estiloInputsText ${
                        CAMPOS_OBLIGATORIOS.includes("fecha_ing")
                          ? "obligatorio"
                          : ""
                      } ${
                        classInvalid.fecha_ing ? classInvalid.fecha_ing : ""
                      }`}
                      value={EMPLEADO.fecha_ing}
                      onChange={handleChangeInfo}
                    />
                    <div className="invalid-feedback">
                      {mensajeError.fecha_ing}
                    </div>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="3">
                  <InputGroup>
                    <InputGroup.Text id="puesto" className="estiloInputs">
                      Puesto{CAMPOS_OBLIGATORIOS.includes("puesto") ? "*" : ""}:{" "}
                    </InputGroup.Text>
                    <Form.Select
                      name="puesto"
                      className={`estiloInputsText select ${
                        CAMPOS_OBLIGATORIOS.includes("puesto")
                          ? "obligatorio"
                          : ""
                      } ${classInvalid.puesto ? classInvalid.puesto : ""}`}
                      value={EMPLEADO.puesto}
                      onChange={handleChangeInfo}
                    >
                      <option value="0">Seleccione una opción</option>
                      {puestos.map((e, key) => {
                        return (
                          <option key={e.id} value={e.id_puesto}>
                            {e.puesto}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <div className="invalid-feedback">
                      {mensajeError.puesto}
                    </div>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="3">
                  <InputGroup>
                    <InputGroup.Text id="departamento" className="estiloInputs">
                      Departamento
                      {CAMPOS_OBLIGATORIOS.includes("departamento")
                        ? "*"
                        : ""}:{" "}
                    </InputGroup.Text>
                    <Form.Select
                      name="departamento"
                      className={`estiloInputsText select ${
                        CAMPOS_OBLIGATORIOS.includes("departamento")
                          ? "obligatorio"
                          : ""
                      } ${
                        classInvalid.departamento
                          ? classInvalid.departamento
                          : ""
                      }`}
                      value={EMPLEADO.departamento}
                      onChange={handleChangeInfo}
                    >
                      <option value="0">Seleccione una opción</option>
                      {departamentos.map((e, key) => {
                        return (
                          <option key={e.id} value={e.id_departamento}>
                            {e.departamento}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <div className="invalid-feedback">
                      {mensajeError.departamento}
                    </div>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="3">
                  <InputGroup>
                    <InputGroup.Text id="empresa" className="estiloInputs">
                      Empresa:{" "}
                    </InputGroup.Text>
                    <Form.Select
                      name="empresa"
                      className="estiloInputsText"
                      value={EMPLEADO.empresa}
                      onChange={handleChangeInfo}
                      disabled
                    >
                      <option value="0">Seleccione una opción</option>
                      {empresas.map((company: ICompany) => {
                        return (
                          <option
                            key={company.id_empresa}
                            value={company.id_empresa}
                          >
                            {company.alias}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className="titulosMov">
                <Typography
                  className="textoTitulos"
                  variant="h6"
                  fontWeight="bold"
                >
                  Movimientos Laborales Internos
                </Typography>
              </Row>
              <Row className="mb-3 ms-2 me-2 mt-4">
                <Form.Group as={Col} md="4">
                  <InputGroup>
                    <InputGroup.Text id="fecha_mov" className="estiloInputsMov">
                      Fecha de Movimiento:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="date"
                      aria-describedby="fecha_mov"
                      name="fecha_mov"
                      className="estiloInputsTextMov"
                      value={EMPLEADO.fecha_mov}
                      onChange={(e) => handleChangeInfo}
                      disabled
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <InputGroup>
                    <InputGroup.Text
                      id="movimiento"
                      className="estiloInputsMov"
                    >
                      Movimiento:{" "}
                    </InputGroup.Text>
                    <Form.Select
                      name="movimiento"
                      className="estiloInputsTextMov"
                      value={EMPLEADO.movimiento}
                      onChange={handleMovimientosChange}
                      disabled
                    >
                      <option value="0">Seleccione una opción</option>
                      {movimientos.map((e, key) => {
                        return (
                          <option key={e.id} value={e.id_movimiento}>
                            {e.movimiento}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <InputGroup>
                    <InputGroup.Text
                      id="motivo_movimiento"
                      className="estiloInputsMov"
                    >
                      Motivo del Movimiento:{" "}
                    </InputGroup.Text>
                    <Form.Select
                      name="motivo_movimiento"
                      className="estiloInputsTextMov"
                      disabled={disabledMotivo}
                      value={selectedMotivo}
                      onChange={handleMotivoMovimientoChange}
                    >
                      <option value="0">Seleccione una opción</option>
                      {motivoMovimientos.map((e, key) => {
                        return (
                          <option key={e.id} value={e.id_motivo_movimiento}>
                            {e.motivo_movimiento}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className="titulos">
                <Typography
                  className="textoTitulos"
                  variant="h6"
                  fontWeight="bold"
                >
                  Nómina
                </Typography>
              </Row>
              <Row className="mb-4 ms-2 me-2 mt-4">
                <Form.Group as={Col} md="4">
                  <InputGroup>
                    <InputGroup.Text
                      id="cuenta_nomina"
                      className="estiloInputs"
                    >
                      Cuenta de Banco Interna de Nómina:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="number"
                      aria-describedby="cuenta_nomina"
                      name="cuenta_nomina"
                      className={`estiloInputsText ${
                        classInvalid.cuenta_nomina
                          ? classInvalid.cuenta_nomina
                          : ""
                      }`}
                      value={EMPLEADO.cuenta_nomina}
                      onChange={handleChangeInfo}
                      maxLength={10}
                      onInput={maxLengthCheck}
                    />
                    <div className="invalid-feedback">
                      {mensajeError.cuenta_nomina}
                    </div>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <InputGroup>
                    <InputGroup.Text
                      id="numero_tarjeta"
                      className="estiloInputs"
                    >
                      No. de Tarjeta:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="number"
                      aria-describedby="numero_tarjeta"
                      name="numero_tarjeta"
                      className={`estiloInputsText ${
                        classInvalid.numero_tarjeta
                          ? classInvalid.numero_tarjeta
                          : ""
                      }`}
                      value={EMPLEADO.numero_tarjeta}
                      onChange={handleChangeInfo}
                      maxLength={16}
                      onInput={maxLengthCheck}
                    />
                    <div className="invalid-feedback">
                      {mensajeError.numero_tarjeta}
                    </div>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <InputGroup>
                    <InputGroup.Text
                      id="clabe_interbancaria"
                      className="estiloInputs"
                    >
                      Clabe Interbancaria:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="number"
                      aria-describedby="clabe_interbancaria"
                      name="clabe_interbancaria"
                      className={`estiloInputsText ${
                        classInvalid.clabe_interbancaria
                          ? classInvalid.clabe_interbancaria
                          : ""
                      }`}
                      value={EMPLEADO.clabe_interbancaria}
                      onChange={handleChangeInfo}
                      maxLength={18}
                      onInput={maxLengthCheck}
                    />
                    <div className="invalid-feedback">
                      {mensajeError.clabe_interbancaria}
                    </div>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className="mb-4 ms-2 me-2 mt-4">
                <Form.Group as={Col} md="6">
                  <InputGroup>
                    <InputGroup.Text id="banco" className="estiloInputs">
                      Banco:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      aria-describedby="banco"
                      name="banco"
                      className={`estiloInputsText ${
                        classInvalid.banco ? classInvalid.banco : ""
                      }`}
                      value={EMPLEADO.banco}
                      onChange={handleChangeInfo}
                      maxLength={20}
                      onInput={maxLengthCheck}
                    />
                    <div className="invalid-feedback">{mensajeError.banco}</div>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <InputGroup>
                    <InputGroup.Text id="beneficiario" className="estiloInputs">
                      Beneficiario:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      aria-describedby="beneficiario"
                      name="beneficiario"
                      className={`estiloInputsText ${
                        classInvalid.beneficiario
                          ? classInvalid.beneficiario
                          : ""
                      }`}
                      value={EMPLEADO.beneficiario}
                      readOnly
                      onChange={handleChangeInfo}
                    />
                    <div className="invalid-feedback">
                      {mensajeError.beneficiario}
                    </div>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row>
                <Col md="6" className="p-0">
                  <Button
                    className="btn estiloBotonCancelar"
                    onClick={history.goBack}
                  >
                    Cancelar
                  </Button>
                </Col>
                <Col md="6" className="p-0">
                  <Button type="submit" className={`btn ${botonGuardarClass}`}>
                    Guardar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Captura;
