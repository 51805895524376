export const mensajes: IMensajesComplemento = {
  nombre: "El nombre es requerido",
  a_paterno: "El apellido paterno es requerido",
  a_materno: "El apellido materno es requerido",
  imss: "El Número de Seguro Social (NSS) es requerido",
  curp: "El CURP es requerido",
  rfc: "El RFC es requerido",
  dir_fisc: "La dirección fiscal es requerida",
  dir: "La dirección es requerida",
  correo: "El email es requerido",
  tel: "El teléfono es requerido",
  fecha_nac: "La fecha de nacimiento es requerida",
  identificacion: "El número de la identificación es requerido",
  fecha_ing: "La fecha de ingreso es requerida",
  puesto: "El puesto es requerido",
  departamento: "El departamento es requerido",
  empresa: "La empresa es requerida",
};

export const mensajesFile: IMensajesComplemento = {
  mensaje_general_tipo:
    "El archivo ingresado no es soportado, debe ser imagen o PDF",
  mensaje_general_tam:
    "El archivo ingresado es muy grande, el máximo es de 3MB",
  file_imss: "El archivo del NSS es requerido",
  file_curp: "El archivo de la CURP es requerido",
  file_rfc: "El archivo del RFC es requerido",
  file_identificacion: "El archivo de la identificación es requerido",
  file_acta: "El archivo del acta de nacimiento es requerido",
  file_foto_inf: "El archivo de la foto infantil es requerido",
  file_foto_fir: "El archivo de la firma es requerido",
};

export const mensajesErrorOtros = {
  errorTel: "El teléfono debe tener 10 digitos",
  errorCtaNom: "La cuenta de banco debe tener 10 digitos",
  errorCLABE: "La CLABE debe tener 18 digitos",
  errorNumTar: "El número de tarjeta debe ser de 16 digitos",
};

export const excepcionNombres = [
  "tel",
  "correo",
  "rfc",
  "curp",
  "imss",
  "cuenta_nomina",
  "clabe_interbancaria",
  "numero_tarjeta",
];

export const estadoInicialMensajes = {
  nombre: "",
  a_paterno: "",
  a_materno: "",
  imss: "",
  curp: "",
  rfc: "",
  dir_fisc: "",
  dir: "",
  correo: "",
  tel: "",
  fecha_nac: "",
  identificacion: "",
  fecha_ing: "",
  puesto: "",
  departamento: "",
  empresa: "",
};

export type IMensajes = typeof mensajes & IMensajesComplemento;
export interface IMensajesComplemento {
  [key: string]: string | boolean;
}
