import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Row, Form, Col, InputGroup } from "react-bootstrap";
import { Typography } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";

import { recover } from "../../actions/auth";

import "../login/Login.css";

const schema = Yup.object().shape({
  username: Yup.string().required("El usuario es requerido"),
  actualPassword: Yup.string().required("La contraseña actual es requerida"),
  newPassword: Yup.string()
    .required("La nueva contraseña es requerida")
    .min(8, "La contraseña debe tener al menos 8 caracteres"),
  confirmedPassword: Yup.string()
    .when("newPassword", {
      is: (val) => !!(val && val.length > 0),
      then: Yup.string().oneOf(
        [Yup.ref("newPassword")],
        "Ambas contraseñas deben ser iguales"
      ),
    })
    .required("La confirmación de la contraseña es requerida")
    .min(8, "La contraseña debe tener al menos 8 caracteres"),
});

const Recover = (props) => {
  const [username, setUsername] = useState("");
  const [actualPassword, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmedPassword, setConfNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  console.log({ isLoggedIn });
  // if (isLoggedIn) {
  //   return <Redirect to="/empresas" />;
  // }
  return (
    <Formik
      validationSchema={schema}
      onSubmit={console.log}
      initialValues={{
        username: "",
        actualPassword: "",
        newPassword: "",
        confirmedPassword: "",
      }}
    >
      {({
        handleSubmit,
        handleRecover = (e) => {
          handleSubmit(e);
          e.preventDefault();
          setLoading(true);
          if (username && actualPassword && newPassword && confirmedPassword) {
            dispatch(recover(username, actualPassword, newPassword))
              .then(() => {
                props.history.push("/empresas");
                window.location.reload();
              })
              .catch(() => {
                setLoading(false);
              });
          } else {
            setLoading(false);
          }
        },
        handleChange,
        onChangeUsername = (e) => {
          handleChange(e);
          const username = e.target.value;
          setUsername(username);
        },
        onChangePassword = (e) => {
          handleChange(e);
          const actualPassword = e.target.value;
          setPassword(actualPassword);
        },
        onChangeNewPassword = (e) => {
          handleChange(e);
          const newPassword = e.target.value;
          setNewPassword(newPassword);
        },
        onChangeConfirmedNewPassword = (e) => {
          handleChange(e);
          const confirmedPassword = e.target.value;
          setConfNewPassword(confirmedPassword);
        },
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <div className="col-md-12">
          <Row>
            <div className="col-md-7">
              <Row>
                <div className="col-md-5 barraTransversal" />
                <div className="col-md-3 barraUno" />
                <div className="col-md-3 barraDos" />
              </Row>
            </div>
            <div className="col-md-5 rectanguloRP rectanguloSM rectanguloMD">
              <Form className="row-login-form" onSubmit={handleRecover}>
                <Row>
                  <Typography
                    id="title"
                    sx={{
                      typography: { xl: "h1", xs: "h3" },
                      fontSize: { lg: 75 },
                    }}
                    align="center"
                    className="titleDos"
                  >
                    {" "}
                    ORBEIT{" "}
                  </Typography>
                </Row>
                <Form.Group className="m-0">
                  <Row className="col-md-12 pe-4 ps-5 mt-3">
                    <Form.Group as={Col} md="12" controlId="validateUsername">
                      <InputGroup hasValidation>
                        <Form.Control
                          type="text"
                          aria-describedby="username"
                          name="username"
                          placeholder="Ingrese Usuario"
                          value={values.username}
                          onChange={onChangeUsername}
                          isInvalid={!!errors.username}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.username}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Row>
                </Form.Group>
                <Row>
                  <Typography align="center" className="textoPSW mt-3">
                    {" "}
                    ¿Estas seguro que deseas cambiar la contraseña?{" "}
                  </Typography>
                </Row>
                <Form.Group className="m-0">
                  <Row className="col-md-12 pe-4 ps-5 mt-3">
                    <Form.Group as={Col} md="12" controlId="validateActualPSW">
                      <InputGroup hasValidation>
                        <Form.Control
                          type="password"
                          aria-describedby="actualPassword"
                          name="actualPassword"
                          placeholder="Ingrese Contraseña Actual"
                          value={values.actualPassword}
                          onChange={onChangePassword}
                          isInvalid={!!errors.actualPassword}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.actualPassword}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Row>
                </Form.Group>
                <Form.Group className="m-0">
                  <Row className="col-md-12 pe-4 ps-5 mt-3">
                    <Form.Group as={Col} md="12" controlId="validateNewPSW">
                      <InputGroup hasValidation>
                        <Form.Control
                          type="password"
                          aria-describedby="newPassword"
                          name="newPassword"
                          placeholder="Ingrese Nueva Contraseña"
                          value={values.newPassword}
                          onChange={onChangeNewPassword}
                          isInvalid={!!errors.newPassword}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.newPassword}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Row>
                </Form.Group>
                <Form.Group className="m-0">
                  <Row className="col-md-12 pe-4 ps-5 mt-3">
                    <Form.Group as={Col} md="12" controlId="validateConfirmPSW">
                      <InputGroup hasValidation>
                        <Form.Control
                          type="password"
                          aria-describedby="confirmedPassword"
                          name="confirmedPassword"
                          placeholder="Confirme Nueva Contraseña"
                          value={values.confirmedPassword}
                          onChange={onChangeConfirmedNewPassword}
                          isInvalid={!!errors.confirmedPassword}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.confirmedPassword}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Row>
                </Form.Group>
                <Row className="marginBottom">
                  <div className="col-md-7">
                    <p />
                  </div>
                  <Row className="col-md-5 mt-3">
                    <button
                      type="submit"
                      className="btn-lg botonLogin botonLoginMD"
                      disabled={loading}
                    >
                      {loading && (
                        <span className="spinner-border spinner-border-sm" />
                      )}
                      <span>Entrar</span>
                    </button>
                  </Row>
                </Row>
                {message && (
                  <Form.Group className="me-1 ms-1">
                    <div className="alert alert-danger" role="alert">
                      {message}
                    </div>
                  </Form.Group>
                )}
              </Form>
            </div>
          </Row>
        </div>
      )}
    </Formik>
  );
};

export default Recover;
