import IDepartamento from "../../components/capture/IDepartamento";
import api from "../api.service";

const getDepartamentos = (): Promise<IDepartamento[]> => {
  return api.get("get/departamentos").then((response) => {
    return response.data;
  });
};

export default getDepartamentos;
