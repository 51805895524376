import IMovimiento from "../../components/capture/IMovimiento";
import api from "../api.service";

const getMovimientos = (): Promise<IMovimiento[]> => {
  return api.get("get/movimientos").then((response) => {
    return response.data;
  });
};

export default getMovimientos;
