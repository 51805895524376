import api from "../api.service";

const getValidarExistenciaCurp = async (curp: string, idEmpresa: string) => {
  return api.post("validar/curp", {
    curp,
    id_empresa: idEmpresa,
  });
};

export default getValidarExistenciaCurp;
