import React, { useEffect, useState } from "react";
import NavBar from "../navbar/NavBar";
import { Row, Col, Form, ProgressBar, InputGroup } from "react-bootstrap";
import Subnavbar from "../subnavbar/Subnavbar";
import {
  Button,
  Input,
  Typography,
  Divider,
  IconButton,
  Alert,
} from "@mui/material/";
import { ArrowUpward, Circle, Email, Plagiarism } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { useHistory } from "react-router-dom";

import axios from "axios";
import {
  API_PORTAL_URL,
  ARCHIVOS_SOPORTADOS,
  CAMPOS_OBLIGATORIOS,
} from "../../constants";
import jwt_decode from "jwt-decode";

import "./Edit.css";
import InfoField from "../info/InfoField";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import getUserLocal from "../../utils/getUserLocal";
import { IEmpleado } from "../capture/empleado";
import IMovimiento from "../capture/IMovimiento";
import IMotivoMovimiento from "../capture/IMotivoMovimiento";
import IPuesto from "../capture/IPuesto";
import IDepartamento from "../capture/IDepartamento";
import useGetEmpresas from "../../hooks/useGetEmpresas";
import dayjs from "dayjs";
import sendEmailVerify from "../../services/api/sendEmailVerify";
import sendSMSVerify from "../../services/api/sendSMSVerify";

const Captura = () => {
  const user = getUserLocal();
  const [userType, setUserType] = useState("4");
  const history = useHistory();

  const location: any = useLocation();
  const [departamentos, setDepartamentos] = useState<IDepartamento[]>([]);
  const [puestos, setPuestos] = useState<IPuesto[]>([]);
  const [movimientos, setMovimientos] = useState<IMovimiento[]>([]);
  const [disabledMotivo, setDisabledMotivo] = useState(false);
  const [terminado, setTerminado] = useState(false);
  const id_empleado = location.search.replace("?/", ""); // <-- Aquí está el ID del empleado
  const [porcentajeAvance, setPorcentajeAvance] = useState(0);
  const [estatusValidar, setEstatusValidar] = useState(false);
  const [companies] = useGetEmpresas(user.id);
  const [EMPLEADO, setEmpleado] = useState<IEmpleado>({
    /* Campos de Información Básica */
    nombre: "",
    a_paterno: "",
    a_materno: "",
    imss: "",
    file_imss: {
      file: "",
      file_name: "",
    },
    curp: "",
    file_curp: {
      file: "",
      file_name: "",
    },
    rfc: "",
    file_rfc: {
      file: "",
      file_name: "",
    },
    dir_fisc: "",
    dir: "",
    correo: "",
    tel: "",
    fecha_nac: "",
    identificacion: "",
    file_identificacion: {
      file: "",
      file_name: "",
    },
    file_acta: {
      file: "",
      file_name: "",
    },
    file_foto_inf: {
      file: "",
      file_name: "",
    },
    file_foto_fir: {
      file: "",
      file_name: "",
    },
    id_ruta: "",
    imss_ruta: "",
    curp_ruta: "",
    rfc_ruta: "",
    acta_ruta: "",
    foto_inf_ruta: "",
    firma_ruta: "",

    /* Campos de status -> color semáforo */
    id_status: 0,
    imss_status: 0,
    curp_status: 0,
    rfc_status: 0,
    acta_status: 0,
    foto_inf_status: 0,
    firma_status: 0,
    cuenta_status: 0,
    correo_status: 0,
    telefono_status: 0,
    porcentaje: 0,

    /*Campos de Ingreso*/
    fecha_ing: "",
    puesto: "",
    departamento: "",
    empresa: "",
    /*Campos de Nómina*/
    cuenta_nomina: "",
    beneficiario: "",
    clabe_interbancaria: "",
    numero_tarjeta: "",
    banco: "",
    /*Bandera para saber si la información solo se esta guardando o también enviando*/
    status_envio: "0",
    id_usuario_accion: user.id,
    id_empleado: id_empleado,
    fecha_alta: "",
    autorizacion_migracion: 0,
  });

  /*Excepción de campos para poder enviar la información del empleado a validar*/
  const excepcionNombresEnviar = [
    "id_ruta",
    "imss_ruta",
    "curp_ruta",
    "rfc_ruta",
    "acta_ruta",
    "foto_inf_ruta",
    "firma_ruta",
  ];

  interface IClasesValidacion {
    [key: string]: string;
  }
  interface IMensajes {
    [key: string]: string | boolean;
  }
  /*----------------------------Hooks para validar los campos obligatorios----------------------------*/
  const [mensajeError, setMensaje] = useState<IMensajes>({});
  const [classInvalid, setClassInvalid] = useState<IClasesValidacion>({});
  /*---------------------------------------Hooks y Funciones para imagenes y pdf------------------------------------------------*/
  const [openIMGModal, setOpenIMGModal] = useState(false);
  const [urlsImagen, setUrlsImagen] = useState<string>("");
  const [urlsPDF, setUrlsPDF] = useState<string>("");
  const [nombreImagenMostrada, setNombreImagenMostrada] = useState<string>("");
  const [esPDF, setEsPDF] = useState<boolean>(false);
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [botonTerminar, setBotonTerminar] = useState<boolean>(false);
  interface IArchivoMigración {
    [key: string]: any;
  }
  const [archivosMigracions, setArchivosMigracion] =
    useState<IArchivoMigración>({
      file_imss: "",
      file_curp: "",
      file_rfc: "",
      file_identificacion: "",
      file_acta: "",
      file_foto_inf: "",
      file_foto_fir: "",
    });
  /*------------------------------Hooks para modales y funcionalidades-----------------------------*/
  const [botonGuardarClass, setBotonGuardarClass] =
    useState("estiloBotonGuardar");
  const [botonEnviarClass, setBotonEnviarClass] = useState(
    "botonCapturaEnviarDesactivado"
  );
  /*-------------------Hooks para el funcionamiento de los botones de ver archivo------------------*/
  const [buttonFile, setButtonFile] = useState({
    file_imss: false,
    file_curp: false,
    file_rfc: false,
    file_identificacion: false,
    file_acta: false,
    file_foto_inf: false,
    file_foto_fir: false,
  });
  /*----------------Hooks para saber si se conserva o no el archivo de la migracion----------------*/
  interface IArchivoActual {
    [key: string]: boolean;
  }
  const [archivoActual, setArchivoActual] = useState<IArchivoActual>({
    file_imss: false,
    file_curp: false,
    file_rfc: false,
    file_identificacion: false,
    file_acta: false,
    file_foto_inf: false,
    file_foto_fir: false,
  });
  /*--------------------------Objeto de mensajes para los campos obligatorios--------------------------*/
  interface IMensaje {
    [key: string]: string;
  }
  const mensajes: IMensaje = {
    nombre: "El nombre es requerido",
    a_paterno: "El apellido paterno es requerido",
    a_materno: "El apellido materno es requerido",
    imss: "El Número de Seguro Social (NSS) es requerido",
    curp: "El CURP es requerido",
    rfc: "El RFC es requerido",
    dir_fisc: "La dirección fiscal es requerida",
    dir: "La dirección es requerida",
    correo: "El email es requerido",
    tel: "El teléfono es requerido",
    fecha_nac: "La fecha de nacimiento es requerida",
    identificacion: "El número de la identificación es requerido",
    fecha_ing: "La fecha de ingreso es requerida",
    puesto: "El puesto es requerido",
    departamento: "El departamento es requerido",
    empresa: "La empresa es requerida",
  };

  /* --------------------------Objeto de mensajes para los campos de archivos-------------------------- */
  interface IMensajeArchivos {
    [key: string]: string;
  }
  const mensajesFile: IMensajeArchivos = {
    mensaje_general_tipo:
      "El archivo ingresado no es soportado, debe ser imagen o PDF",
    mensaje_general_tam:
      "El archivo ingresado es muy grande, el máximo es de 3MB",
    file_imss: "El archivo del NSS es requerido",
    file_curp: "El archivo de la CURP es requerido",
    file_rfc: "El archivo del RFC es requerido",
    file_identificacion: "El archivo de la identificación es requerido",
    file_acta: "El archivo del acta de nacimiento es requerido",
    file_foto_inf: "El archivo de la foto infantil es requerido",
    file_foto_fir: "El archivo de la firma es requerido",
  };

  /*----------------------------Mensajes para otros errores y validaciones----------------------------*/
  const mensajesErrorOtros = {
    errorTel: "El teléfono debe tener 10 digitos",
    errorCtaNom: "La cuenta de banco debe tener 10 digitos",
    errorCLABE: "La CLABE debe tener 18 digitos",
    errorNumTar: "El número de tarjeta debe ser de 16 digitos",
  };
  const excepcionNombres = [
    "tel",
    "correo",
    "rfc",
    "curp",
    "imss",
    "cuenta_nomina",
    "clabe_interbancaria",
    "numero_tarjeta",
  ];
  /*---------------------Mensajes de alertas que se muestran dependiendo la acción--------------------*/
  const editadoToast = () => toast.success("Empleado editado exitosamente.");
  const sendEmailVerifyToast = () =>
    toast.success("Correo enviado exitosamente.");
  const errorSendEmailVerifyToast = () =>
    toast.error("Error al enviar el correo.");
  const sendSMSVerifyToast = () => toast.success("SMS enviado exitosamente.");
  const errorSendSMSVerifyToast = () => toast.error("Error al enviar el SMS.");
  const envioToast = () =>
    toast.success("Empleado enviado a validar exitosamente.");
  const terminadoToast = () => toast.success("Empleado validado correctamente");
  /*---------Hooks para los movimientos del empleado tanto en la edición como en los nuevos movimientos-----------*/
  const [movimientosEmpleado, setMovimientosEmpleado] = useState<any[]>([]);
  const [nuevoMovimiento, setNuevoMovimiento] = useState({
    id: "",
    curp: "",
    movimiento_nuevo: "",
    motivo_nuevo: "",
    fecha_mov_nuevo: "",
    fecha_ant_nuevo: "",
    ult_mov_nuevo: 0,
    notas_nuevo: "",
    id_usuario_accion: user.id,
    movimiento_ausencia: 0,
  });
  const [nuevoMovimientoBoton, setNuevoMovimientoBoton] = useState<boolean>(false);
  const [disabledMovimientoAnt, setDisabledMovimientoAnt] = useState<boolean>(false);
  const [disabledMovimientoAntCont, setDisabledMovimientoAntCont] =
    useState(false);
  const [movimientosEmpleadoNuevo, setMovimientosEmpleadoNuevo] = useState<
    IMotivoMovimiento[]
  >([]);
  const [selectedMotivo, setSelectedMotivo] = useState("MOTMOV0");
  const [cuentasEmpleado, setCuentasEmpleado] = useState([]);

  function handleStatus(status: number) {
    if (terminado) {
      return status;
    }
    if (status === 3) {
      status = 0;
    } else {
      status += 1;
    }
    terminar(status);

    return status;
  }
  function terminar(status: number) {
    var circulosVerdes = document.querySelectorAll(".cir_green").length;
    if (status === 1) {
      circulosVerdes += 1;
    }
    if (status === 2) circulosVerdes -= 1;
    var retornado;
    if (circulosVerdes === 10) {
      retornado = false;
    } else {
      retornado = true;
    }
    setBotonTerminar(retornado);
  }

  const getMotivoMovimientos = async (id_movimiento: string) => {
    return axios
      .post(API_PORTAL_URL + "get/motivo-movimientos", {
        id_movimiento,
      })
      .then((response) => {
        // setMotivoMovimientos(response.data);
        return response.data;
      });
  };

  const getMotivoMovimientosNuevo = async (id_movimiento: string) => {
    return axios
      .post(API_PORTAL_URL + "get/motivo-movimientos", {
        id_movimiento,
      })
      .then((response) => {
        setMovimientosEmpleadoNuevo(response.data);
      });
  };

  /* -------Función para detectar el cambio de los input y validar aquellos que son obligatorios-------- */
  function handleChangeInfo(e: React.ChangeEvent<HTMLInputElement> | any) {
    let { name, value } = e.target;
    const obligatorio = e.target.className.split(" ");
    validarEnviarGuardarEstado();
    if (obligatorio.includes("obligatorio")) {
      if (value === "") {
        setMensaje((mensajeError) => ({
          ...mensajeError,
          [name]: mensajes[name],
        }));
        setClassInvalid((classInvalid) => ({
          ...classInvalid,
          [name]: "is-invalid",
        }));
      } else {
        setMensaje((mensajeError) => ({
          ...mensajeError,
          [name]: "",
        }));
        setClassInvalid((classInvalid) => ({
          ...classInvalid,
          [name]: "",
        }));
      }
    }
    /* Parte para pasar el nombre al beneficiario */
    if (name === "nombre") {
      if (
        value !== "" &&
        EMPLEADO.a_paterno !== "" &&
        EMPLEADO.a_materno !== ""
      ) {
        setEmpleado((EMPLEADO) => ({
          ...EMPLEADO,
          beneficiario:
            value + " " + EMPLEADO.a_paterno + " " + EMPLEADO.a_materno,
        }));
      }
    }
    if (name === "a_paterno") {
      if (value !== "" && EMPLEADO.nombre !== "" && EMPLEADO.a_materno !== "") {
        setEmpleado((EMPLEADO) => ({
          ...EMPLEADO,
          beneficiario:
            EMPLEADO.nombre + " " + value + " " + EMPLEADO.a_materno,
        }));
      }
    }
    if (name === "a_materno") {
      if (value !== "" && EMPLEADO.nombre !== "" && EMPLEADO.a_paterno !== "") {
        setEmpleado((EMPLEADO) => ({
          ...EMPLEADO,
          beneficiario:
            EMPLEADO.nombre + " " + EMPLEADO.a_paterno + " " + value,
        }));
      }
    }
    /* Parte para validar la CURP */
    if (name === "curp") {
      setBotonGuardarClass("estiloBotonGuardar");
      if (value === "" && obligatorio.includes("obligatorio")) {
        setMensaje((mensajeError) => ({
          ...mensajeError,
          [name]: mensajes[name],
        }));
        setClassInvalid((classInvalid) => ({
          ...classInvalid,
          [name]: "is-invalid",
        }));
      } else {
        if (value !== "") {
          EMPLEADO.autorizacion_migracion = 0;
          value = value.toUpperCase();
          const [errCURP, msnCURP] = validarCURP(value);
          if (errCURP) {
            setMensaje((mensajeError) => ({
              ...mensajeError,
              [name]: msnCURP,
            }));
            setClassInvalid((classInvalid) => ({
              ...classInvalid,
              [name]: "is-invalid",
            }));
          } else {
            setMensaje((mensajeError) => ({
              ...mensajeError,
              [name]: "",
            }));
            setClassInvalid((classInvalid) => ({
              ...classInvalid,
              [name]: "",
            }));
          }
        } else {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [name]: "",
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [name]: "",
          }));
        }
      }
    }
    /* Parte para validar el IMSS */
    if (name === "imss") {
      if (value === "" && obligatorio.includes("obligatorio")) {
        setMensaje((mensajeError) => ({
          ...mensajeError,
          [name]: mensajes[name],
        }));
        setClassInvalid((classInvalid) => ({
          ...classInvalid,
          [name]: "is-invalid",
        }));
      } else {
        if (value !== "") {
          const [err, msn] = validarNSS(value);
          if (err) {
            setMensaje((mensajeError) => ({
              ...mensajeError,
              [name]: msn,
            }));
            setClassInvalid((classInvalid) => ({
              ...classInvalid,
              [name]: "is-invalid",
            }));
          } else {
            setMensaje((mensajeError) => ({
              ...mensajeError,
              [name]: "",
            }));
            setClassInvalid((classInvalid) => ({
              ...classInvalid,
              [name]: "",
            }));
          }
        } else {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [name]: "",
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [name]: "",
          }));
        }
      }
    }
    /* Parte para validar el RFC */
    if (name === "rfc") {
      if (value === "" && obligatorio.includes("obligatorio")) {
        setMensaje((mensajeError) => ({
          ...mensajeError,
          [name]: mensajes[name],
        }));
        setClassInvalid((classInvalid) => ({
          ...classInvalid,
          [name]: "is-invalid",
        }));
      } else {
        if (value !== "") {
          value = value.toUpperCase();
          const [errRFC, msnRFC] = validarRFC(value);
          if (errRFC) {
            setMensaje((mensajeError) => ({
              ...mensajeError,
              [name]: msnRFC,
            }));
            setClassInvalid((classInvalid) => ({
              ...classInvalid,
              [name]: "is-invalid",
            }));
          } else {
            setMensaje((mensajeError) => ({
              ...mensajeError,
              [name]: "",
            }));
            setClassInvalid((classInvalid) => ({
              ...classInvalid,
              [name]: "",
            }));
          }
        } else {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [name]: "",
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [name]: "",
          }));
        }
      }
    }
    /* Validar el formato del correo, esta validación se pone acá por si el correo no es obligatorio */
    if (name === "correo") {
      if (value === "" && obligatorio.includes("obligatorio")) {
        setMensaje((mensajeError) => ({
          ...mensajeError,
          [name]: mensajes[name],
        }));
        setClassInvalid((classInvalid) => ({
          ...classInvalid,
          [name]: "is-invalid",
        }));
      } else {
        if (!validarCorreo(value) && value !== "") {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [name]: "El formato del correo es inválido",
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [name]: "is-invalid",
          }));
        } else {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [name]: "",
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [name]: "",
          }));
        }
      }
    }
    /* Validar la longitud del teléfono, esta validación se pone acá por si el teléfono no es obligatorio */
    if (name === "tel") {
      if (value === "" && obligatorio.includes("obligatorio")) {
        setMensaje((mensajeError) => ({
          ...mensajeError,
          [name]: mensajes[name],
        }));
        setClassInvalid((classInvalid) => ({
          ...classInvalid,
          [name]: "is-invalid",
        }));
      } else {
        if (value !== "" && !validarTelefono(value)) {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [name]: mensajesErrorOtros.errorTel,
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [name]: "is-invalid",
          }));
        } else {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [name]: "",
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [name]: "",
          }));
        }
      }
    }
    /* Parte para validar las longitudes de los datos bancarios */
    if (
      name === "cuenta_nomina" ||
      name === "clabe_interbancaria" ||
      name === "numero_tarjeta"
    ) {
      if (name === "cuenta_nomina") {
        if (value !== "" && validarCuentaNomina(value)) {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [name]: mensajesErrorOtros.errorCtaNom,
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [name]: "is-invalid",
          }));
        } else {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [name]: "",
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [name]: "",
          }));
        }
      } else if (name === "clabe_interbancaria") {
        if (value !== "" && validarCLABE(value)) {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [name]: mensajesErrorOtros.errorCLABE,
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [name]: "is-invalid",
          }));
        } else {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [name]: "",
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [name]: "",
          }));
        }
      } else {
        if (value !== "" && validarNumTar(value)) {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [name]: mensajesErrorOtros.errorNumTar,
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [name]: "is-invalid",
          }));
        } else {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [name]: "",
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [name]: "",
          }));
        }
      }
    }
    /* Parte para válidar los select de puesto y departamento */
    if (name === "puesto" || name === "departamento") {
      /* Se validan los campos select */
      if (value === "0") {
        setMensaje((mensajeError) => ({
          ...mensajeError,
          [name]: mensajes[name],
        }));
        setClassInvalid((classInvalid) => ({
          ...classInvalid,
          [name]: "is-invalid",
        }));
      } else {
        setMensaje((mensajeError) => ({
          ...mensajeError,
          [name]: "",
        }));
        setClassInvalid((classInvalid) => ({
          ...classInvalid,
          [name]: "",
        }));
      }
    }
    /* Cuando se da click en enviar y editar se pintan los mensajes de error, con este fragmento de código se eliminan si no es obligaotio */
    if (
      value !== "" &&
      !obligatorio.includes("obligatorio") &&
      !excepcionNombres.includes(name)
    ) {
      setMensaje((mensajeError) => ({
        ...mensajeError,
        [name]: "",
      }));
      setClassInvalid((classInvalid) => ({
        ...classInvalid,
        [name]: "",
      }));
    }
    if (name === "fecha_mov") {
      if (value !== "") {
        const fecha_mov = new Date(value);
        const hoy = new Date();
        setEmpleado((EMPLEADO) => ({
          ...EMPLEADO,
          dias_ult_mov: getDiferenciaFechasEnDias(fecha_mov, hoy),
        }));
      }
    }
    setEmpleado((EMPLEADO) => ({
      ...EMPLEADO,
      [name]: value,
    }));
  }

  /*Función para válidar el número teléfonico*/
  function validarTelefono(tel: string) {
    var isphone =
      /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/.test(
        tel
      );
    return isphone;
  }
  // function isTerminado() {
  //   if (EMPLEADO.status === "5") {
  //     return true;
  //   } else return false;
  // }

  function handleChangeMovimiento(
    e: React.ChangeEvent<HTMLSelectElement> | any,
    bandera?: number
  ) {
    const [name, idx] = e.target.name.split("-");
    const value = e.target.value;
    if (bandera === 0) {
      var newArray: any = [...movimientosEmpleado];
      /*Parte para cambiar la bandera a 1 en caso de que sea de auscencia*/
      if (e.target.options[e.target.selectedIndex].text === "Ausencia") {
        newArray[0].movimiento_ausencia = 1;
      } else {
        newArray[0].movimiento_ausencia = 0;
      }
      setMovimientosEmpleado(newArray);
    }
    var movs: any = movimientosEmpleado.slice();

    movs[idx][name] = value;
    /*Parte para obtener la diferencia de días*/
    let fecha_uno: Date;
    let fecha_dos: Date;
    var diferenciaDias = 0;
    if (name === "fecha_mov") {
      fecha_uno = new Date(e.target.value);
      fecha_uno.setMinutes(
        fecha_uno.getMinutes() + fecha_uno.getTimezoneOffset()
      );
      fecha_dos = new Date(movs[idx]["fecha_ant"]);
      fecha_dos.setMinutes(
        fecha_dos.getMinutes() + fecha_dos.getTimezoneOffset()
      );
      diferenciaDias = getDiferenciaFechasEnDias(fecha_uno, fecha_dos);
      if (isNaN(diferenciaDias)) {
        fecha_dos = new Date();
        diferenciaDias = getDiferenciaFechasEnDias(fecha_uno, fecha_dos);
        movs[idx]["ult_mov"] = diferenciaDias;
      } else {
        movs[idx]["ult_mov"] = diferenciaDias;
      }
    }
    if (name === "fecha_ant") {
      fecha_uno = new Date(movs[idx]["fecha_mov"]);
      fecha_uno.setMinutes(
        fecha_uno.getMinutes() + fecha_uno.getTimezoneOffset()
      );
      fecha_dos = e.target.value;
      fecha_dos = new Date(e.target.value);
      fecha_dos.setMinutes(
        fecha_dos.getMinutes() + fecha_dos.getTimezoneOffset()
      );
      diferenciaDias = getDiferenciaFechasEnDias(fecha_uno, fecha_dos);
      if (!isNaN(diferenciaDias)) {
        movs[idx]["ult_mov"] = diferenciaDias;
      }
    }

    setMovimientosEmpleado(movs);
  }

  const handleMovimientosChange = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    handleChangeMovimiento(e, 0);

    let [name, idx] = e.target.name.split("-");
    name = "list_motivos";

    handleChangeMovimiento(
      {
        target: {
          name: name + "-" + idx,
          value: await getMotivoMovimientos(e.target.value),
        },
      },
      1
    );
  };

  /*Función para detectar el cambio del state del nuevo movimiento*/
  function handleChangeMovimientoNuevo(
    e: React.ChangeEvent<HTMLInputElement | any>
  ) {
    setNuevoMovimiento((nuevoMovimiento) => ({
      ...nuevoMovimiento,
      [e.target.name]: e.target.value,
    }));
    /*Parte para obtener la diferencia de días*/
    let fecha_uno: Date;
    let fecha_dos: Date;
    var diferenciaDias = 0;
    if (e.target.name === "fecha_mov_nuevo") {
      fecha_uno = new Date(e.target.value);
      fecha_uno.setMinutes(
        fecha_uno.getMinutes() + fecha_uno.getTimezoneOffset()
      );
      fecha_dos = new Date(nuevoMovimiento.fecha_ant_nuevo);
      fecha_dos.setMinutes(
        fecha_dos.getMinutes() + fecha_dos.getTimezoneOffset()
      );
      diferenciaDias = getDiferenciaFechasEnDias(fecha_uno, fecha_dos);
      if (isNaN(diferenciaDias)) {
        fecha_dos = new Date();
        diferenciaDias = getDiferenciaFechasEnDias(fecha_uno, fecha_dos);
        setNuevoMovimiento((nuevoMovimiento) => ({
          ...nuevoMovimiento,
          ult_mov_nuevo: diferenciaDias,
        }));
      } else {
        setNuevoMovimiento((nuevoMovimiento) => ({
          ...nuevoMovimiento,
          ult_mov_nuevo: diferenciaDias,
        }));
      }
    }
    if (e.target.name === "fecha_ant_nuevo") {
      fecha_uno = new Date(nuevoMovimiento.fecha_mov_nuevo);
      fecha_uno.setMinutes(
        fecha_uno.getMinutes() + fecha_uno.getTimezoneOffset()
      );
      fecha_dos = e.target.value;
      fecha_dos = new Date(e.target.value);
      fecha_dos.setMinutes(
        fecha_dos.getMinutes() + fecha_dos.getTimezoneOffset()
      );
      diferenciaDias = getDiferenciaFechasEnDias(fecha_uno, fecha_dos);
      if (!isNaN(diferenciaDias)) {
        setNuevoMovimiento((nuevoMovimiento) => ({
          ...nuevoMovimiento,
          ["ult_mov_nuevo"]: diferenciaDias,
        }));
      }
    }
  }

  /*---Funciones para detectar los movimientos y dependiendo el elegido se cambien los motivos del nuevo movimiento---*/
  function handleMovimientosNuevoChange(
    e: React.ChangeEvent<HTMLSelectElement>
  ) {
    if (e.target.value !== "0") {
      /*Parte para cambiar la bandera a 1 en caso de que sea de auscencia*/
      if (e.target.options[e.target.selectedIndex].text === "Ausencia") {
        setNuevoMovimiento((nuevoMovimiento) => ({
          ...nuevoMovimiento,
          movimiento_ausencia: 1,
        }));
      } else {
        setNuevoMovimiento((nuevoMovimiento) => ({
          ...nuevoMovimiento,
          ["movimiento_ausencia"]: 0,
        }));
      }
      /*Se agrega el valor del */
      setDisabledMotivo(false);
      getMotivoMovimientosNuevo(e.target.value);
      setSelectedMotivo("0");
      setNuevoMovimiento((nuevoMovimiento) => ({
        ...nuevoMovimiento,
        [e.target.name]: e.target.value,
      }));
    } else {
      setDisabledMotivo(true);
      setSelectedMotivo("0");
      setNuevoMovimiento((nuevoMovimiento) => ({
        ...nuevoMovimiento,
        [e.target.name]: "",
      }));
    }
  }

  function handleMotivoMovimientoNuevoChange(
    e: React.ChangeEvent<HTMLSelectElement>
  ) {
    if (e.target.value !== "0") {
      setNuevoMovimiento((nuevoMovimiento) => ({
        ...nuevoMovimiento,
        [e.target.name]: e.target.value,
      }));
      setSelectedMotivo(e.target.value);
    } else {
      setNuevoMovimiento((nuevoMovimiento) => ({
        ...nuevoMovimiento,
        [e.target.name]: "",
      }));
      setSelectedMotivo("0");
    }
  }

  // function handleChangeCuenta(e) {
  //   const [name, idx] = e.target.name.split("-");
  //   const value = e.target.value;

  //   const ctas = cuentasEmpleado.slice();

  //   ctas[idx][name] = value;

  //   setCuentasEmpleado(ctas);
  // }

  function formatDate(date: Date) {
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    let monthFormat = month < 10 ? "0" + month.toString() : month.toString();
    let dayFormat = day < 10 ? "0" + day.toString() : day.toString();

    return year + "-" + monthFormat + "-" + dayFormat;
  }

  /* Función encargada de válidar el formato del NSS */
  function validarNSS(nss: string) {
    let mensajeError = "";
    let error = false;
    if (nss.length !== 11) {
      mensajeError = "La longitud del NSS debe ser igual a 11";
      error = true;
    } else {
      const re = /^(\d{2})(\d{2})(\d{2})\d{5}$/;
      const validado = nss.match(re) || [];
      const subDeleg = parseInt(validado[1], 10);
      const anno = new Date().getFullYear() % 100;
      let annoAlta = parseInt(validado[2], 10);
      let annoNac = parseInt(validado[3], 10);
      // Comparar años (excepto que no tenga año de nacimiento)
      if (subDeleg !== 97) {
        if (annoAlta <= anno) annoAlta += 100;
        if (annoNac <= anno) annoNac += 100;
        if (annoNac > annoAlta) {
          mensajeError = "Formato de NSS no válido";
          error = true;
        }
      }
      if (algoritmoLuhn(nss)) {
        mensajeError = "";
        error = false;
      } else {
        mensajeError = "Formato de NSS no válido";
        error = true;
      }
    }
    return [error, mensajeError];
  }

  /* El algoritmo de Luhn o fórmula de Luhn, también conocida como "algoritmo de módulo 10",
    es una fórmula de suma de verificación, utilizada para validar una diversidad de números de identificación */
  function algoritmoLuhn(nss: string) {
    let suma = 0;
    let par = false;
    let digito;
    for (let i = nss.length - 1; i >= 0; i--) {
      digito = parseInt(nss.charAt(i), 10);
      if (par) {
        if ((digito *= 2) > 9) {
          digito -= 9;
        }
      }
      par = !par;
      suma += digito;
    }
    // Un NSS es válido si el último dígito de la suma da 0.
    return suma % 10 === 0;
  }

  /* Función encargada de válidar el CURP */
  function validarCURP(curp: string) {
    let mensajeError = "";
    let error = false;
    if (curp.length !== 18) {
      mensajeError = "La longitud de la CURP debe ser igual a 18";
      error = true;
    } else {
      const re =
        /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
      const validado = curp.match(re);
      if (validado !== null) {
        if (parseInt(validado[2]) !== digitoVerificador(validado[1])) {
          mensajeError = "La CURP digitada es inválida";
          error = true;
        } else {
          mensajeError = "";
          error = false;
        }
      } else {
        mensajeError = "El formato de la CURP es inválido";
        error = true;
      }
    }
    return [error, mensajeError];
  }

  /* Función para validar que coincida el dígito verificador */
  function digitoVerificador(curp17: string) {
    const diccionario = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ";
    let lngSuma = 0.0;
    let lngDigito = 0.0;
    for (let i = 0; i < 17; i++) {
      lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
    }
    lngDigito = 10 - (lngSuma % 10);
    if (lngDigito === 10) {
      return 0;
    }
    return lngDigito;
  }

  /* Función encargada de válidar el RFC */
  function validarRFC(rfc: string, aceptarGenerico: boolean = true) {
    let mensajeError = "";
    let error = false;
    if (rfc.length !== 13) {
      mensajeError = "La longitud del RFC debe ser igual a 13";
      error = true;
    } else {
      const re =
        /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
      const validado = rfc.match(re);
      if (validado !== null) {
        // Separar el dígito verificador del resto del RFC
        const digitoVerificador = validado.pop() as string;
        const rfcSinDigito = validado.slice(1).join("");
        const len = rfcSinDigito.length;

        // Obtener el digito esperado
        const diccionario = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ";
        const indice = len + 1;
        let suma = 0;
        let digitoEsperado;
        for (let i = 0; i < len; i++) {
          suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
        }
        digitoEsperado = 11 - (suma % 11);
        if (digitoEsperado === 11) digitoEsperado = 0;
        else if (digitoEsperado === 10) digitoEsperado = "A";

        // El dígito verificador coincide con el esperado?
        // o es un RFC Genérico (ventas a público general)?
        if (
          parseInt(digitoVerificador) !== digitoEsperado &&
          (!aceptarGenerico ||
            rfcSinDigito + digitoVerificador !== "XAXX010101000")
        ) {
          mensajeError = "El formato del RFC es inválido";
          error = true;
        } else if (
          !aceptarGenerico &&
          rfcSinDigito + digitoVerificador === "XEXX010101000"
        ) {
          mensajeError = "El formato del RFC es inválido";
          error = true;
        }
      } else {
        mensajeError = "El formato del RFC es inválido";
        error = true;
      }
    }
    return [error, mensajeError];
  }

  /* Función para validar el formato del correo eléctrónico */
  function validarCorreo(correo: string) {
    let correcto = false;
    if (
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
        correo
      )
    ) {
      correcto = true;
    }
    return correcto;
  }

  /* Función para detectar el cambio de los botones de archivos y validar el tipo y peso de los mismos */
  function handleFileChange(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | any>
  ) {
    /* Variables y connstantes a utilizar */
    const file = e.target.files;
    const name = e.target.id;
    const pesoMaximo = 3; // Peso máximo soportado por los archivos
    const obligatorio = e.target.parentElement.className.split(" ");
    /* Se limpia lo guardado si existe algo */
    setEmpleado((EMPLEADO) => ({
      ...EMPLEADO,
      [name]: {
        file: "",
        file_name: "",
      },
    }));
    /* Si se agrega un archivo se verifica si todos los campos ya se llenarom */
    validarEnviarGuardarEstado();
    /* Se valida si se cargo un archivo o no */
    if (file.length > 0) {
      const pesoArchivo = file[0].size / 1000000;
      if (!validarExtension(file)) {
        // Se valida si el archivo es soportado
        setMensaje((mensajeError) => ({
          ...mensajeError,
          [name]: mensajesFile.mensaje_general_tipo,
        }));
        setClassInvalid((classInvalid) => ({
          ...classInvalid,
          [name]: "is-invalid",
        }));
        return;
      }
      if (pesoArchivo > pesoMaximo) {
        // Se valida si tiene un peso menor a 3mb
        setMensaje((mensajeError) => ({
          ...mensajeError,
          [name]: mensajesFile.mensaje_general_tam,
        }));
        setClassInvalid((classInvalid) => ({
          ...classInvalid,
          [name]: "is-invalid",
        }));
        return;
      }
      // Si pasa ambas validaciones se quitan los mensajes de error y se cambia el texto del botón por el nombre del archivo
      setEmpleado((EMPLEADO) => ({
        ...EMPLEADO,
        [name]: {
          file: file[0],
          file_name: file[0].name,
        },
      }));
      // En caso de que haya un archivo de migracion y este se cambie se eliminará el que este guardado en base de datos
      setArchivoActual((archivoMigracionActual) => ({
        ...archivoMigracionActual,
        [name]: false,
      }));
      setMensaje((mensajeError) => ({
        ...mensajeError,
        [name]: "",
      }));
      setClassInvalid((classInvalid) => ({
        ...classInvalid,
        [name]: "",
      }));
      setButtonFile((buttonFile) => ({
        ...buttonFile,
        [name]: true,
      }));
    } else {
      // En caso de que no se carge un archivo se valida si es obligatorio
      if (obligatorio.includes("obligatorio")) {
        // Si es obligatorio se mostrará el mensaje de error
        setMensaje((mensajeError) => ({
          ...mensajeError,
          [name]: mensajesFile[name],
        }));
        setClassInvalid((classInvalid) => ({
          ...classInvalid,
          [name]: "is-invalid",
        }));
      } else {
        // Si no es obligatorio se quitan los mensajes de error
        setMensaje((mensajeError) => ({
          ...mensajeError,
          [name]: "",
        }));
        setClassInvalid((classInvalid) => ({
          ...classInvalid,
          [name]: "",
        }));
      }
      setButtonFile((buttonFile) => ({
        ...buttonFile,
        [name]: false,
      }));
    }
  }

  /* Función para validar si el archivo ingresado cumple con las extensiones soportadas */
  function validarExtension(file: FileList) {
    const extension = file[0].name.split(".").pop() || "";
    const isSupported = ARCHIVOS_SOPORTADOS.includes(extension);
    return isSupported;
  }
  /* Función para obtener la diferencia de dos fechas en días */
  function getDiferenciaFechasEnDias(fec1: Date, fec2: Date) {
    const diffInMs = Math.abs(fec2.getTime() - fec1.getTime());
    return Math.round(diffInMs / (1000 * 60 * 60 * 24));
  }
  /* -----------------------------Funciones para válidar los datos bancarios----------------------------- */
  function validarCuentaNomina(cuenta_nomina: string) {
    let error = false;
    if (cuenta_nomina.length !== 10) {
      error = true;
    }
    return error;
  }

  function validarCLABE(cuenta_clabe: string) {
    let error = false;
    if (cuenta_clabe.length !== 18) {
      error = true;
    }
    return error;
  }

  function validarNumTar(numero_tarjeta: string) {
    let error = false;
    if (numero_tarjeta.length !== 16) {
      error = true;
    }
    return error;
  }
  /* ------------------------------Funciones para visualizar imagenes------------------------------------ */
  /* Función para mostrar imagen cargada al dar click en el botón de previsualizar archivo */
  const openImage = (id_file: string) => {
    setNumPages(0);
    setPageNumber(1);
    let nombreImagen = "";

    if (archivoActual[id_file]) {
      const arrayBuffer = base64ToArrayBuffer(archivosMigracions[id_file].data);
      const blob = new Blob([arrayBuffer], {
        type: archivosMigracions[id_file].headers["content-type"],
      });
      const url = URL.createObjectURL(blob);
      if (
        archivosMigracions[id_file].headers["content-type"] ===
        "application/pdf"
      ) {
        setEsPDF(true);
        setUrlsPDF(url);
      } else {
        setEsPDF(false);
        setUrlsImagen(url);
      }
    } else {
      const file = EMPLEADO[id_file].file;
      const url = URL.createObjectURL(file);
      if (file.type === "application/pdf") {
        setEsPDF(true);
        setUrlsPDF(url);
      } else {
        setEsPDF(false);
        setUrlsImagen(url);
      }
    }
    switch (id_file) {
      case "file_imss":
        nombreImagen = "del IMSS";
        break;
      case "file_curp":
        nombreImagen = "de la CURP";
        break;
      case "file_rfc":
        nombreImagen = "del RFC";
        break;
      case "file_identificacion":
        nombreImagen = "de la identificación";
        break;
      case "file_acta":
        nombreImagen = "del acta de nacimiento";
        break;
      case "file_foto_inf":
        nombreImagen = "de la foto";
        break;
      case "file_foto_fir":
        nombreImagen = "de la firma";
        break;
    }
    setNombreImagenMostrada(nombreImagen);
    setOpenIMGModal(true);
  };
  /* Función para cerrar el modal de la visualización de la imagen */
  function closeIMGModal() {
    setOpenIMGModal(false);
  }

  /* Funciones para realizar el conteo de las páginas del PDF */
  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () => {
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
  };

  const goToNextPage = () => {
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);
  };

  /* Función donde se validarán todos los datos obligatorios al guardar el empleado */
  function validarDatos(): boolean {
    let errores = false;
    CAMPOS_OBLIGATORIOS.map((e, key) => {
      /* Se validan los campos normales */
      if (e.substring(0, 4) !== "file") {
        if (EMPLEADO[e] === "") {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [e]: mensajes[e],
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [e]: "is-invalid",
          }));
          errores = true;
        } else {
          if (e === "imss") {
            /* Parte para validar el NSS */
            const [err, msn] = validarNSS(EMPLEADO[e]);
            if (err) {
              setMensaje((mensajeError) => ({
                ...mensajeError,
                [e]: msn,
              }));
              setClassInvalid((classInvalid) => ({
                ...classInvalid,
                [e]: "is-invalid",
              }));
              errores = true;
            } else {
              setMensaje((mensajeError) => ({
                ...mensajeError,
                [e]: "",
              }));
              setClassInvalid((classInvalid) => ({
                ...classInvalid,
                [e]: "",
              }));
            }
          } else if (e === "curp") {
            /* Parte para validar la CURP */
            const [errCURP, msnCURP] = validarCURP(EMPLEADO[e]);
            if (errCURP) {
              setMensaje((mensajeError) => ({
                ...mensajeError,
                [e]: msnCURP,
              }));
              setClassInvalid((classInvalid) => ({
                ...classInvalid,
                [e]: "is-invalid",
              }));
              errores = true;
            } else {
              setMensaje((mensajeError) => ({
                ...mensajeError,
                [e]: "",
              }));
              setClassInvalid((classInvalid) => ({
                ...classInvalid,
                [e]: "",
              }));
            }
          } else if (e === "rfc") {
            /* Parte para validar el RFC */
            const [errRFC, msnRFC] = validarRFC(EMPLEADO[e]);
            if (errRFC) {
              setMensaje((mensajeError) => ({
                ...mensajeError,
                [e]: msnRFC,
              }));
              setClassInvalid((classInvalid) => ({
                ...classInvalid,
                [e]: "is-invalid",
              }));
              errores = true;
            } else {
              setMensaje((mensajeError) => ({
                ...mensajeError,
                [e]: "",
              }));
              setClassInvalid((classInvalid) => ({
                ...classInvalid,
                [e]: "",
              }));
            }
          } else if (e === "puesto" || e === "departamento") {
            /* Se validan los campos select */
            if (EMPLEADO[e] === "0") {
              setMensaje((mensajeError) => ({
                ...mensajeError,
                [e]: mensajes[e],
              }));
              setClassInvalid((classInvalid) => ({
                ...classInvalid,
                [e]: "is-invalid",
              }));
              errores = true;
            }
          }
        }
      } else {
        /* Se validan los campos de archivos */
        if (EMPLEADO[e].file === "") {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [e]: mensajesFile[e],
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [e]: "is-invalid",
          }));
          errores = true;
        }
      }
    });
    return errores;
  }

  const status_names = [
    "id_status",
    "imss_status",
    "curp_status",
    "rfc_status",
    "acta_status",
    "foto_inf_status",
    "firma_status",
    "correo_status",
    "telefono_status",
    "cuenta_status",
  ];

  const handleTerminarEmpleado = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    handleEditarEmpleado(e);
    var route = API_PORTAL_URL + "terminar/empleado/" + EMPLEADO.id_empleado;
    axios
      .post(route, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        terminadoToast();
        setTimeout(() => {
          history.push({
            pathname: "/empleados",
            search: "/" + EMPLEADO["empresa"],
            state: { companies: location.state.companies },
          });
        }, 1100);
      })
      .catch((response) => {
        console.log(response);
      });
  };

  /*-------------------------------------Función para enviar la información a guardar-----------------------*/
  // const handleGuardarEdit = (e) => {
  //   e.preventDefault();

  //   var route = "editar/enviar/empleado";

  //   if (!validarDatos()) {
  //     const movimiento: any = movimientosEmpleado[0];

  //     axios
  //       .post(API_PORTAL_URL + "editar/movimiento", {
  //         id_movimiento_empleado: movimiento.id_mov_emp,
  //         movimiento: movimiento.movimiento,
  //         motivo_movimiento: movimiento.motivo,
  //         fecha_mov: movimiento.fecha_mov,
  //         fecha_fin: movimiento.fecha_ant,
  //         dias_ult_mov: movimiento.ult_mov,
  //         observaciones: movimiento.notas,
  //         id_usuario_accion: user.id,
  //       })
  //       .then((response) => {
  //         console.log(response);
  //       })
  //       .catch((response) => {
  //         console.log(response);
  //       });

  //     if (nuevoMovimientoBoton) {
  //       axios
  //         .post(API_PORTAL_URL + "nuevo/movimiento", {
  //           nuevoMovimiento,
  //         })
  //         .then((response) => {
  //           console.log(response);
  //         })
  //         .catch((response) => {
  //           console.log(response);
  //         });
  //     }
  //     axios
  //       .post(API_PORTAL_URL + route, EMPLEADO, {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       })
  //       .then((response) => {
  //         editadoToast();
  //         setTimeout(() => {
  //           history.push({
  //             pathname: "/empleados",
  //             search: "/" + EMPLEADO["empresa"],
  //             state: { companies: location.state.companies },
  //           });
  //         }, 1100);
  //       })
  //       .catch((response) => {
  //         console.log(response);
  //       });
  //   }
  // };

  const handleEditarEmpleado = (e: any, validarSemaforos: boolean = false, empleadoData: IEmpleado = {}) => {
    if(e?.preventDefault) {
      e.preventDefault();
    }
    let empleado: IEmpleado = {}
    if(Object.keys(empleadoData).length > 0) {
      empleado = empleadoData;
    } else {
      empleado = EMPLEADO;
    }
    let route = "editar/empleado";
    if (empleado.status === "1" || empleado.status === "2") {
      route = "editar/enviar/empleado";

      if (validarSemaforos) {
        route = "editar/validar/empleado";
      }
    }
    if (!validarDatos()) {
      const movimiento: any = movimientosEmpleado[0];

      axios
        .post(API_PORTAL_URL + "editar/movimiento", {
          id_movimiento_empleado: movimiento.id_mov_emp,
          movimiento: movimiento.movimiento,
          motivo_movimiento: movimiento.motivo,
          fecha_mov: movimiento.fecha_mov,
          fecha_fin: movimiento.fecha_ant,
          dias_ult_mov: movimiento.ult_mov,
          observaciones: movimiento.notas,
          id_usuario_accion: user.id,
          movimiento_ausencia: movimiento.movimiento_ausencia,
        })
        .then((response) => {
          // console.log(response);
        })
        .catch((response) => {
          console.log(response);
        });

      if (nuevoMovimientoBoton) {
        axios
          .post(API_PORTAL_URL + "nuevo/movimiento", {
            nuevoMovimiento,
          })
          .then((response) => {
            // console.log(response);
          })
          .catch((response) => {
            console.log(response);
          });
      }
      axios
        .post(API_PORTAL_URL + route, empleado, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          editadoToast();
          setTimeout(() => {
            history.push({
              pathname: "/empleados",
              search: "/" + empleado["empresa"],
              state: { companies: location.state.companies },
            });
          }, 1100);
        })
        .catch((response) => {
          console.log(response);
        });
    }
  };

  /*----------------------Función para obtener el documento guardado en la base de datos-----------------------*/
  async function obtenerArchivoGuardado(url: string, file: string) {
    const request = await axios
      .post(API_PORTAL_URL + "get/files", {
        responseType: "arraybuffer",
        ruta_archivo: url,
      })
      .then((response) => {
        setArchivosMigracion((archivosMigracions) => ({
          ...archivosMigracions,
          [file]: response,
        }));
        let extension = response.headers["content-type"].split("/");
        setEmpleado((EMPLEADO) => ({
          ...EMPLEADO,
          [file]: {
            file: "actual",
            file_name: file + "." + extension[1],
          },
        }));
      })
      .catch((response_1) => {
        return response_1;
      });
  }

  /* ----------------------Función para pasar un archivo de base64 a un arreglo de buffer----------------------- */
  function base64ToArrayBuffer(base64: string) {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  function changeStatus(s: string) {
    setEmpleado((EMPLEADO) => ({
      ...EMPLEADO,
      status_envio: s,
    }));
  }

  /*--------------------------------Funciones para el envío y guardado de datos--------------------------------*/
  /*Función para validar que todos los campos estén llenos al momento de envíar un dato*/
  function enviarGuardarInfo() {
    console.log("enviarGuardarInfo");
    let errores = false;
    const nombresDatos = Object.keys(EMPLEADO);
    var banderaCamposBancarios = false;
    nombresDatos.map((e, key) => {
      /*Se validan los campos normales*/
      if (e.substring(0, 4) !== "file" && !excepcionNombresEnviar.includes(e)) {
        if (
          e === "cuenta_nomina" ||
          e === "clabe_interbancaria" ||
          e === "numero_tarjeta"
        ) {
          if (EMPLEADO[e] !== "") {
            banderaCamposBancarios = true;
            if (e === "cuenta_nomina") {
              if (validarCuentaNomina(EMPLEADO[e])) {
                errores = true;
              }
            } else if (e === "clabe_interbancaria") {
              if (validarCLABE(EMPLEADO[e])) {
                errores = true;
              }
            } else if (e === "numero_tarjeta") {
              if (validarNumTar(EMPLEADO[e])) {
                errores = true;
              }
            }
          }
        } else if (
          e !== "cuenta_nomina" &&
          e !== "clabe_interbancaria" &&
          e !== "numero_tarjeta" &&
          EMPLEADO[e] === ""
        ) {
          errores = true;
        } else {
          if (e === "imss") {
            /*Parte para validar el NSS*/
            var [err, msn] = validarNSS(EMPLEADO[e]);
            if (err) {
              errores = true;
            }
          } else if (e === "curp") {
            /*Parte para validar la CURP*/
            var [errCURP, msnCURP] = validarCURP(EMPLEADO[e]);
            if (errCURP) {
              errores = true;
            }
          } else if (e === "rfc") {
            /*Parte para validar el RFC*/
            var [errRFC, msnRFC] = validarRFC(EMPLEADO[e]);
            if (errRFC) {
              errores = true;
            }
          } else if (e === "puesto" || e === "departamento") {
            /*Se validan los campos select*/
            if (EMPLEADO[e] === "0") {
              setMensaje((mensajeError) => ({
                ...mensajeError,
                [e]: mensajes[e],
              }));
              setClassInvalid((classInvalid) => ({
                ...classInvalid,
                [e]: "is-invalid",
              }));
              errores = true;
            }
          }
        }
      } else {
        /*Se validan los campos de archivos*/
        if (EMPLEADO[e].file === "") {
          setMensaje((mensajeError) => ({
            ...mensajeError,
            [e]: mensajesFile[e],
          }));
          setClassInvalid((classInvalid) => ({
            ...classInvalid,
            [e]: "is-invalid",
          }));
          errores = true;
        }
      }
    });
    if (!errores && banderaCamposBancarios) {
      axios
        .post(API_PORTAL_URL + "editar/enviar/empleado", EMPLEADO, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          envioToast();
          setTimeout(() => {
            history.push({
              pathname: "/empleados",
              search: "/" + EMPLEADO["empresa"],
              state: { companies: location.state.companies },
            });
          }, 1500);
        })
        .catch((response) => {
          console.log(response);
        });
    }
  }
  /*Función para activar o desactivar el botón de enviar*/
  function validarEnviarGuardarEstado() {
    let errores = false;
    const nombresDatos = Object.keys(EMPLEADO);
    var banderaCamposBancarios = false;
    nombresDatos.map((e, key) => {
      /*Se validan los campos normales*/
      if (e.substring(0, 4) !== "file" && !excepcionNombresEnviar.includes(e)) {
        if (
          e === "cuenta_nomina" ||
          e === "clabe_interbancaria" ||
          e === "numero_tarjeta"
        ) {
          if (EMPLEADO[e] !== "") {
            banderaCamposBancarios = true;
            if (e === "cuenta_nomina") {
              if (validarCuentaNomina(EMPLEADO[e])) {
                errores = true;
              }
            } else if (e === "clabe_interbancaria") {
              if (validarCLABE(EMPLEADO[e])) {
                errores = true;
              }
            } else if (e === "numero_tarjeta") {
              if (validarNumTar(EMPLEADO[e])) {
                errores = true;
              }
            }
          }
        } else if (
          e !== "cuenta_nomina" &&
          e !== "clabe_interbancaria" &&
          e !== "numero_tarjeta" &&
          EMPLEADO[e] === ""
        ) {
          errores = true;
        } else {
          if (e === "imss") {
            /*Parte para validar el NSS*/
            var [err, msn] = validarNSS(EMPLEADO[e]);
            if (err) {
              errores = true;
            }
          } else if (e === "curp") {
            /*Parte para validar la CURP*/
            var [errCURP, msnCURP] = validarCURP(EMPLEADO[e]);
            if (errCURP) {
              errores = true;
            }
          } else if (e === "rfc") {
            /*Parte para validar el RFC*/
            var [errRFC, msnRFC] = validarRFC(EMPLEADO[e]);
            if (errRFC) {
              errores = true;
            }
          } else if (e === "puesto" || e === "departamento") {
            /*Se validan los campos select*/
            if (EMPLEADO[e] === "0") {
              errores = true;
            }
          }
        }
      } else {
        /*Se validan los campos de archivos*/
        if (EMPLEADO[e].file_name === "") {
          errores = true;
        }
      }
    });
    if (!errores && banderaCamposBancarios) {
      setBotonEnviarClass("botonCapturaEnviar");
    } else {
      setBotonEnviarClass("botonCapturaEnviarDesactivado");
    }
  }

  /* ---------------------------------------Funciones para agregar movimientos------------------------------------------ */
  function agregarMovimiento(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
    e.preventDefault();
    setNuevoMovimientoBoton(true);
    setDisabledMovimientoAnt(true);
  }

  useEffect(() => {
    if (user) {
      const decodedToken: any = jwt_decode(user.accessToken);

      setUserType(decodedToken.rol);
    }

    const getDepartamentos = () => {
      return axios
        .get(API_PORTAL_URL + "get/departamentos", {})
        .then((response) => {
          setDepartamentos(response.data);
        });
    };

    const getPuestos = () => {
      return axios.get(API_PORTAL_URL + "get/puestos", {}).then((response) => {
        setPuestos(response.data);
      });
    };

    const getMovimientos = () => {
      return axios
        .get(API_PORTAL_URL + "get/movimientos", {})
        .then((response) => {
          setMovimientos(response.data);
        });
    };
    getDepartamentos();
    getPuestos();
    getMovimientos();

    axios
      .post(API_PORTAL_URL + "consultar/empleado", {
        id_empleado: id_empleado,
      })
      .then((response) => {
        const info = response.data.datos_empleado[0];
        console.log(info)

        const formato_fecha_nacimiento =
          info.fecha_nacimiento !== null &&
          info.fecha_nacimiento !== "2000-01-00T06:00:00.000Z"
            ? new Date(info.fecha_nacimiento).toISOString().slice(0, 10)
            : "";
        const formato_fecha_ingreso =
          info.fecha_ingreso !== null &&
          info.fecha_ingreso !== "2000-01-00T06:00:00.000Z"
            ? new Date(info.fecha_ingreso).toISOString().slice(0, 10)
            : "";
        const formato_fecha_alta =
          info.fecha_alta !== null
            ? new Date(info.fecha_alta).toISOString().slice(0, 10)
            : "";

        let contador = 0;
        contador =
          contador +
          parseInt(info.identificacion_status) +
          parseInt(info.imss_status) +
          parseInt(info.curp_status) +
          parseInt(info.rfc_status) +
          parseInt(info.acta_status) +
          parseInt(info.foto_status) +
          parseInt(info.firma_status) +
          parseInt(info.telefono_status) +
          parseInt(info.correo_status) +
          parseInt(info.cuenta_status) +
          parseInt(info.avance);
        setEmpleado((EMPLEADO) => ({
          ...EMPLEADO,
          ["nombre"]: info.nombre,
          ["a_paterno"]: info.apellido_paterno,
          ["a_materno"]: info.apellido_materno,
          ["imss"]: info.numero_imss,
          ["curp"]: info.curp,
          ["rfc"]: info.rfc,
          ["dir_fisc"]: info.direccion_fiscal,
          ["dir"]: info.direccion,
          ["correo"]: info.correo_electronico,
          ["tel"]: info.telefono,
          ["fecha_nac"]: formato_fecha_nacimiento,
          ["id_status"]: parseInt(info.identificacion_status),
          ["imss_status"]: parseInt(info.imss_status),
          ["curp_status"]: parseInt(info.curp_status),
          ["rfc_status"]: parseInt(info.rfc_status),
          ["acta_status"]: parseInt(info.acta_status),
          ["foto_inf_status"]: parseInt(info.foto_status),
          ["firma_status"]: parseInt(info.firma_status),
          ["telefono_status"]: parseInt(info.telefono_status),
          ["correo_status"]: parseInt(info.correo_status),
          ["cuenta_status"]: parseInt(info.cuenta_status),
          ["porcentaje"]: parseInt(info.avance),
          ["fecha_ing"]: formato_fecha_ingreso,
          ["puesto"]: info.id_puesto,
          ["departamento"]: info.id_departamento,
          ["empresa"]: info.id_empresa,
          ["status"]: info.status,
          ["cuenta_nomina"]:
            info.cuenta_bancaria !== null ? info.cuenta_bancaria : "",
          ["beneficiario"]:
            info.beneficiario_cuenta !== null ? info.beneficiario_cuenta : "",
          ["clabe_interbancaria"]:
            info.clabe_interbancaria !== null ? info.clabe_interbancaria : "",
          ["numero_tarjeta"]:
            info.numero_tarjeta !== null ? info.numero_tarjeta : "",
          ["banco"]: info.banco !== null ? info.banco : "",
          ["fecha_alta"]: formato_fecha_alta,
          ["identificacion"]: info.identificacion,
          token_email: info.token_email,
          es_valido_email: info.es_valido_email,
          token_telefono: info.token_telefono,
          es_valido_telefono: info.es_valido_telefono,
          id: info.id,
          avance: info.avance,
        }));
        if (contador !== 110) setBotonTerminar(true);
        if (EMPLEADO.status === "5" || info.status == "5") {
          setTerminado(true);
        }

        if (EMPLEADO.status === "2" || info.status == "2") {
          setEstatusValidar(true);
        }
        //Se
        setNuevoMovimiento((nuevoMovimiento) => ({
          ...nuevoMovimiento,
          ["curp"]: info.curp,
        }));
        setPorcentajeAvance(parseInt(info.avance));
        if (info.avance === "100") {
          setBotonEnviarClass("botonCapturaEnviar");
        }
        /*Se obtienen los archivos que estén registrados en la base de datos*/
        /*------------------Primeramente se obtiene el archivo del Imss--------------------*/
        if (info.ruta_numero_imss !== "") {
          obtenerArchivoGuardado(info.ruta_numero_imss, "file_imss");
          setButtonFile((buttonFile) => ({
            ...buttonFile,
            ["file_imss"]: true,
          }));
          setArchivoActual((archivoActual) => ({
            ...archivoActual,
            ["file_imss"]: true,
          }));
        }
        /*------------------Se obiene el archivo de la CURP--------------------------*/
        if (info.ruta_curp !== "") {
          obtenerArchivoGuardado(info.ruta_curp, "file_curp");
          setButtonFile((buttonFile) => ({
            ...buttonFile,
            ["file_curp"]: true,
          }));
          setArchivoActual((archivoActual) => ({
            ...archivoActual,
            ["file_curp"]: true,
          }));
        }
        /*------------------Se obiene el archivo del RFC--------------------------*/
        if (info.ruta_rfc !== "") {
          obtenerArchivoGuardado(info.ruta_rfc, "file_rfc");
          setButtonFile((buttonFile) => ({
            ...buttonFile,
            ["file_rfc"]: true,
          }));
          setArchivoActual((archivoActual) => ({
            ...archivoActual,
            ["file_rfc"]: true,
          }));
        }
        /*------------------Se obiene el archivo de la identificacion--------------------------*/
        if (info.ruta_identificacion !== "") {
          obtenerArchivoGuardado(
            info.ruta_identificacion,
            "file_identificacion"
          );
          setButtonFile((buttonFile) => ({
            ...buttonFile,
            ["file_identificacion"]: true,
          }));
          setArchivoActual((archivoActual) => ({
            ...archivoActual,
            ["file_identificacion"]: true,
          }));
        }
        /*------------------Se obiene el archivo del Acta--------------------------*/
        if (info.acta_nacimiento !== "") {
          obtenerArchivoGuardado(info.acta_nacimiento, "file_acta");
          setButtonFile((buttonFile) => ({
            ...buttonFile,
            ["file_acta"]: true,
          }));
          setArchivoActual((archivoActual) => ({
            ...archivoActual,
            ["file_acta"]: true,
          }));
        }
        /*------------------Se obiene el archivo de la foto infantil--------------------------*/
        if (info.foto_infantil !== "") {
          obtenerArchivoGuardado(info.foto_infantil, "file_foto_inf");
          setButtonFile((buttonFile) => ({
            ...buttonFile,
            ["file_foto_inf"]: true,
          }));
          setArchivoActual((archivoActual) => ({
            ...archivoActual,
            ["file_foto_inf"]: true,
          }));
        }
        /*------------------Se obiene el archivo de la foto firma--------------------------*/
        if (info.foto_firma !== "") {
          obtenerArchivoGuardado(info.foto_firma, "file_foto_fir");
          setButtonFile((buttonFile) => ({
            ...buttonFile,
            ["file_foto_fir"]: true,
          }));
          setArchivoActual((archivoActual) => ({
            ...archivoActual,
            ["file_foto_fir"]: true,
          }));
        }
      })
      .catch((response) => {
        console.log(response);
      });

    axios
      .post(API_PORTAL_URL + "movimientos", {
        id_empleado,
      })
      .then(async (response) => {
        const data = response.data;
        var banderaAusencia = false;
        var banderaProceso = false;
        var banderaContratacion = false;
        var banderaOtroMov = false;
        var banderaEntroOtroMov = false;
        var movs = [];
        /*Se mostrará el último movimiento*/
        //Se agrega el último id registrado en la bd, para que el nuevo se le aumente 1
        setNuevoMovimiento((nuevoMovimiento) => ({
          ...nuevoMovimiento,
          ["id"]: data.length,
        }));
        for (var i = 0; i < data.length; i++) {
          /*En caso de que el movimiento sea de ausencia*/
          if (data[i].movimiento_ausencia !== 0) {
            banderaAusencia = true;
            /*Se validará la fecha de ausencia*/
            const fecha_ant =
              data[i].fecha_fin !== null &&
              data[i].fecha_fin !== "2000-01-01T06:00:00.000Z"
                ? new Date(data[i].fecha_fin).toISOString().slice(0, 10)
                : "";
            const fecha_mov = new Date(data[i].fecha_movimiento);
            const hoy = new Date().toISOString().slice(0, 10);
            var difference =
              new Date(fecha_ant).getTime() - new Date(hoy).getTime();
            if (difference >= 0) {
              movs = [];
              setDisabledMovimientoAntCont(false);
              movs.push({
                id: data[i].id,
                id_mov_emp: data[i].id_movimiento_empleado,
                fecha_mov: formatDate(fecha_mov),
                movimiento: data[i].id_movimiento,
                motivo: data[i].id_motivo_movimiento,
                fecha_ant: fecha_ant,
                ult_mov: data[i].dias_ultimo_movimiento,
                notas: data[i].notas,
                list_motivos: await getMotivoMovimientos(data[i].id_movimiento),
                movimiento_ausencia: data[i].movimiento_ausencia,
              });
            } else {
              banderaAusencia = false;
            }
          } else if (data[i].movimiento_ausencia === 0 && !banderaAusencia) {
            const fecha_mov = new Date(data[i].fecha_movimiento);
            //const fecha_ant = new Date(data[i].fecha_fin);
            const fecha_ant =
              data[i].fecha_fin !== null &&
              data[i].fecha_fin !== "2000-01-01T06:00:00.000Z"
                ? new Date(data[i].fecha_fin).toISOString().slice(0, 10)
                : "";
            if (data[i].id_motivo_movimiento === "MOTMOV0") {
              setDisabledMovimientoAntCont(false);
              banderaProceso = true;
            } else if (data[i].movimiento === "Contratación") {
              setDisabledMovimientoAntCont(true);
              banderaContratacion = true;
            } else {
              setDisabledMovimientoAntCont(false);
              banderaOtroMov = true;
            }
            if ((banderaProceso || banderaContratacion) && banderaOtroMov) {
              if (
                data[i].movimiento !== "Contratación" &&
                data[i].movimiento !== "En proceso" &&
                data[i].movimiento !== "Ausencia"
              ) {
                getMotivoMovimientosNuevo(data[i].id_movimiento);
                banderaEntroOtroMov = true;
                movs = [];
                movs.push({
                  id: data[i].id,
                  id_mov_emp: data[i].id_movimiento_empleado,
                  fecha_mov: formatDate(fecha_mov),
                  movimiento: data[i].id_movimiento,
                  motivo: data[i].id_motivo_movimiento,
                  fecha_ant: fecha_ant,
                  ult_mov: data[i].dias_ultimo_movimiento,
                  notas: data[i].notas,
                  list_motivos: await getMotivoMovimientos(
                    data[i].id_movimiento
                  ),
                  movimiento_ausencia: data[i].movimiento_ausencia,
                });
              }
            } else if (
              !banderaEntroOtroMov &&
              data[i].movimiento !== "Ausencia"
            ) {
              movs = [];
              movs.push({
                id: data[i].id,
                id_mov_emp: data[i].id_movimiento_empleado,
                fecha_mov: formatDate(fecha_mov),
                movimiento: data[i].id_movimiento,
                motivo: data[i].id_motivo_movimiento,
                fecha_ant: fecha_ant,
                ult_mov: data[i].dias_ultimo_movimiento,
                notas: data[i].notas,
                list_motivos: await getMotivoMovimientos(data[i].id_movimiento),
                movimiento_ausencia: data[i].movimiento_ausencia,
              });
            }
          }
        }

        setMovimientosEmpleado(movs);
      })
      .catch((response) => {
        console.log(response);
      });
  }, [id_empleado]);

  const [porcentajeSemaforos, setPorcentajeSemaforos] = useState<number>(0);

  useEffect(() => {
    let porcentaje = 0;

    let greens = 0;
    let yellows = 0;
    let reds = 0;
    console.log(EMPLEADO)

    for (let i = 0; i < status_names.length; i++) {
      switch (EMPLEADO[status_names[i]]) {
        case 1:
          greens++;
          break;
        case 2:
          yellows++;
          break;
        case 3:
          reds++;
          break;
        default:
      }
    }

    porcentaje = ((greens + yellows + reds) * 100) / status_names.length;

    setPorcentajeSemaforos(porcentaje);
  }, [EMPLEADO])

  const circle_color = (num: number) => {
    if (num === 0) {
      return "cir_gray";
    } else if (num === 1) {
      return "cir_green";
    } else if (num === 2) {
      return "cir_yellow";
    } else {
      return "cir_red";
    }
  };

  function maxLengthCheck(e: any) {
    if (e.target.value.length > e.target.maxLength) {
      e.target.value = e.target.value.slice(0, e.target.maxLength);
    }
  }

  return (
    <div>
      <Dialog
        open={openIMGModal}
        onClose={closeIMGModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title text-center">
          {"Imagen del archivo " + nombreImagenMostrada}
        </DialogTitle>
        <DialogContent className="alert-dialog-content">
          {esPDF ? (
            <div>
              <nav>
                <button onClick={goToPrevPage}>Anterior</button>
                <button onClick={goToNextPage}>Siguiente</button>
                <p>
                  Página {pageNumber} de {numPages}
                </p>
              </nav>

              <Document file={urlsPDF} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} />
              </Document>
            </div>
          ) : (
            <DialogContentText id="alert-dialog-description">
              <img src={`${urlsImagen}`} />
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions className="alert-dialog-actions">
          <Button className="cancel-btn mx-5 px-5 py-2" onClick={closeIMGModal}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <ToastContainer
        position="bottom-center"
        theme="colored"
        autoClose={1000}
        closeButton={false}
        closeOnClick
      />

      <NavBar />

      <Subnavbar image={location.state.image} />

      <Row className="content">
        <Col className="content">
          <Row className="">
            <div className="col-md-10">
              <ProgressBar
                variant="success"
                now={porcentajeAvance}
                label={`${porcentajeAvance}%`}
                className="my-4 mx-5 alturaProgress"
              />
            </div>
            <div className="col-md-2 marginBotonEnvir">
              {(() => {
                if (EMPLEADO.status === "2") {
                  return (
                    <div>
                      <button
                        type="submit"
                        className={
                          botonTerminar
                            ? "btn-lg botonTerminard"
                            : "btn-lg botonTerminar"
                        }
                        onClick={handleTerminarEmpleado}
                        disabled={botonTerminar}
                      >
                        Terminar
                      </button>
                    </div>
                  );
                } else if (EMPLEADO.status === "5") {
                  return <b>VALIDADO</b>;
                } else {
                  return (
                    <button
                      type="submit"
                      className={`btn-lg ${botonEnviarClass}`}
                      style={{
                        backgroundColor: EMPLEADO.status === "1" && porcentajeSemaforos !== 100 ? "#c7c5c1" : "#f68b29",
                      }}
                      disabled={EMPLEADO.status === "1" && porcentajeSemaforos !== 100}
                      onClick={(e) => {
                         return EMPLEADO.status === "1"
                          ? handleEditarEmpleado(e, true)
                          : enviarGuardarInfo()
                      }}
                    >
                      {/*  <span>{EMPLEADO.status === '1' ? "Validar" : "Enviar a Validación"}</span>*/}

                      {(() => {
                        switch (EMPLEADO.status) {
                          case "1":
                            // return <span>En validación</span>;
                            return <span>Terminar</span>;
                          case "2":
                            return <span>Validado</span>;
                          default:
                            return <span>Enviar a validación</span>;
                        }
                      })()}
                    </button>
                  );
                }
              })()}
            </div>
          </Row>
          <Row className="creme-bckgrnd">
            <Form className="row-login-form" onSubmit={handleEditarEmpleado}>
              <Row className="titulos">
                <Typography
                  className="textoTitulos"
                  variant="h6"
                  fontWeight="bold"
                >
                  Información Básica
                </Typography>
              </Row>
              <Row className="mb-3 ms-2 me-2 mt-4">
                <Form.Group as={Col} md="4">
                  <InputGroup>
                    <InputGroup.Text id="nombre" className="estiloInputs">
                      Nombre{CAMPOS_OBLIGATORIOS.includes("nombre") ? "*" : ""}:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      aria-describedby="nombre"
                      disabled={terminado || estatusValidar}
                      name="nombre"
                      id="nombre"
                      className={`estiloInputsText ${
                        CAMPOS_OBLIGATORIOS.includes("nombre")
                          ? "obligatorio"
                          : ""
                      } ${classInvalid.nombre ? classInvalid.nombre : ""}`}
                      value={EMPLEADO.nombre}
                      onChange={handleChangeInfo}
                    />
                    <div className="invalid-feedback">
                      {mensajeError.nombre}
                    </div>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <InputGroup>
                    <InputGroup.Text id="a_paterno" className="estiloInputs">
                      Apellido Paterno
                      {CAMPOS_OBLIGATORIOS.includes("a_paterno")
                        ? "*"
                        : ""}:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      aria-describedby="a_paterno"
                      disabled={terminado || estatusValidar}
                      name="a_paterno"
                      className={`estiloInputsText ${
                        CAMPOS_OBLIGATORIOS.includes("a_paterno")
                          ? "obligatorio"
                          : ""
                      } ${
                        classInvalid.a_paterno ? classInvalid.a_paterno : ""
                      }`}
                      value={EMPLEADO.a_paterno}
                      onChange={handleChangeInfo}
                    />
                    <div className="invalid-feedback">
                      {mensajeError.a_paterno}
                    </div>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <InputGroup>
                    <InputGroup.Text id="a_materno" className="estiloInputs">
                      Apellido Materno
                      {CAMPOS_OBLIGATORIOS.includes("a_materno")
                        ? "*"
                        : ""}:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      aria-describedby="a_materno"
                      disabled={terminado || estatusValidar}
                      name="a_materno"
                      className={`estiloInputsText ${
                        CAMPOS_OBLIGATORIOS.includes("a_materno")
                          ? "obligatorio"
                          : ""
                      } ${
                        classInvalid.a_materno ? classInvalid.a_materno : ""
                      }`}
                      value={EMPLEADO.a_materno}
                      onChange={handleChangeInfo}
                    />
                    <div className="invalid-feedback">
                      {mensajeError.a_materno}
                    </div>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className="mb-3 ms-2 me-2 mt-4">
                <Form.Group as={Col} md="2">
                  <InputGroup>
                    <InputGroup.Text id="imss" className="estiloInputs">
                      IMSS{CAMPOS_OBLIGATORIOS.includes("imss") ? "*" : ""}:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="number"
                      aria-describedby="imss"
                      name="imss"
                      disabled={terminado}
                      className={`estiloInputsText ${
                        CAMPOS_OBLIGATORIOS.includes("imss")
                          ? "obligatorio"
                          : ""
                      } ${classInvalid.imss ? classInvalid.imss : ""}`}
                      value={EMPLEADO.imss}
                      onChange={handleChangeInfo}
                      maxLength={11}
                      onInput={maxLengthCheck}
                    />
                    <div className="invalid-feedback">{mensajeError.imss}</div>
                    {EMPLEADO.status !== "0" && EMPLEADO.status !== "6" ? (
                      <Col md={{ span: 1 }} align="center" hidden={false}>
                        <Circle
                          className={`fontCirculo ${circle_color(
                            EMPLEADO.imss_status
                          )}`}
                          //hidden={userType === '2'}
                          onClick={() => {
                            handleChangeInfo({
                              target: {
                                name: "imss_status",
                                value: handleStatus(EMPLEADO.imss_status),
                                className: "",
                              },
                            });
                          }}
                        ></Circle>
                      </Col>
                    ) : (
                      ""
                    )}
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="2">
                  <Row>
                    <InputGroup className="centrarBotones">
                      <label
                        htmlFor="file_imss"
                        className={`${classInvalid.file_imss}`}
                      >
                        <Input
                          // accept="image/*,.pdf"
                          id="file_imss"
                          type="file"
                          className={`${
                            CAMPOS_OBLIGATORIOS.includes("file_imss")
                              ? "obligatorio"
                              : ""
                          }`}
                          sx={{ display: { xl: "none", xs: "none" } }}
                          onChange={handleFileChange}
                        />
                        <Button
                          variant="contained"
                          component="span"
                          className="formatoBotonArchivos"
                          startIcon={<ArrowUpward />}
                        >
                          {`${
                            EMPLEADO.file_imss.file_name === ""
                              ? "Cargar Archivo"
                              : EMPLEADO.file_imss.file_name.replace(
                                  "file_",
                                  ""
                                )
                          }`}
                        </Button>
                      </label>
                      {buttonFile.file_imss ? (
                        <IconButton
                          size="medium"
                          className="estiloIconArchivo"
                          name="imss_button"
                          disabled={terminado}
                          onClick={() => openImage("file_imss")}
                        >
                          <Plagiarism className="estiloIconoArchivoDos" />
                        </IconButton>
                      ) : (
                        ""
                      )}
                      <div className="invalid-feedback">
                        {mensajeError.file_imss}
                      </div>
                    </InputGroup>
                  </Row>
                </Form.Group>
                <Form.Group as={Col} md="2">
                  <InputGroup>
                    <InputGroup.Text id="curp" className="estiloInputs">
                      CURP{CAMPOS_OBLIGATORIOS.includes("curp") ? "*" : ""}:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      aria-describedby="curp"
                      name="curp"
                      className={`estiloInputsText ${
                        CAMPOS_OBLIGATORIOS.includes("curp")
                          ? "obligatorio"
                          : ""
                      } ${classInvalid.curp ? classInvalid.curp : ""}`}
                      value={EMPLEADO.curp}
                      onChange={handleChangeInfo}
                      disabled
                    />
                    <div className="invalid-feedback">{mensajeError.curp}</div>
                    {EMPLEADO.status !== "0" && EMPLEADO.status !== "6" ? (
                      <Col md={{ span: 1 }} align="center" hidden={false}>
                        <Circle
                          className={`fontCirculo ${circle_color(
                            EMPLEADO.curp_status
                          )}`}
                          //hidden={userType === '2'}
                          onClick={() => {
                            handleChangeInfo({
                              target: {
                                name: "curp_status",
                                value: handleStatus(EMPLEADO.curp_status),
                                className: "",
                              },
                            });
                          }}
                        ></Circle>
                      </Col>
                    ) : (
                      ""
                    )}
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="2">
                  <InputGroup className="centrarBotones">
                    <label
                      htmlFor="file_curp"
                      className={`${classInvalid.file_curp}`}
                    >
                      <Input
                        // accept="image/*,.pdf"
                        id="file_curp"
                        type="file"
                        className={`${
                          CAMPOS_OBLIGATORIOS.includes("file_curp")
                            ? "obligatorio"
                            : ""
                        }`}
                        sx={{ display: { xl: "none", xs: "none" } }}
                        onChange={handleFileChange}
                      />
                      <Button
                        variant="contained"
                        component="span"
                        className="formatoBotonArchivos"
                        startIcon={<ArrowUpward />}
                      >
                        {`${
                          EMPLEADO.file_curp.file_name === ""
                            ? "Cargar Archivo"
                            : EMPLEADO.file_curp.file_name.replace("file_", "")
                        }`}
                      </Button>
                    </label>
                    {buttonFile.file_curp ? (
                      <IconButton
                        size="medium"
                        className="estiloIconArchivo"
                        disabled={terminado}
                        name="curp_button"
                        onClick={() => openImage("file_curp")}
                      >
                        <Plagiarism className="estiloIconoArchivoDos" />
                      </IconButton>
                    ) : (
                      ""
                    )}
                    <div className="invalid-feedback">
                      {mensajeError.file_curp}
                    </div>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="2">
                  <InputGroup>
                    <InputGroup.Text id="rfc" className="estiloInputs">
                      RFC{CAMPOS_OBLIGATORIOS.includes("rfc") ? "*" : ""}:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      disabled={terminado}
                      aria-describedby="rfc"
                      name="rfc"
                      className={`estiloInputsText ${
                        CAMPOS_OBLIGATORIOS.includes("rfc") ? "obligatorio" : ""
                      } ${classInvalid.rfc ? classInvalid.rfc : ""}`}
                      value={EMPLEADO.rfc}
                      onChange={handleChangeInfo}
                      maxLength={13}
                    />
                    <div className="invalid-feedback">{mensajeError.rfc}</div>
                    {EMPLEADO.status !== "0" && EMPLEADO.status !== "6" ? (
                      <Col md={{ span: 1 }} align="center" hidden={false}>
                        <Circle
                          className={`fontCirculo ${circle_color(
                            EMPLEADO.rfc_status
                          )}`}
                          //hidden={userType === '2'}
                          // disabled={terminado}
                          onClick={() => {
                            handleChangeInfo({
                              target: {
                                name: "rfc_status",
                                value: handleStatus(EMPLEADO.rfc_status),
                                className: "",
                              },
                            });
                          }}
                        ></Circle>
                      </Col>
                    ) : (
                      ""
                    )}
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="2">
                  <InputGroup className="centrarBotones">
                    <label
                      htmlFor="file_rfc"
                      className={`${classInvalid.file_rfc}`}
                    >
                      <Input
                        // accept="image/*,.pdf"
                        id="file_rfc"
                        type="file"
                        className={`${
                          CAMPOS_OBLIGATORIOS.includes("file_rfc")
                            ? "obligatorio"
                            : ""
                        }`}
                        sx={{ display: { xl: "none", xs: "none" } }}
                        onChange={handleFileChange}
                      />
                      <Button
                        variant="contained"
                        component="span"
                        className="formatoBotonArchivos"
                        startIcon={<ArrowUpward />}
                      >
                        {`${
                          EMPLEADO.file_rfc.file_name === ""
                            ? "Cargar Archivo"
                            : EMPLEADO.file_rfc.file_name.replace("file_", "")
                        }`}
                      </Button>
                    </label>
                    {buttonFile.file_rfc ? (
                      <IconButton
                        size="medium"
                        className="estiloIconArchivo"
                        name="rfc_button"
                        onClick={() => openImage("file_rfc")}
                      >
                        <Plagiarism className="estiloIconoArchivoDos" />
                      </IconButton>
                    ) : (
                      ""
                    )}
                    <div className="invalid-feedback">
                      {mensajeError.file_rfc}
                    </div>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className="mb-3 ms-2 me-2 mt-4">
                <Form.Group as={Col} md="12">
                  <InputGroup>
                    <InputGroup.Text id="dir_fisc" className="estiloInputs">
                      Dirección Fiscal
                      {CAMPOS_OBLIGATORIOS.includes("dir_fisc")
                        ? "*"
                        : ""}:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      aria-describedby="dir_fisc"
                      name="dir_fisc"
                      disabled={estatusValidar}
                      className={`estiloInputsText ${
                        CAMPOS_OBLIGATORIOS.includes("dir_fisc")
                          ? "obligatorio"
                          : ""
                      } ${classInvalid.dir_fisc ? classInvalid.dir_fisc : ""}`}
                      value={EMPLEADO.dir_fisc}
                      onChange={handleChangeInfo}
                    />
                    <div className="invalid-feedback">
                      {mensajeError.dir_fisc}
                    </div>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className="mb-3 ms-2 me-2 mt-4">
                <Form.Group as={Col} md="12">
                  <InputGroup>
                    <InputGroup.Text id="dir" className="estiloInputs">
                      Dirección{CAMPOS_OBLIGATORIOS.includes("dir") ? "*" : ""}:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      aria-describedby="dir"
                      name="dir"
                      disabled={estatusValidar}
                      className={`estiloInputsText ${
                        CAMPOS_OBLIGATORIOS.includes("dir") ? "obligatorio" : ""
                      } ${classInvalid.dir ? classInvalid.dir : ""}`}
                      value={EMPLEADO.dir}
                      onChange={handleChangeInfo}
                    />
                    <div className="invalid-feedback">{mensajeError.dir}</div>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className="mb-3 ms-2 me-2 mt-4">
                <Form.Group
                  as={Col}
                  md="3"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <InputGroup>
                    <InputGroup.Text id="correo" className="estiloInputs">
                      Correo{CAMPOS_OBLIGATORIOS.includes("correo") ? "*" : ""}:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="email"
                      aria-describedby="correo"
                      name="correo"
                      className={`estiloInputsText ${
                        CAMPOS_OBLIGATORIOS.includes("correo")
                          ? "obligatorio"
                          : ""
                      } ${classInvalid.correo ? classInvalid.correo : ""}`}
                      value={EMPLEADO.correo}
                      onChange={handleChangeInfo}
                      disabled={
                        EMPLEADO.es_valido_email === 1 &&
                        EMPLEADO.status !== "5"
                      }
                    />
                    <div className="invalid-feedback">
                      {mensajeError.correo}
                    </div>
                  </InputGroup>
                  {EMPLEADO.correo === "" ? (
                    ""
                  ) : EMPLEADO.token_email === null ? (
                    <Button
                      variant="contained"
                      style={{
                        width: 150,
                        backgroundColor: "#134C5C",
                        marginLeft: 80,
                      }}
                      onClick={() => {
                        return sendEmailVerify(
                          EMPLEADO.id,
                          EMPLEADO.correo,
                          EMPLEADO.nombre + " " + EMPLEADO.a_paterno
                        )
                          .then(async(e) => {
                            await handleEditarEmpleado(e, false, {
                              ...EMPLEADO,
                              correo_status: 2
                            });
                            sendEmailVerifyToast();
                          })
                          .catch((err) => {
                            console.log(err)
                            errorSendEmailVerifyToast();
                          });
                      }}
                    >
                      Verificar
                    </Button>
                  ) : EMPLEADO.es_valido_email === 0 ? (
                    <Button
                      variant="contained"
                      style={{
                        width: 150,
                        backgroundColor: "#134C5C",
                        marginLeft: 80,
                      }}
                      onClick={() => {
                        sendEmailVerify(
                          EMPLEADO.id,
                          EMPLEADO.correo,
                          EMPLEADO.nombre + " " + EMPLEADO.a_paterno
                        )
                          .then(async(e) => {
                            await handleEditarEmpleado(e, false,{
                              ...EMPLEADO,
                              correo_status: 2
                            });
                            sendEmailVerifyToast();
                          })
                          .catch((err) => {
                            console.log(err)
                            errorSendEmailVerifyToast();
                          });
                      }}
                    >
                      Reenviar
                    </Button>
                  ) : (
                    <Alert severity="success">Correo verificado</Alert>
                  )}
                </Form.Group>
                {EMPLEADO.status !== "0" && EMPLEADO.status !== "6" ? (
                  <Col md={{ span: 1 }} align="center" hidden={false}>
                    <Circle
                      className={`fontCirculo ${circle_color(
                        EMPLEADO.correo_status
                      )}`}
                      //hidden={userType === '2'}
                      onClick={() => {
                        handleChangeInfo({
                          target: {
                            name: "correo_status",
                            value: EMPLEADO.correo_status === 2 ? 0 : 2,
                            className: "",
                          },
                        });
                        // handleChangeInfo({
                        //   target: {
                        //     name: "correo_status",
                        //     value: handleStatus(EMPLEADO.correo_status),
                        //     className: "",
                        //   },
                        // });
                      }}
                    ></Circle>
                  </Col>
                ) : (
                  ""
                )}
                <Form.Group
                  as={Col}
                  md="3"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <InputGroup>
                    <InputGroup.Text id="tel" className="estiloInputs">
                      Teléfono{CAMPOS_OBLIGATORIOS.includes("tel") ? "*" : ""}:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="number"
                      aria-describedby="tel"
                      name="tel"
                      className={`estiloInputsText ${
                        CAMPOS_OBLIGATORIOS.includes("tel") ? "obligatorio" : ""
                      } ${classInvalid.tel ? classInvalid.tel : ""}`}
                      value={EMPLEADO.tel}
                      onChange={handleChangeInfo}
                      maxLength={10}
                      onInput={maxLengthCheck}
                      disabled={
                        EMPLEADO.es_valido_telefono === 1 &&
                        EMPLEADO.status !== "5"
                      }
                    />
                    <div className="invalid-feedback">{mensajeError.tel}</div>
                  </InputGroup>
                  {EMPLEADO.tel === "" ? (
                    ""
                  ) : EMPLEADO.token_telefono === null ? (
                    <Button
                      variant="contained"
                      style={{
                        width: 150,
                        backgroundColor: "#134C5C",
                        marginLeft: 100,
                      }}
                      onClick={() => {
                        console.log("Empleado: ", EMPLEADO.nombre)
                        sendSMSVerify({
                          empresa: companies[0].alias || "",
                          idEmpleado: EMPLEADO.id,
                          telefono: EMPLEADO.tel,
                          nombre: EMPLEADO.nombre + " " + EMPLEADO.a_paterno,
                        })
                          .then(async (e) => {
                            await handleEditarEmpleado(e, false, {
                              ...EMPLEADO,
                              telefono_status: 2
                            });
                            sendSMSVerifyToast();
                          })
                          .catch((err) => {
                            console.log(err)
                            errorSendSMSVerifyToast();
                          })
                      }}
                    >
                      Verificar
                    </Button>
                  ) : EMPLEADO.es_valido_telefono === 0 ? (
                    <Button
                      variant="contained"
                      style={{
                        width: 150,
                        backgroundColor: "#134C5C",
                        marginLeft: 95,
                      }}
                      onClick={() =>{
                        console.log("Empleado: ", EMPLEADO.nombre)
                        sendSMSVerify({
                          empresa: companies[0].alias || "",
                          idEmpleado: EMPLEADO.id,
                          telefono: EMPLEADO.tel,
                          nombre: EMPLEADO.nombre + " " + EMPLEADO.a_paterno,
                        })
                          .then(async(e) => {
                            await handleEditarEmpleado(e, false, {
                              ...EMPLEADO,
                              telefono_status: 2
                            });
                            sendSMSVerifyToast();
                          })
                          .catch((err) => {
                            console.log(err)
                            errorSendSMSVerifyToast();
                          })
                      }
                      }
                    >
                      Reenviar
                    </Button>
                  ) : (
                    <Alert severity="success">Telefono validado</Alert>
                  )}
                </Form.Group>
                {EMPLEADO.status !== "0" && EMPLEADO.status !== "6" ? (
                  <Col md={{ span: 1 }} align="center" hidden={false}>
                    <Circle
                      // disabled={terminado}
                      className={`fontCirculo ${circle_color(
                        EMPLEADO.telefono_status
                      )}`}
                      //hidden={userType === '2'}
                      onClick={() => {
                        // handleChangeInfo({
                        //   target: {
                        //     name: "telefono_status",
                        //     value: handleStatus(EMPLEADO.telefono_status),
                        //     className: "",
                        //   },
                        // });

                        handleChangeInfo({
                          target: {
                            name: "telefono_status",
                            value: EMPLEADO.telefono_status === 2 ? 0 : 2,
                            className: "",
                          },
                        });
                      }}
                    ></Circle>
                  </Col>
                ) : (
                  ""
                )}
                <Form.Group as={Col} md="4">
                  <InputGroup>
                    <InputGroup.Text id="fecha_nac" className="estiloInputs">
                      Fecha de Nacimiento
                      {CAMPOS_OBLIGATORIOS.includes("fecha_nac")
                        ? "*"
                        : ""}:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="date"
                      aria-describedby="fecha_nac"
                      name="fecha_nac"
                      disabled={terminado}
                      min="1900-01-01"
                      max={dayjs().subtract(18, "year").format("YYYY-MM-DD")}
                      className={`estiloInputsText ${
                        CAMPOS_OBLIGATORIOS.includes("fecha_nac")
                          ? "obligatorio"
                          : ""
                      } ${
                        classInvalid.fecha_nac ? classInvalid.fecha_nac : ""
                      }`}
                      value={EMPLEADO.fecha_nac}
                      onChange={handleChangeInfo}
                    />
                    <div className="invalid-feedback">
                      {mensajeError.fecha_nac}
                    </div>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className="mb-3 ms-2 me-2 mt-4">
                <Form.Group as={Col} md="3" style={{ marginTop: "1rem" }}>
                  <InputGroup>
                    <InputGroup.Text
                      id="identificacion"
                      className="estiloInputs"
                    >
                      INE/Pasaporte/Cédula
                      {CAMPOS_OBLIGATORIOS.includes("identificacion")
                        ? "*"
                        : ""}
                      :{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      disabled={terminado}
                      aria-describedby="identificacion"
                      name="identificacion"
                      className={`estiloInputsText ${
                        CAMPOS_OBLIGATORIOS.includes("identificacion")
                          ? "obligatorio"
                          : ""
                      } ${
                        classInvalid.identificacion
                          ? classInvalid.identificacion
                          : ""
                      }`}
                      value={EMPLEADO.identificacion}
                      onChange={handleChangeInfo}
                      maxLength={20}
                      onInput={maxLengthCheck}
                    />
                    <div className="invalid-feedback">
                      {mensajeError.identificacion}
                    </div>
                    {EMPLEADO.status !== "0" && EMPLEADO.status !== "6" ? (
                      <Col md={{ span: 1 }} align="center" hidden={false}>
                        <Circle
                          className={`fontCirculoIdentificacion ${circle_color(
                            EMPLEADO.id_status
                          )}`}
                          //hidden={userType === '2'}
                          // disabled={terminado}
                          onClick={() => {
                            handleChangeInfo({
                              target: {
                                name: "id_status",
                                value: handleStatus(EMPLEADO.id_status),
                                className: "",
                              },
                            });
                          }}
                        ></Circle>
                      </Col>
                    ) : (
                      ""
                    )}
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="2" style={{ marginTop: "2rem" }}>
                  <InputGroup className="centrarBotones">
                    <label
                      htmlFor="file_identificacion"
                      className={`${
                        classInvalid.file_identificacion
                          ? classInvalid.file_identificacion
                          : ""
                      }`}
                    >
                      <Input
                        // accept="image/*,.pdf"
                        id="file_identificacion"
                        type="file"
                        className={`${
                          CAMPOS_OBLIGATORIOS.includes("file_identificacion")
                            ? "obligatorio"
                            : ""
                        }`}
                        sx={{ display: { xl: "none", xs: "none" } }}
                        onChange={handleFileChange}
                      />
                      <Button
                        variant="contained"
                        component="span"
                        className="formatoBotonArchivos"
                        startIcon={<ArrowUpward />}
                      >
                        {`${
                          EMPLEADO.file_identificacion.file_name === ""
                            ? "Cargar Archivo"
                            : EMPLEADO.file_identificacion.file_name.replace(
                                "file_",
                                ""
                              )
                        }`}
                      </Button>
                    </label>
                    {buttonFile.file_identificacion ? (
                      <IconButton
                        size="medium"
                        className="estiloIconArchivo"
                        name="identificacion_button"
                        onClick={() => openImage("file_identificacion")}
                      >
                        <Plagiarism className="estiloIconoArchivoDos" />
                      </IconButton>
                    ) : (
                      ""
                    )}
                    <div className="invalid-feedback">
                      {mensajeError.file_identificacion}
                    </div>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="2" style={{ marginTop: "-0.3rem" }}>
                  <InputGroup className="centrarBotones">
                    <InputGroup.Text
                      id="acta"
                      className={`estiloInputs ${
                        buttonFile.file_acta
                          ? "claseTextoActaBoton"
                          : "claseTextoActaSinBoton"
                      }`}
                    >
                      Acta de Nacimiento
                      {CAMPOS_OBLIGATORIOS.includes("file_acta") ? "*" : ""}:
                    </InputGroup.Text>
                    <label
                      htmlFor="file_acta"
                      className={`${classInvalid.file_acta}`}
                    >
                      <Input
                        // accept="image/*,.pdf"
                        id="file_acta"
                        type="file"
                        className={`${
                          CAMPOS_OBLIGATORIOS.includes("file_acta")
                            ? "obligatorio"
                            : ""
                        }`}
                        sx={{ display: { xl: "none", xs: "none" } }}
                        onChange={handleFileChange}
                      />
                      <Button
                        variant="contained"
                        component="span"
                        className="formatoBotonArchivos"
                        startIcon={<ArrowUpward />}
                      >
                        {`${
                          EMPLEADO.file_acta.file_name === ""
                            ? "Cargar Archivo"
                            : EMPLEADO.file_acta.file_name.replace("file_", "")
                        }`}
                      </Button>
                    </label>
                    {buttonFile.file_acta ? (
                      <IconButton
                        size="medium"
                        className="estiloIconArchivo"
                        name="acta_button"
                        onClick={() => openImage("file_acta")}
                      >
                        <Plagiarism className="estiloIconoArchivoDos" />
                      </IconButton>
                    ) : (
                      ""
                    )}
                    <div className="invalid-feedback">
                      {mensajeError.file_acta}
                    </div>
                    {EMPLEADO.status !== "0" && EMPLEADO.status !== "6" ? (
                      <Col md={{ span: 1 }} align="center" hidden={false}>
                        <Circle
                          className={`fontCirculo ${circle_color(
                            EMPLEADO.acta_status
                          )}`}
                          //hidden={userType === '2'}
                          onClick={() => {
                            handleChangeInfo({
                              target: {
                                name: "acta_status",
                                value: handleStatus(EMPLEADO.acta_status),
                                className: "",
                              },
                            });
                          }}
                        ></Circle>
                      </Col>
                    ) : (
                      ""
                    )}
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="2" style={{ marginTop: "-0.3rem" }}>
                  <InputGroup className="centrarBotones">
                    <InputGroup.Text
                      id="foto_inf"
                      className={`estiloInputs ${
                        buttonFile.file_foto_inf
                          ? "claseTextoFotoInfBoton"
                          : "claseTextoFotoInfSinBoton"
                      }`}
                    >
                      Foto Infantil
                      {CAMPOS_OBLIGATORIOS.includes("file_foto_inf") ? "*" : ""}
                      :
                    </InputGroup.Text>
                    <label
                      htmlFor="file_foto_inf"
                      className={`${classInvalid.file_foto_inf}`}
                    >
                      <Input
                        // accept="image/*,.pdf"
                        id="file_foto_inf"
                        type="file"
                        className={`${
                          CAMPOS_OBLIGATORIOS.includes("file_foto_inf")
                            ? "obligatorio"
                            : ""
                        }`}
                        sx={{ display: { xl: "none", xs: "none" } }}
                        onChange={handleFileChange}
                      />
                      <Button
                        variant="contained"
                        component="span"
                        className="formatoBotonArchivos"
                        startIcon={<ArrowUpward />}
                      >
                        {`${
                          EMPLEADO.file_foto_inf.file_name === ""
                            ? "Cargar Archivo"
                            : EMPLEADO.file_foto_inf.file_name.replace(
                                "file_",
                                ""
                              )
                        }`}
                      </Button>
                    </label>
                    {buttonFile.file_foto_inf ? (
                      <IconButton
                        size="medium"
                        className="estiloIconArchivo"
                        name="foto_inf_button"
                        onClick={() => openImage("file_foto_inf")}
                      >
                        <Plagiarism className="estiloIconoArchivoDos" />
                      </IconButton>
                    ) : (
                      ""
                    )}
                    <div className="invalid-feedback">
                      {mensajeError.file_foto_inf}
                    </div>
                    {EMPLEADO.status !== "0" && EMPLEADO.status !== "6" ? (
                      <Col md={{ span: 1 }} align="center" hidden={false}>
                        <Circle
                          className={`fontCirculo ${circle_color(
                            EMPLEADO.foto_inf_status
                          )}`}
                          //hidden={userType === '2'}
                          onClick={() => {
                            handleChangeInfo({
                              target: {
                                name: "foto_inf_status",
                                value: handleStatus(EMPLEADO.foto_inf_status),
                                className: "",
                              },
                            });
                          }}
                        ></Circle>
                      </Col>
                    ) : (
                      ""
                    )}
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="2" style={{ marginTop: "-0.3rem" }}>
                  <InputGroup className="centrarBotones">
                    <InputGroup.Text
                      id="foto_fir"
                      className={`estiloInputs ${
                        buttonFile.file_foto_inf
                          ? "claseTextoFotoFirBoton"
                          : "claseTextoFotoFirSinBoton"
                      }`}
                    >
                      Foto Firma
                      {CAMPOS_OBLIGATORIOS.includes("file_foto_fir") ? "*" : ""}
                      :
                    </InputGroup.Text>
                    <label
                      htmlFor="file_foto_fir"
                      className={`${classInvalid.file_foto_fir}`}
                    >
                      <Input
                        // accept="image/*,.pdf"
                        id="file_foto_fir"
                        type="file"
                        className={`${
                          CAMPOS_OBLIGATORIOS.includes("file_foto_fir")
                            ? "obligatorio"
                            : ""
                        }`}
                        sx={{ display: { xl: "none", xs: "none" } }}
                        onChange={handleFileChange}
                      />
                      <Button
                        variant="contained"
                        component="span"
                        className="formatoBotonArchivos"
                        startIcon={<ArrowUpward />}
                      >
                        {`${
                          EMPLEADO.file_foto_fir.file_name === ""
                            ? "Cargar Archivo"
                            : EMPLEADO.file_foto_fir.file_name.replace(
                                "file_",
                                ""
                              )
                        }`}
                      </Button>
                    </label>
                    {buttonFile.file_foto_fir ? (
                      <IconButton
                        size="medium"
                        className="estiloIconArchivo"
                        name="foto_fir_button"
                        onClick={() => openImage("file_foto_fir")}
                      >
                        <Plagiarism className="estiloIconoArchivoDos" />
                      </IconButton>
                    ) : (
                      ""
                    )}
                    <div className="invalid-feedback">
                      {mensajeError.file_foto_fir}
                    </div>
                    {EMPLEADO.status !== "0" && EMPLEADO.status !== "6" ? (
                      <Col md={{ span: 1 }} align="center" hidden={false}>
                        <Circle
                          className={`fontCirculo ${circle_color(
                            EMPLEADO.firma_status
                          )}`}
                          //hidden={userType === '2'}
                          onClick={() => {
                            handleChangeInfo({
                              target: {
                                name: "firma_status",
                                value: handleStatus(EMPLEADO.firma_status),
                                className: "",
                              },
                            });
                          }}
                        ></Circle>
                      </Col>
                    ) : (
                      ""
                    )}
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className="titulos">
                <Typography
                  className="textoTitulos"
                  variant="h6"
                  fontWeight="bold"
                >
                  Ingreso
                </Typography>
              </Row>
              <Row className="mb-4 ms-2 me-2 mt-4">
                <Form.Group as={Col} md="3">
                  <InputGroup>
                    <InputGroup.Text id="fecha_ing" className="estiloInputs">
                      Fecha de Ingreso
                      {CAMPOS_OBLIGATORIOS.includes("fecha_ing")
                        ? "*"
                        : ""}:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="date"
                      aria-describedby="fecha_ing"
                      name="fecha_ing"
                      disabled={estatusValidar}
                      className={`estiloInputsText ${
                        CAMPOS_OBLIGATORIOS.includes("fecha_ing")
                          ? "obligatorio"
                          : ""
                      } ${
                        classInvalid.fecha_ing ? classInvalid.fecha_ing : ""
                      }`}
                      value={EMPLEADO.fecha_ing}
                      onChange={handleChangeInfo}
                    />
                    <div className="invalid-feedback">
                      {mensajeError.fecha_ing}
                    </div>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="3">
                  <InputGroup>
                    <InputGroup.Text id="puesto" className="estiloInputs">
                      Puesto{CAMPOS_OBLIGATORIOS.includes("puesto") ? "*" : ""}:{" "}
                    </InputGroup.Text>
                    <Form.Select
                      name="puesto"
                      className={`estiloInputsText select ${
                        CAMPOS_OBLIGATORIOS.includes("puesto")
                          ? "obligatorio"
                          : ""
                      } ${classInvalid.puesto ? classInvalid.puesto : ""}`}
                      value={EMPLEADO.puesto}
                      disabled={estatusValidar}
                      onChange={handleChangeInfo}
                    >
                      <option value="0">Seleccione una opción</option>
                      {puestos.map((e, key) => {
                        return (
                          <option key={e.id} value={e.id_puesto}>
                            {e.puesto}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <div className="invalid-feedback">
                      {mensajeError.puesto}
                    </div>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="3">
                  <InputGroup>
                    <InputGroup.Text id="departamento" className="estiloInputs">
                      Departamento
                      {CAMPOS_OBLIGATORIOS.includes("departamento")
                        ? "*"
                        : ""}:{" "}
                    </InputGroup.Text>
                    <Form.Select
                      name="departamento"
                      className={`estiloInputsText select ${
                        CAMPOS_OBLIGATORIOS.includes("departamento")
                          ? "obligatorio"
                          : ""
                      } ${
                        classInvalid.departamento
                          ? classInvalid.departamento
                          : ""
                      }`}
                      value={EMPLEADO.departamento}
                      disabled={estatusValidar}
                      onChange={handleChangeInfo}
                    >
                      <option value="0">Seleccione una opción</option>
                      {departamentos.map((departamento, key) => {
                        return (
                          <option
                            key={departamento.id}
                            value={departamento.id_departamento}
                          >
                            {departamento.departamento}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <div className="invalid-feedback">
                      {mensajeError.departamento}
                    </div>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="3">
                  <InputGroup>
                    <InputGroup.Text id="empresa" className="estiloInputs">
                      Empresa:{" "}
                    </InputGroup.Text>
                    <Form.Select
                      name="empresa"
                      className="estiloInputsText"
                      value={EMPLEADO.empresa}
                      onChange={handleChangeInfo}
                      disabled
                    >
                      <option value="0">Seleccione una opción</option>
                      {companies.map((companie, key) => {
                        return (
                          <option key={key} value={companie.id_empresa}>
                            {companie.alias}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className="titulos">
                <Typography
                  className="textoTitulos"
                  variant="h6"
                  fontWeight="bold"
                >
                  Movimientos Laborales Internos
                </Typography>
              </Row>

              {movimientosEmpleado.length === 0 ? (
                <Row className="py-4 creme-bckgrnd px-5">
                  <Col md={{ span: 12 }} align="center">
                    <InfoField data="Este empleado no tiene movimientos registrados." />
                  </Col>
                </Row>
              ) : (
                movimientosEmpleado.map((mov, index) => {
                  return (
                    <Col key={index}>
                      {index > 0 ? (
                        <Col className="creme-bckgrnd py-3" align="center">
                          <Divider className="div-style" />
                        </Col>
                      ) : (
                        <div />
                      )}

                      <Row className="mb-3 ms-2 me-2 mt-4">
                        <Form.Group as={Col} md="6">
                          <InputGroup>
                            <InputGroup.Text
                              id="fechaMov"
                              className="estiloInputs"
                            >
                              Fecha de Movimiento:{" "}
                            </InputGroup.Text>
                            <Form.Control
                              type="date"
                              aria-describedby="fechaMov"
                              name={"fecha_mov-" + index}
                              className="estiloInputsText"
                              value={mov.fecha_mov}
                              onChange={handleChangeMovimiento}
                              disabled={
                                userType === "3" ||
                                disabledMovimientoAnt ||
                                disabledMovimientoAntCont
                              }
                            />
                          </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="6">
                          <InputGroup>
                            <InputGroup.Text
                              id="movimiento"
                              className="estiloInputs"
                            >
                              Movimiento:{" "}
                            </InputGroup.Text>
                            <Form.Select
                              name={"movimiento-" + index}
                              className="estiloInputsText"
                              onChange={handleMovimientosChange}
                              disabled={
                                userType === "3" ||
                                disabledMovimientoAnt ||
                                disabledMovimientoAntCont
                              }
                              value={mov.movimiento}
                            >
                              <option value="0">Seleccione una opción</option>
                              {movimientos.map((e, key) => {
                                return (
                                  <option key={e.id} value={e.id_movimiento}>
                                    {e.movimiento}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </InputGroup>
                        </Form.Group>
                      </Row>
                      <Row className="mb-3 ms-2 me-2 mt-4">
                        <Form.Group as={Col} md="4">
                          <InputGroup>
                            <InputGroup.Text
                              id="motivoMov"
                              className="estiloInputs"
                            >
                              Motivo del Movimiento:{" "}
                            </InputGroup.Text>
                            <Form.Select
                              name={"motivo-" + index}
                              className="estiloInputsText"
                              disabled={
                                userType === "3" ||
                                disabledMovimientoAnt ||
                                disabledMovimientoAntCont
                              }
                              value={mov.motivo}
                              onChange={handleChangeMovimiento}
                            >
                              <option value="0">Seleccione una opción</option>
                              {mov.list_motivos.map((e: any) => {
                                return (
                                  <option
                                    key={e.id}
                                    value={e.id_motivo_movimiento}
                                  >
                                    {e.motivo_movimiento}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="4">
                          <InputGroup>
                            <InputGroup.Text
                              id="fechaAnt"
                              className="estiloInputs"
                            >
                              Fecha de Fin:{" "}
                            </InputGroup.Text>
                            <Form.Control
                              type="date"
                              aria-describedby="fechaAnt"
                              name={"fecha_ant-" + index}
                              className="estiloInputsText"
                              value={mov.fecha_ant}
                              onChange={handleChangeMovimiento}
                              disabled={
                                userType === "3" ||
                                disabledMovimientoAnt ||
                                disabledMovimientoAntCont
                              }
                            />
                          </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="4">
                          <InputGroup>
                            <InputGroup.Text
                              id="diasMov"
                              className="estiloInputs"
                            >
                              Días del Último Movimiento:{" "}
                            </InputGroup.Text>
                            <Form.Control
                              type="number"
                              aria-describedby="diasMov"
                              name={"ult_mov-" + index}
                              min={0}
                              className="estiloInputsText"
                              value={mov.ult_mov}
                              onChange={handleChangeMovimiento}
                              disabled={true}
                            />
                          </InputGroup>
                        </Form.Group>
                      </Row>
                      <Row className="mb-4 ms-2 me-2 mt-4">
                        <Form.Group as={Col} md="12">
                          <InputGroup>
                            <InputGroup.Text
                              id="observaciones"
                              className="estiloInputs"
                            >
                              Notas / Observaciones:{" "}
                            </InputGroup.Text>
                            <Form.Control
                              as="textarea"
                              rows={5}
                              aria-describedby="observaciones"
                              name={"notas-" + index}
                              className="estiloObservaciones"
                              value={mov.notas}
                              onChange={handleChangeMovimiento}
                              disabled={
                                userType === "3" ||
                                disabledMovimientoAnt ||
                                disabledMovimientoAntCont
                              }
                              key={index}
                            />
                          </InputGroup>
                        </Form.Group>
                      </Row>
                    </Col>
                  );
                })
              )}
              {nuevoMovimientoBoton ? (
                <Col className="borderTopMovimiento">
                  <Row className="mb-3 ms-2 me-2 mt-4">
                    <Form.Group as={Col} md="6">
                      <InputGroup>
                        <InputGroup.Text id="fechaMov" className="estiloInputs">
                          Fecha de Movimiento:{" "}
                        </InputGroup.Text>
                        <Form.Control
                          type="date"
                          aria-describedby="fechaMov"
                          name="fecha_mov_nuevo"
                          className="estiloInputsText"
                          value={nuevoMovimiento.fecha_mov_nuevo}
                          onChange={handleChangeMovimientoNuevo}
                          disabled={userType === "3"}
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                      <InputGroup>
                        <InputGroup.Text
                          id="movimiento"
                          className="estiloInputs"
                        >
                          Movimiento:{" "}
                        </InputGroup.Text>
                        <Form.Select
                          name="movimiento_nuevo"
                          className="estiloInputsText"
                          onChange={handleMovimientosNuevoChange}
                          disabled={userType === "3"}
                          value={nuevoMovimiento.movimiento_nuevo}
                        >
                          <option value="0">Seleccione una opción</option>
                          {movimientos.map((e, key) => {
                            return (
                              <option key={e.id} value={e.id_movimiento}>
                                {e.movimiento}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </InputGroup>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3 ms-2 me-2 mt-4">
                    <Form.Group as={Col} md="4">
                      <InputGroup>
                        <InputGroup.Text
                          id="motivoMov"
                          className="estiloInputs"
                        >
                          Motivo del Movimiento:{" "}
                        </InputGroup.Text>
                        <Form.Select
                          name="motivo_nuevo"
                          className="estiloInputsText"
                          disabled={disabledMotivo || userType === "3"}
                          value={selectedMotivo}
                          onChange={handleMotivoMovimientoNuevoChange}
                        >
                          <option value="0">Seleccione una opción</option>
                          {movimientosEmpleadoNuevo.map((e, key) => {
                            return (
                              <option key={e.id} value={e.id_motivo_movimiento}>
                                {e.motivo_movimiento}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} md="4">
                      <InputGroup>
                        <InputGroup.Text id="fechaAnt" className="estiloInputs">
                          Fecha de Fin:{" "}
                        </InputGroup.Text>
                        <Form.Control
                          type="date"
                          aria-describedby="fechaAnt"
                          name="fecha_ant_nuevo"
                          className="estiloInputsText"
                          value={nuevoMovimiento.fecha_ant_nuevo}
                          onChange={handleChangeMovimientoNuevo}
                          disabled={userType === "3"}
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} md="4">
                      <InputGroup>
                        <InputGroup.Text id="diasMov" className="estiloInputs">
                          Días del Último Movimiento:{" "}
                        </InputGroup.Text>
                        <Form.Control
                          type="number"
                          aria-describedby="diasMov"
                          name="ult_mov_nuevo"
                          min={0}
                          className="estiloInputsText"
                          value={nuevoMovimiento.ult_mov_nuevo}
                          onChange={handleChangeMovimientoNuevo}
                          disabled={true}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Row>
                  <Row className="mb-4 ms-2 me-2 mt-4">
                    <Form.Group as={Col} md="12">
                      <InputGroup>
                        <InputGroup.Text
                          id="observaciones"
                          className="estiloInputs"
                        >
                          Notas / Observaciones:{" "}
                        </InputGroup.Text>
                        <Form.Control
                          as="textarea"
                          rows={5}
                          aria-describedby="observaciones"
                          name="notas_nuevo"
                          className="estiloObservaciones"
                          value={nuevoMovimiento.notas_nuevo}
                          onChange={handleChangeMovimientoNuevo}
                          disabled={userType === "3"}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Row>
                </Col>
              ) : (
                ""
              )}
              <Row className="mb-4 ms-2 me-2 mt-4">
                <div className="col-md-10"></div>
                <div className="col-md-2 marginBotonEnvir">
                  <Button
                    variant="contained"
                    component="span"
                    className={`formatoBotonMov ${
                      disabledMovimientoAnt ? "formatoBotonMovDisabled" : ""
                    }`}
                    onClick={agregarMovimiento}
                  >
                    Añadir
                  </Button>
                </div>
              </Row>
              <Row className="titulos py-1">
                <Col md="auto">
                  <Typography
                    className="textoTitulos pt-1"
                    variant="h6"
                    fontWeight="bold"
                  >
                    Nómina
                  </Typography>
                </Col>
                <Col>
                  {EMPLEADO.status !== "0" && EMPLEADO.status !== "6" ? (
                    <Col md={{ span: 1 }} align="center" hidden={false}>
                      <Circle
                        className={`fontCirculo ${circle_color(
                          EMPLEADO.cuenta_status
                        )}`}
                        //hidden={userType === '2'}
                        onClick={() => {
                          handleChangeInfo({
                            target: {
                              name: "cuenta_status",
                              value: handleStatus(EMPLEADO.cuenta_status),
                              className: "",
                            },
                          });
                        }}
                      ></Circle>
                    </Col>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row className="mb-4 ms-2 me-2 mt-4">
                <Form.Group as={Col} md="4">
                  <InputGroup>
                    <InputGroup.Text
                      id="cuenta_nomina"
                      className="estiloInputs"
                    >
                      Cuenta de Banco Interna de Nómina:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="number"
                      aria-describedby="cuenta_nomina"
                      name="cuenta_nomina"
                      className={`estiloInputsText ${
                        classInvalid.cuenta_nomina
                          ? classInvalid.cuenta_nomina
                          : ""
                      }`}
                      value={EMPLEADO.cuenta_nomina}
                      onChange={handleChangeInfo}
                      maxLength={10}
                      onInput={maxLengthCheck}
                    />
                    <div className="invalid-feedback">
                      {mensajeError.cuenta_nomina}
                    </div>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <InputGroup>
                    <InputGroup.Text
                      id="numero_tarjeta"
                      className="estiloInputs"
                    >
                      No. de Tarjeta:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="number"
                      aria-describedby="numero_tarjeta"
                      name="numero_tarjeta"
                      className={`estiloInputsText ${
                        classInvalid.numero_tarjeta
                          ? classInvalid.numero_tarjeta
                          : ""
                      }`}
                      value={EMPLEADO.numero_tarjeta}
                      onChange={handleChangeInfo}
                      maxLength={16}
                      onInput={maxLengthCheck}
                    />
                    <div className="invalid-feedback">
                      {mensajeError.numero_tarjeta}
                    </div>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <InputGroup>
                    <InputGroup.Text
                      id="clabe_interbancaria"
                      className="estiloInputs"
                    >
                      Clabe Interbancaria:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="number"
                      aria-describedby="clabe_interbancaria"
                      name="clabe_interbancaria"
                      className={`estiloInputsText ${
                        classInvalid.clabe_interbancaria
                          ? classInvalid.clabe_interbancaria
                          : ""
                      }`}
                      value={EMPLEADO.clabe_interbancaria}
                      onChange={handleChangeInfo}
                      maxLength={18}
                      onInput={maxLengthCheck}
                    />
                    <div className="invalid-feedback">
                      {mensajeError.clabe_interbancaria}
                    </div>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className="mb-4 ms-2 me-2 mt-4">
                <Form.Group as={Col} md="6">
                  <InputGroup>
                    <InputGroup.Text id="banco" className="estiloInputs">
                      Banco:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      aria-describedby="banco"
                      name="banco"
                      className={`estiloInputsText ${
                        classInvalid.banco ? classInvalid.banco : ""
                      }`}
                      value={EMPLEADO.banco}
                      onChange={handleChangeInfo}
                    />
                    <div className="invalid-feedback">{mensajeError.banco}</div>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <InputGroup>
                    <InputGroup.Text id="beneficiario" className="estiloInputs">
                      Beneficiario:{" "}
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      disabled={terminado}
                      aria-describedby="beneficiario"
                      name="beneficiario"
                      className={`estiloInputsText ${
                        classInvalid.beneficiario
                          ? classInvalid.beneficiario
                          : ""
                      }`}
                      value={EMPLEADO.beneficiario}
                      readOnly
                      onChange={handleChangeInfo}
                    />
                    <div className="invalid-feedback">
                      {mensajeError.beneficiario}
                    </div>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row>
                <Col md="6" className="p-0">
                  <Button
                    className="btn estiloBotonCancelar"
                    onClick={history.goBack}
                  >
                    Cancelar
                  </Button>
                </Col>
                <Col md="6" className="p-0">
                  <Button type="submit" className="btn estiloBotonGuardar">
                    Guardar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Captura;
