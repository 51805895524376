import React, { useState } from "react";
import NavBar from "../navbar/NavBar";
import Subnavbar from "../subnavbar/Subnavbar";
import { Row, Col } from "react-bootstrap";
import { Button, Typography, IconButton } from "@mui/material";
import { useLocation } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";

import axios from "axios";
import { API_PORTAL_URL } from "../../constants";

import "./Info.css";
import backArrow from "../img/back_arrow.png";
import ppDefault from "../img/pp_default.png";
import InfoField from "./InfoField";
import ProgressCircle from "./ProgressCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Circle } from "@mui/icons-material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import dateFormat from "dateformat";
import { useHistory } from "react-router-dom";

interface IMovimientoInfo {
  id: number;
  fecha_mov: string;
  movimiento: string;
  motivo: string;
  fecha_ant: string;
  ult_mov: number;
  notas: string;
  duracion: number;
}

export const status_names = [
  "identificacion_status",
  "imss_status",
  "curp_status",
  "rfc_status",
  "acta_status",
  "foto_status",
  "firma_status",
  "correo_status",
  "telefono_status",
  "cuenta_status",
];

const Info = () => {
  const location: any = useLocation();
  const history = useHistory();

  const id_empleado = location.search.replace("?/", "");

  const [EMPLEADO, setEmpleado] = useState({
    nombre: "",
    a_paterno: "",
    a_materno: "",
    imss: "",
    curp: "",
    rfc: "",
    dir_fisc: "",
    dir: "",
    correo: "",
    tel: "",
    fecha_nac: "",
    id_ruta: "",
    imss_ruta: "",
    curp_ruta: "",
    rfc_ruta: "",
    acta_ruta: "",
    foto_inf_ruta: "",
    firma_ruta: "",
    id_status: 0,
    imss_status: 0,
    curp_status: 0,
    rfc_status: 0,
    acta_status: 0,
    foto_inf_status: 0,
    firma_status: 0,
    porcentaje: 0,
    fecha_ing: "",
    puesto: "",
    departamento: "",
    empresa: "",
    greens: 0,
    yellows: 0,
    reds: 0,
    correo_status: 0,
    tel_status: 0,
    cuenta_status: 0,
    cuenta_bancaria: "",
    numero_tarjeta: "",
    clabe_interbancaria: "",
    banco: "",
    beneficiario: "",
  });

  /* ---------------------------------------Hooks y Funciones para imagenes y pdf------------------------------------------------ */
  const [openIMGModal, setOpenIMGModal] = useState<boolean>(false);
  const [urlsImagen, setUrlsImagen] = useState<string>("");
  const [urlsPDF, setUrlsPDF] = useState<string>("");
  const [nombreImagenMostrada, setNombreImagenMostrada] = useState<string>("");
  const [esPDF, setEsPDF] = useState<boolean>(false);
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  interface IArchivosGuardados {
    [key: string]: string;
  }
  const [archivosGuardados, setArchivosGuardados] = useState<any>({
    file_imss: "",
    file_curp: "",
    file_rfc: "",
    file_identificacion: "",
    file_acta: "",
    file_foto_inf: "",
    file_foto_fir: "",
  });
  const [imagenEmpleado, setImagenEmpleado] = useState(ppDefault);
  const [banderaTipoImg, setBanderaTipoImg] = useState(false);
  /* -------------------Hooks para el funcionamiento de los botones de ver archivo------------------ */
  const [buttonFile, setButtonFile] = useState({
    file_imss: false,
    file_curp: false,
    file_rfc: false,
    file_identificacion: false,
    file_acta: false,
    file_foto_inf: false,
    file_foto_fir: false,
  });
  const [movimientos, setMovimientos] = useState<IMovimientoInfo[]>([]);

  React.useEffect(() => {
    axios
      .post(API_PORTAL_URL + "info_empleado", {
        id_empleado,
      })
      .then((response) => {
        const info = response.data[0];

        const fecha_nac =
          info.fecha_nacimiento !== null &&
          info.fecha_nacimiento !== "2000-01-01T06:00:00.000Z"
            ? dateFormat(new Date(info.fecha_nacimiento), "dd/mm/yyyy")
            : "";
        const fecha_ing =
          info.fecha_ingreso !== null &&
          info.fecha_ingreso !== "2000-01-01T06:00:00.000Z"
            ? dateFormat(new Date(info.fecha_ingreso), "dd/mm/yyyy")
            : "";

        let porcentaje = 0;

        let greens = 0;
        let yellows = 0;
        let reds = 0;

        for (let i = 0; i < status_names.length; i++) {
          switch (info[status_names[i]]) {
            case "1":
              greens++;
              break;
            case "2":
              yellows++;
              break;
            case "3":
              reds++;
              break;
            default:
          }
        }

        if (info.status === "1") {
          porcentaje = ((greens + yellows + reds) * 100) / status_names.length;
        } else {
          porcentaje = parseInt(info.avance);
        }
        const nombreArchivoIMSS =
          info.ruta_numero_imss !== ""
            ? info.ruta_numero_imss.split("/")[4]
            : "";
        const nombreArchivoCURP =
          info.ruta_curp !== "" ? info.ruta_curp.split("/")[4] : "";
        const nombreArchivoRFC =
          info.ruta_rfc !== "" ? info.ruta_rfc.split("/")[4] : "";
        const nombreArchivoID =
          info.ruta_identificacion !== ""
            ? info.ruta_identificacion.split("/")[4]
            : "";
        const nombreArchivoActaNac =
          info.acta_nacimiento !== "" ? info.acta_nacimiento.split("/")[4] : "";
        const nombreArchivoFir =
          info.foto_firma !== "" ? info.foto_firma.split("/")[4] : "";
        const nombreArchivoFoto =
          info.foto_infantil !== "" ? info.foto_infantil.split("/")[4] : "";
        const emp_info = {
          nombre: info.nombre,
          a_paterno: info.apellido_paterno,
          a_materno: info.apellido_materno,
          imss: info.numero_imss,
          curp: info.curp,
          rfc: info.rfc,
          dir_fisc: info.direccion_fiscal,
          dir: info.direccion,
          correo: info.correo_electronico,
          tel: info.telefono,
          fecha_nac,
          id_ruta: nombreArchivoID,
          imss_ruta: nombreArchivoIMSS,
          curp_ruta: nombreArchivoCURP,
          rfc_ruta: nombreArchivoRFC,
          acta_ruta: nombreArchivoActaNac,
          foto_inf_ruta: nombreArchivoFoto,
          firma_ruta: nombreArchivoFir,
          id_status: parseInt(info.identificacion_status),
          imss_status: parseInt(info.imss_status),
          curp_status: parseInt(info.curp_status),
          rfc_status: parseInt(info.rfc_status),
          acta_status: parseInt(info.acta_status),
          foto_inf_status: parseInt(info.foto_status),
          firma_status: parseInt(info.firma_status),
          porcentaje,
          fecha_ing,
          puesto: info.puesto,
          departamento: info.departamento,
          empresa: info.alias,
          cuenta_bancaria: info.cuenta_bancaria,
          clabe_interbancaria: info.clabe_interbancaria,
          numero_tarjeta: info.numero_tarjeta,
          banco: info.banco,
          beneficiario: info.beneficiario_cuenta,
          cuenta_status: parseInt(info.cuenta_status),
          correo_status: parseInt(info.correo_status),
          tel_status: parseInt(info.telefono_status),
          greens,
          yellows,
          reds,
        };

        /* Se obtienen los archivos que estén registrados en la base de datos */
        /* ------------------Primeramente se obtiene el archivo del Imss-------------------- */
        if (info.ruta_numero_imss !== "") {
          obtenerArchivoGuardado(info.ruta_numero_imss, "file_imss");
          setButtonFile((buttonFile) => ({
            ...buttonFile,
            file_imss: true,
          }));
        }
        /* ------------------Se obiene el archivo de la CURP-------------------------- */
        if (info.ruta_curp !== "") {
          obtenerArchivoGuardado(info.ruta_curp, "file_curp");
          setButtonFile((buttonFile) => ({
            ...buttonFile,
            file_curp: true,
          }));
        }
        /* ------------------Se obiene el archivo del RFC-------------------------- */
        if (info.ruta_rfc !== "") {
          obtenerArchivoGuardado(info.ruta_rfc, "file_rfc");
          setButtonFile((buttonFile) => ({
            ...buttonFile,
            file_rfc: true,
          }));
        }
        /* ------------------Se obiene el archivo de la identificacion-------------------------- */
        if (info.ruta_identificacion !== "") {
          obtenerArchivoGuardado(
            info.ruta_identificacion,
            "file_identificacion"
          );
          setButtonFile((buttonFile) => ({
            ...buttonFile,
            file_identificacion: true,
          }));
        }
        /* ------------------Se obiene el archivo del Acta-------------------------- */
        if (info.acta_nacimiento !== "") {
          obtenerArchivoGuardado(info.acta_nacimiento, "file_acta");
          setButtonFile((buttonFile) => ({
            ...buttonFile,
            file_acta: true,
          }));
        }
        /* ------------------Se obiene el archivo de la foto infantil-------------------------- */
        if (info.foto_infantil !== "") {
          obtenerArchivoGuardado(info.foto_infantil, "file_foto_inf");
          setButtonFile((buttonFile) => ({
            ...buttonFile,
            file_foto_inf: true,
          }));
        }
        /* ------------------Se obiene el archivo de la foto firma-------------------------- */
        if (info.foto_firma !== "") {
          obtenerArchivoGuardado(info.foto_firma, "file_foto_fir");
          setButtonFile((buttonFile) => ({
            ...buttonFile,
            file_foto_fir: true,
          }));
        }

        setEmpleado(() => emp_info);
      })
      .catch((response) => {
        console.log(response);
      });

    axios
      .post(API_PORTAL_URL + "movimientos", {
        id_empleado,
      })
      .then((response) => {
        // console.log(response);

        const data = response.data;

        const movs: IMovimientoInfo[] = [];

        for (let i = 0; i < data.length; i++) {
          // const fecha_mov = new Date(data[i].fecha_movimiento);
          // const fecha_ant = data[i].fecha_fin ? new Date(data[i].fecha_fin) : null;

          const fecha_mov =
            data[i].fecha_movimiento !== null &&
            data[i].fecha_movimiento !== "2000-01-01T06:00:00.000Z"
              ? dateFormat(new Date(data[i].fecha_movimiento), "dd/mm/yyyy")
              : "";
          const fecha_ant =
            data[i].fecha_fin !== null &&
            data[i].fecha_fin !== "2000-01-01T06:00:00.000Z"
              ? dateFormat(new Date(data[i].fecha_fin), "dd/mm/yyyy")
              : "";

          const duracion =
            fecha_ant === ""
              ? dayDiff(fecha_mov)
              : dayDiffTwoDates(fecha_mov, fecha_ant);

          movs.push({
            id: data[i].id,
            fecha_mov,
            movimiento: data[i].movimiento,
            motivo: data[i].motivo_movimiento,
            fecha_ant,
            ult_mov: data[i].dias_ultimo_movimiento,
            notas: data[i].notas,
            duracion,
          });
        }

        setMovimientos(movs);
      })
      .catch((response) => {
        console.log(response);
      });

    // console.log('USE EFFECT');
  }, []);

  const circle_color = (num: number) => {
    if (num === 0) {
      return "cir_gray";
    } else if (num === 1) {
      return "cir_green";
    } else if (num === 2) {
      return "cir_yellow";
    } else {
      return "cir_red";
    }
  };

  function dayDiff(date: string) {
    const today = Date.now();
    const [day, month, year] = date.split("/");
    const other = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));

    const diffTime = Math.abs(today - other.getTime());
    const diffDays = diffTime / (1000 * 60 * 60 * 24);

    return diffDays;
  }

  function dayDiffTwoDates(date1: string, date2: string) {
    let [day, month, year] = date1.split("/");
    let lastDay;

    if (date2) {
      let [day2, month2, year2] = date2.split("/");
      lastDay = new Date(
        parseInt(year2),
        parseInt(month2) - 1,
        parseInt(day2)
      ).getTime();
    } else {
      lastDay = Date.now();
    }

    const other = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));

    const diffTime = Math.abs(lastDay - other.getTime());
    const diffDays = diffTime / (1000 * 60 * 60 * 24);

    return diffDays;
  }

  function MovementRow(props: any) {
    const { mov } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <>
        <TableRow
          sx={{ "& > *": { borderBottom: "unset" } }}
          className={props.index % 2 ? "tbl-rw-odd" : "tbl-rw-even"}
        >
          <TableCell align="center">{mov.movimiento}</TableCell>
          <TableCell align="center">{mov.motivo}</TableCell>
          <TableCell align="center">{mov.fecha_mov}</TableCell>
          <TableCell align="center">
            {dayDiffTwoDates(mov.fecha_mov, mov.fecha_ant)}
          </TableCell>
          <TableCell align="center">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow className={props.index % 2 ? "tbl-rw-odd" : "tbl-rw-even"}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ marginLeft: "5%", marginRight: "5%" }}>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  component="div"
                  className="mx-5 px-5 notas-title"
                  align="center"
                >
                  Notas y Observaciones
                </Typography>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  component="div"
                  className="my-4 mx-5 px-5"
                  align="center"
                >
                  {mov.notas}
                </Typography>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  /* ------------------------------Funciones para visualizar imagenes------------------------------------ */
  /* Función para mostrar imagen cargada al dar click en el botón de previsualizar archivo */
  const openImage = (id_file: string) => {
    setNumPages(0);
    setPageNumber(1);
    let nombreImagen = "";
    const arrayBuffer = base64ToArrayBuffer(archivosGuardados[id_file].data);
    const blob = new Blob([arrayBuffer], {
      type: archivosGuardados[id_file].headers["content-type"],
    });
    const url = URL.createObjectURL(blob);
    if (
      archivosGuardados[id_file].headers["content-type"] === "application/pdf"
    ) {
      setEsPDF(true);
      setUrlsPDF(url);
    } else {
      setEsPDF(false);
      setUrlsImagen(url);
    }
    switch (id_file) {
      case "file_imss":
        nombreImagen = "del IMSS";
        break;
      case "file_curp":
        nombreImagen = "de la CURP";
        break;
      case "file_rfc":
        nombreImagen = "del RFC";
        break;
      case "file_identificacion":
        nombreImagen = "de la identificación";
        break;
      case "file_acta":
        nombreImagen = "del acta de nacimiento";
        break;
      case "file_foto_inf":
        nombreImagen = "de la foto";
        break;
      case "file_foto_fir":
        nombreImagen = "de la firma";
        break;
    }
    setNombreImagenMostrada(nombreImagen);
    setOpenIMGModal(true);
  };
  /* Función para cerrar el modal de la visualización de la imagen */
  function closeIMGModal() {
    setOpenIMGModal(false);
  }

  /* Funciones para realizar el conteo de las páginas del PDF */
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () => {
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
  };

  const goToNextPage = () => {
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);
  };

  /* ----------------------Función para obtener el documento guardado en la base de datos----------------------- */
  async function obtenerArchivoGuardado(url: string, file: string) {
    const request = await axios
      .post(API_PORTAL_URL + "get/files", {
        responseType: "arraybuffer",
        ruta_archivo: url,
      })
      .then((response) => {
        setArchivosGuardados((archivosGuardados: any) => ({
          ...archivosGuardados,
          [file]: response,
        }));
        const extension = response.headers["content-type"].split("/");
        setEmpleado((EMPLEADO) => ({
          ...EMPLEADO,
          [file]: {
            file: "actual",
            file_name: file + "." + extension[1],
          },
        }));
        if (file === "file_foto_inf") {
          const arrayBuffer = base64ToArrayBuffer(response.data);
          const blob = new Blob([arrayBuffer], {
            type: response.headers["content-type"],
          });
          const url = URL.createObjectURL(blob);
          setImagenEmpleado(url);
          if (response.headers["content-type"] === "application/pdf") {
            setBanderaTipoImg(true);
          } else {
            setBanderaTipoImg(false);
          }
        }
      })
      .catch((response_1) => {
        return response_1;
      });
  }

  /* ----------------------Función para pasar un archivo de base64 a un arreglo de buffer----------------------- */
  function base64ToArrayBuffer(base64: string) {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  return (
    <div>
      <Dialog
        open={openIMGModal}
        onClose={closeIMGModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title text-center">
          {"Imagen del archivo " + nombreImagenMostrada}
        </DialogTitle>
        <DialogContent className="alert-dialog-content">
          {esPDF ? (
            <div>
              <nav>
                <button onClick={goToPrevPage}>Anterior</button>
                <button onClick={goToNextPage}>Siguiente</button>
                <p>
                  Página {pageNumber} de {numPages}
                </p>
              </nav>

              <Document file={urlsPDF} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} />
              </Document>
            </div>
          ) : (
            <DialogContentText id="alert-dialog-description">
              <img src={`${urlsImagen}`} />
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions className="alert-dialog-actions">
          <Button className="cancel-btn mx-5 px-5 py-2" onClick={closeIMGModal}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <NavBar />

      <Subnavbar image={location.state.image} />

      <Row className="content">
        <Col>
          <Row id="">
            <Col md={{ span: 3 }} id="back-btn-col">
              <Button id="back-btn" onClick={history.goBack}>
                <img src={backArrow} alt="Back Arrow" />
              </Button>
            </Col>
            <Col md={{ span: 9 }} className="section-title pt-1 px-3">
              <Typography variant="h6" fontWeight="bold">
                {" "}
                Información Básica{" "}
              </Typography>
            </Col>
          </Row>

          <Row className="pt-2 creme-bckgrnd">
            <Col md={{ span: 3 }} className="creme-bckgrnd pt-4" align="center">
              <Row className="mb-4">
                <Col>
                  {banderaTipoImg ? (
                    <div className="dimensionesPDF">
                      <Document
                        file={imagenEmpleado}
                        onLoadSuccess={onDocumentLoadSuccess}
                        className="dimensionesPDF"
                      >
                        <Page pageNumber={pageNumber} />
                      </Document>
                    </div>
                  ) : (
                    <img
                      src={imagenEmpleado}
                      alt="Profile"
                      className="dimensionesImg"
                    />
                  )}
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <ProgressCircle
                    progress={EMPLEADO.porcentaje}
                    greens={EMPLEADO.greens}
                    yellows={EMPLEADO.yellows}
                    reds={EMPLEADO.reds}
                    total={status_names.length}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={{ span: 9 }} className="creme-bckgrnd pt-4">
              <Row className="mx-5 mb-3">
                <Col md={{ span: 4 }}>
                  <InfoField label="Nombre*:" data={EMPLEADO.nombre} />
                </Col>
                <Col md={{ span: 4 }}>
                  <InfoField
                    label="Apellido Paterno*:"
                    data={EMPLEADO.a_paterno}
                  />
                </Col>
                <Col md={{ span: 4 }}>
                  <InfoField
                    label="Apellido Materno*:"
                    data={EMPLEADO.a_materno}
                  />
                </Col>
              </Row>

              <Row className="mx-5 mb-3">
                <Col md={{ span: 4 }}>
                  <InfoField label="IMSS*:" data={EMPLEADO.imss} />
                </Col>
                <Col md={{ span: 4 }}>
                  <InfoField label="CURP*:" data={EMPLEADO.curp} />
                </Col>
                <Col md={{ span: 4 }}>
                  <InfoField label="RFC*:" data={EMPLEADO.rfc} />
                </Col>
              </Row>

              <Row className="mx-5 mb-3">
                <Col md={{ span: 12 }}>
                  <InfoField
                    label="Dirección Fiscal*:"
                    data={EMPLEADO.dir_fisc}
                  />
                </Col>
              </Row>

              <Row className="mx-5 mb-3">
                <Col md={{ span: 12 }}>
                  <InfoField label="Dirección*:" data={EMPLEADO.dir} />
                </Col>
              </Row>

              <Row className="mx-5 mb-5">
                <Col md={{ span: 4 }}>
                  <InfoField
                    label="Correo*:"
                    data={EMPLEADO.correo}
                    status
                    status_num={EMPLEADO.correo_status}
                  />
                </Col>
                <Col md={{ span: 4 }}>
                  <InfoField
                    label="Teléfono*:"
                    data={EMPLEADO.tel}
                    status
                    status_num={EMPLEADO.tel_status}
                  />
                </Col>
                <Col md={{ span: 4 }}>
                  <InfoField
                    label="Fecha de Nacimiento*:"
                    data={EMPLEADO.fecha_nac}
                  />
                </Col>
              </Row>

              <Row className="mx-5 mb-2">
                <Col md={{ span: 5 }}>
                  <Row className="mb-2">
                    <InfoField
                      label="INE / Pasaporte / Cédula:"
                      data={EMPLEADO.id_ruta.replace("file_", "")}
                      status
                      status_num={EMPLEADO.id_status}
                      onClick={() => openImage("file_identificacion")}
                    />
                  </Row>
                  <Row className="mb-2">
                    <InfoField
                      label="Acta de Nacimiento:"
                      data={EMPLEADO.acta_ruta.replace("file_", "")}
                      status
                      status_num={EMPLEADO.acta_status}
                      onClick={() => openImage("file_acta")}
                    />
                  </Row>
                  <Row className="mb-2">
                    <InfoField
                      label="Foto Infantil:"
                      data={EMPLEADO.foto_inf_ruta.replace("file_", "")}
                      status
                      status_num={EMPLEADO.foto_inf_status}
                      onClick={() => openImage("file_foto_inf")}
                    />
                  </Row>
                  <Row className="mb-2">
                    <InfoField
                      label="Foto Firma:"
                      data={EMPLEADO.firma_ruta.replace("file_", "")}
                      status
                      status_num={EMPLEADO.firma_status}
                      onClick={() => openImage("file_foto_fir")}
                    />
                  </Row>
                </Col>
                <Col md={{ span: 5, offset: 1 }}>
                  <Row className="mb-2">
                    <InfoField
                      label="IMSS:"
                      data={EMPLEADO.imss_ruta.replace("file_", "")}
                      status
                      status_num={EMPLEADO.imss_status}
                      onClick={() => openImage("file_imss")}
                    />
                  </Row>
                  <Row className="mb-2">
                    <InfoField
                      label="CURP:"
                      data={EMPLEADO.curp_ruta.replace("file_", "")}
                      status
                      status_num={EMPLEADO.curp_status}
                      onClick={() => openImage("file_curp")}
                    />
                  </Row>
                  <Row className="mb-2">
                    <InfoField
                      label="RFC:"
                      data={EMPLEADO.rfc_ruta.replace("file_", "")}
                      status
                      status_num={EMPLEADO.rfc_status}
                      onClick={() => openImage("file_rfc")}
                    />
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="px-3 section-title">
            <Col md={{ span: 12 }} className="section-title pt-2 px-4 pb-3">
              <Typography variant="h6" fontWeight="bold">
                {" "}
                Ingreso{" "}
              </Typography>
            </Col>
          </Row>

          <Row className="py-4 creme-bckgrnd px-5">
            <Col md={{ span: 3 }}>
              <InfoField label="Fecha de Ingreso:" data={EMPLEADO.fecha_ing} />
            </Col>
            <Col md={{ span: 3 }}>
              <InfoField label="Puesto:" data={EMPLEADO.puesto} />
            </Col>
            <Col md={{ span: 3 }}>
              <InfoField label="Departamento:" data={EMPLEADO.departamento} />
            </Col>
            <Col md={{ span: 3 }}>
              <InfoField label="Empresa:" data={EMPLEADO.empresa} />
            </Col>
          </Row>

          <Row className="px-3 section-title">
            <Col md={{ span: 12 }} className="section-title pt-2 px-4 pb-3">
              <Typography variant="h6" fontWeight="bold">
                {" "}
                Movimientos Laborales Internos{" "}
              </Typography>
            </Col>
          </Row>

          {movimientos.length === 0 ? (
            <Row className="py-4 creme-bckgrnd px-5">
              <Col md={{ span: 12 }} align="center">
                <InfoField data="No se han realizado movimientos." />
              </Col>
            </Row>
          ) : (
            <div>
              <Row key={movimientos[0].id}>
                <Col className="creme-bckgrnd pb-3">
                  <Row className="py-4 creme-bckgrnd px-5">
                    <Col md={{ span: 4 }}>
                      <InfoField
                        label="Fecha de Movimiento:"
                        data={movimientos[0].fecha_mov}
                      />
                    </Col>
                    <Col md={{ span: 4 }}>
                      <InfoField
                        label="Movimiento:"
                        data={movimientos[0].movimiento}
                      />
                    </Col>
                    <Col md={{ span: 4 }}>
                      <InfoField
                        label="Motivo de Movimiento:"
                        data={movimientos[0].motivo}
                      />
                    </Col>
                  </Row>

                  <Row className="pt-2 creme-bckgrnd px-5">
                    {movimientos[0].fecha_ant ? (
                      <Col md={{ span: 4 }}>
                        <InfoField
                          label="Fecha de Fin del Movimiento:"
                          data={movimientos[0].fecha_ant}
                        />
                      </Col>
                    ) : (
                      <div />
                    )}

                    <Col md={{ span: 4 }}>
                      <InfoField
                        label="Duración:"
                        data={Math.trunc(movimientos[0].duracion)}
                      />
                    </Col>

                    <Col md={{ span: 4 }}>
                      <InfoField
                        label="Días de Último Movimiento:"
                        data={Math.trunc(dayDiff(movimientos[0].fecha_mov))}
                      />
                    </Col>
                  </Row>

                  <Row className="py-4 creme-bckgrnd px-5">
                    <Col md={{ span: 12 }}>
                      <InfoField
                        label="Notas / Observaciones:"
                        data={movimientos[0].notas}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              {movimientos.length > 1 ? (
                <Row>
                  <Col className="px-0">
                    <TableContainer component={Paper} className="tbl-a">
                      <Table aria-label="collapsible table">
                        <TableHead>
                          <TableRow>
                            <TableCell className="tbl-hd" align="center">
                              Movimiento
                            </TableCell>
                            <TableCell className="tbl-hd" align="center">
                              Motivo
                            </TableCell>
                            <TableCell className="tbl-hd" align="center">
                              Fecha
                            </TableCell>
                            <TableCell className="tbl-hd" align="center">
                              Duración
                            </TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {movimientos.map((mov, index) => {
                            return index === 0 ? (
                              <TableRow key={index} />
                            ) : (
                              <MovementRow
                                key={index}
                                mov={mov}
                                index={index}
                              />
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Col>
                </Row>
              ) : (
                <Row />
              )}
            </div>
          )}

          <Row className="px-3 section-title">
            <Col md="auto" className="section-title pt-3 px-4 pb-3">
              <Typography variant="h6" fontWeight="bold">
                {" "}
                Nómina{" "}
              </Typography>
            </Col>
            <Col className="section-title pt-3 px-4 pb-3">
              <Circle className={circle_color(EMPLEADO.cuenta_status)} />
            </Col>
          </Row>

          <Row>
            <Col className="creme-bckgrnd py-2">
              <Row className="py-3 creme-bckgrnd px-5">
                <Col md={{ span: 4 }}>
                  <InfoField
                    label="Cuenta de Banco Interna de Nómina:"
                    data={EMPLEADO.cuenta_bancaria}
                  />
                </Col>
                <Col md={{ span: 4 }}>
                  <InfoField
                    label="No. de Tarjeta:"
                    data={EMPLEADO.numero_tarjeta}
                  />
                </Col>
                <Col md={{ span: 4 }}>
                  <InfoField
                    label="Clabe Interbancaria:"
                    data={EMPLEADO.clabe_interbancaria}
                  />
                </Col>
              </Row>

              <Row className="py-3 creme-bckgrnd px-5">
                <Col md={{ span: 6 }}>
                  <InfoField label="Banco:" data={EMPLEADO.banco} />
                </Col>
                <Col md={{ span: 6 }}>
                  <InfoField
                    label="Beneficiario de Cuenta:"
                    data={EMPLEADO.beneficiario}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Info;
