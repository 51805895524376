/* La función despachadora (o simplemente función dispatch) es una función que acepta una acción o una acción asíncrona;
 * entonces puede o no despachar una o más acciones al store. */
import { Dispatch } from "redux";
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  RECOVER_SUCCESS,
  RECOVER_FAIL,
} from "./types";
import AuthService from "../services/auth.service";

export const login =
  (username: string, password: string) => (dispatch: Dispatch) => {
    return AuthService.login(username, password).then(
      (data) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        });
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch({
          type: LOGIN_FAIL,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        return Promise.reject();
      }
    );
  };
export const logout = () => (dispatch: Dispatch) => {
  AuthService.logout();
  dispatch({
    type: LOGOUT,
  });
};

export const recover =
  (username: string, actualPassword: string, newPassword: string) =>
  (dispatch: Dispatch) => {
    return AuthService.recover(username, actualPassword, newPassword).then(
      (data) => {
        dispatch({
          type: RECOVER_SUCCESS,
          payload: { user: data },
        });
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch({
          type: RECOVER_FAIL,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        return Promise.reject();
      }
    );
  };
