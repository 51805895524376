import colocarFechaActual from "./colocarFechaActual";
export interface IEmpleado {
  [key: string]: any;
}
export const crearEstadoInicialEmpleado = (
  empresa: string,
  idUser: string
): IEmpleado => {
  return {
    /* Campos de Información Básica */
    nombre: "",
    a_paterno: "",
    a_materno: "",
    imss: "",
    file_imss: {
      file: "",
      file_name: "",
    },
    curp: "",
    file_curp: {
      file: "",
      file_name: "",
    },
    rfc: "",
    file_rfc: {
      file: "",
      file_name: "",
    },
    dir_fisc: "",
    dir: "",
    correo: "",
    tel: "",
    fecha_nac: "",
    identificacion: "",
    file_identificacion: {
      file: "",
      file_name: "",
    },
    file_acta: {
      file: "",
      file_name: "",
    },
    file_foto_inf: {
      file: "",
      file_name: "",
    },
    file_foto_fir: {
      file: "",
      file_name: "",
    },
    /* Campos de Ingreso */
    fecha_ing: "",
    puesto: "CAND",
    departamento: "",
    empresa: empresa,
    /* Campos de Movimientos Laborales Internos */
    fecha_mov: colocarFechaActual(new Date()),
    movimiento: "MOV0",
    motivo_movimiento: "MOTMOV0",
    fecha_ant: "",
    dias_ult_mov: "0",
    observaciones: "N/A",
    /* Campos de Nómina */
    cuenta_nomina: "",
    beneficiario: "",
    clabe_interbancaria: "",
    numero_tarjeta: "",
    banco: "",
    /* Campos extra para la migración, si es que existe */
    empleado_repetido: "0",
    empleado_repetido_empresa: "0",
    autorizacion_migracion: "0",
    /* Bandera para saber si la información solo se esta guardando o también enviando */
    status_envio: "0",
    /* ID del empleado en caso de que sea migración */
    id_empleado: "",
    id_usuario_accion: idUser,
    fecha_alta: "",
  };
};
