import { useState, useEffect } from "react";
import { ICompany, ICompanyRequest } from "../components/companies/ICompany";

import api from "../services/api.service";

const useGetEmpresas = (idUser: string): [ICompany[], boolean] => {
  const [empresas, setEmpresas] = useState<ICompany[]>([]);
  const [cargando, setCargando] = useState<boolean>(true);

  const getLista = async (idUser: string) => {
    try {
      setCargando(true);
      const response = await api.post("empresas", {
        id_user: idUser,
      });
      const comps: ICompany[] = response.data.map(
        (empresa: ICompanyRequest) => ({
          id_empresa: empresa.id_empresa,
          alias: empresa.alias,
          image: empresa.imagen_ruta ? empresa.imagen_ruta : "./hixsa.svg",
        })
      );
      setEmpresas(comps);
    } catch (err) {
      throw err;
    }
    setCargando(false);
  };

  useEffect(() => {
    getLista(idUser);
  }, []);

  return [empresas, cargando];
};

export default useGetEmpresas;
