export interface IArchivoMigracion {
  [key: string]: any;
}
export const estadoInicialMigración: IArchivoMigracion = {
  file_imss: false,
  file_curp: false,
  file_rfc: false,
  file_identificacion: false,
  file_acta: false,
  file_foto_inf: false,
  file_foto_fir: false,
};
