import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./components/fonts/orbeit-font.otf";

import Login from "./components/login/Login";
import Empresas from "./components/companies/Empresas";
import Employees from "./components/employees/Employees";
import Capture from "./components/capture/Capture";
import Info from "./components/info/Info";
import Edit from "./components/edit/Edit";
import Recover from "./components/recoverpassword/Recover";
import NotFound from "./components/notfound/NotFound";
import Log from "./components/log/Log";

import { PrivateRoute } from "./components/privateroute/PrivateRoute";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path={["/", "/login"]} component={Login} />
        <PrivateRoute path="/empresas" component={Empresas} />
        <PrivateRoute path="/empleados" component={Employees} />
        <PrivateRoute path="/captura" component={Capture} />
        <PrivateRoute path="/info" component={Info} />
        <PrivateRoute path="/editar" component={Edit} />
        <PrivateRoute path="/log" component={Log} />
        <Route exact path="/recover" component={Recover} />
        <Route exact component={NotFound} />
      </Switch>
    </Router>
  );
};

export default App;
