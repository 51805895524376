import React from "react";
import { Route, Redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";
import getUserLocal from "../../utils/getUserLocal";

interface PrivateRouteProps {
  component: React.ComponentType<any>;
  path: string;
  exact?: boolean;
}

export const PrivateRoute = ({
  component: Component,
  ...rest
}: PrivateRouteProps) => {
  const user = getUserLocal();

  let result = false;
  if (user) {
    var decodedToken: any = jwt_decode(user.accessToken);
    const currentDate = new Date();
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      result = false;
      localStorage.removeItem("user");
    } else {
      result = true;
    }
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        result && decodedToken.username ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};
