import api from "../api.service";

const sendEmailVerify = (
  idEmpleado: number,
  email: string,
  nombreEmpleado: string
) => {
  return new Promise((resolve, reject) => {
    api
      .post(
        `empleado/validar/email?email=${email}&idEmpleado=${idEmpleado}&nombre_empleado=${nombreEmpleado}`
      )
      .then(() => {
        resolve("Correo de verificación enviado con éxito");
      })
      .catch((error) => {
        reject("Error al enviar el correo de verificación");
      });
  });
};

export default sendEmailVerify;
