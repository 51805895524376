import { ICompany } from "../../components/companies/ICompany";
import api from "../api.service";

const getTotalUsuarios = async (idEmpresa: string): Promise<number> => {
  try {
    const { data } = await api.post("empresas/totalEmp", {
      id_empresa: idEmpresa,
    });
    return data[0];
  } catch (error) {
    throw error;
  }
};

export default getTotalUsuarios;
