import api from "../api.service";

export interface IGetTotalAltas {
  idEmpresa: string;
  inicio: Date;
  fin: Date;
}

export interface IGetTotalAltasResponse {
  altas: number;
  validados: number;
  noValidados: number;
  cancelados: number;
  altasTotal: number;
}

const getTotalAltas = async ({
  idEmpresa,
  inicio,
  fin,
}: IGetTotalAltas): Promise<IGetTotalAltasResponse> => {
  try {
    const { data } = await api.post("empresas/altas", {
      id_empresa: idEmpresa,
      inicio,
      fin,
    });
    let result: IGetTotalAltasResponse = {
      altas: 0,
      validados: 0,
      noValidados: 0,
      cancelados: 0,
      altasTotal: 0,
    };

    result.altas = data.length;

    data.forEach((item: any) => {
      const status = parseInt(item.status);
      if (status !== 6) {
        result.altasTotal++;
      }

      if (status <= 1) {
        result.noValidados++;
      } else {
        result.validados++;
      }

      if (status === 6) {
        result.cancelados++;
      }
    });

    return result;
  } catch (error) {
    throw error;
  }
};

export default getTotalAltas;
