import api from "../api.service";
const getMotivoMovimientos = (id_movimiento: string) => {
  return api
    .post("get/motivo-movimientos", {
      id_movimiento,
    })
    .then((response) => {
      return response.data;
    });
};
export default getMotivoMovimientos;
