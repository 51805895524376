import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { Circle } from "@mui/icons-material";

import "react-toastify/dist/ReactToastify.css";
import "./LogTable.css";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "id",
    numeric: true,
    align: "center",
    disablePadding: true,
    label: "ID",
  },
  {
    id: "user",
    numeric: true,
    align: "center",
    disablePadding: false,
    label: "Usuario",
  },
  {
    id: "date",
    numeric: true,
    align: "center",
    disablePadding: false,
    label: "Fecha",
  },
  {
    id: "employee",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "Empleado",
  },
  {
    id: "data",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "Dato",
  },
  {
    id: "data_prev",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "Dato Anterior",
  },
  {
    id: "move",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "Movimiento",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead id="log-header">
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
            className="log-header_cells"
          >
            <TableSortLabel
              active
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className="log-header_labels"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function colors_class(num) {
  switch (num) {
    case "0":
      return "cir-gray";
    case "1":
      return "cir-green";
    case "2":
      return "cir-yellow";
    case "3":
      return "cir-red";
    default:
      return "cir-gray";
  }
}

const LogTable = (props) => {
  const rows = props.logs;

  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("date");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Box sx={{ width: "100%" }} className="mt-3">
      <Paper sx={{ width: "100%" }}>
        <TableContainer id="log-tbl-cont">
          <Table stickyHeader className="tbl">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {stableSort(rows, getComparator(order, orderBy)).map(
                (row, index) => {
                  const labelId = `table-${index}`;

                  return (
                    <TableRow
                      tabIndex={-1}
                      key={row.id}
                      className={
                        "row-style " + (index % 2 === 0 ? "" : "odd-row")
                      }
                    >
                      <TableCell id={labelId} align="center">
                        {row.id}
                      </TableCell>
                      <TableCell align="center">{row.user}</TableCell>
                      <TableCell align="center">
                        {row.date.toLocaleString()}
                      </TableCell>
                      <TableCell align="center">{row.employee}</TableCell>
                      <TableCell align="center">{row.data}</TableCell>
                      <TableCell align="center">
                        {row.data.substring(0, 6) === "Status" ? (
                          <Circle className={colors_class(row.data_prev)} />
                        ) : (
                          row.data_prev
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {row.data.substring(0, 6) === "Status" ? (
                          <Circle className={colors_class(row.move)} />
                        ) : (
                          row.move
                        )}
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default LogTable;
