import api from "../api.service";
import { ILog } from "../../actions/log-insert";

const setLog = (data: ILog) => {
  return api
    .post("nuevo/log", data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export default setLog;
