const validarNSS = (nss: string): [boolean, string] => {
  let mensajeError: string = "";
  let error = false;
  if (nss.length !== 11) {
    mensajeError = "La longitud del NSS debe ser igual a 11";
    error = true;
  } else {
    const re = /^(\d{2})(\d{2})(\d{2})\d{5}$/;
    const validado = nss.match(re) || [];
    const subDeleg = parseInt(validado[1], 10);
    const anno = new Date().getFullYear() % 100;
    let annoAlta = parseInt(validado[2], 10);
    let annoNac = parseInt(validado[3], 10);
    // Comparar años (excepto que no tenga año de nacimiento)
    if (subDeleg !== 97) {
      if (annoAlta <= anno) annoAlta += 100;
      if (annoNac <= anno) annoNac += 100;
      if (annoNac > annoAlta) {
        mensajeError = "Formato de NSS no válido";
        error = true;
      }
    }
    if (algoritmoLuhn(nss)) {
      mensajeError = "";
      error = false;
    } else {
      mensajeError = "Formato de NSS no válido";
      error = true;
    }
  }
  return [error, mensajeError];
};

/* El algoritmo de Luhn o fórmula de Luhn, también conocida como "algoritmo de módulo 10",
    es una fórmula de suma de verificación, utilizada para validar una diversidad de números de identificación */
function algoritmoLuhn(nss: string) {
  let suma = 0;
  let par = false;
  let digito;
  for (let i = nss.length - 1; i >= 0; i--) {
    digito = parseInt(nss.charAt(i), 10);
    if (par) {
      if ((digito *= 2) > 9) {
        digito -= 9;
      }
    }
    par = !par;
    suma += digito;
  }
  // Un NSS es válido si el último dígito de la suma da 0.
  return suma % 10 === 0;
}

export default validarNSS;
