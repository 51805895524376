// PRODUCCIÓN
const API_PORTAL_URL = "https://api-portalrh.elothe.dev/api-portal-rh/";

// DEV
// const API_PORTAL_URL = "http://localhost:8000/api-portal-rh/";

export const DATE_DEPLOY = "15-03-2023";

/* ------------------Arreglo de los formatos soportados para el guardado de archivos------------------ */
const ARCHIVOS_SOPORTADOS = [
  "png",
  "jpg",
  "jpeg",
  "pdf",
  "PNG",
  "JPG",
  "JPEG",
  "PDF",
  "tiff",
  "TIFF",
  "JFIF",
  "jfif",
];
/* ------------------------Arreglo de los datos obligatorios para el guardado------------------------ */
// Este arreglo es el que se deberá modificar en caso de que un campo se vuelva obligatorio o deje de serlo
const CAMPOS_OBLIGATORIOS_CONST = [
  "nombre",
  "a_paterno",
  "a_materno",
  "imss",
  "file_imss",
  "curp",
  "file_curp",
  "puesto",
  "departamento",
] as const;

const CAMPOS_OBLIGATORIOS = [
  "nombre",
  "a_paterno",
  "a_materno",
  "imss",
  "file_imss",
  "curp",
  "file_curp",
  "puesto",
  "departamento",
];

export {
  API_PORTAL_URL,
  ARCHIVOS_SOPORTADOS,
  CAMPOS_OBLIGATORIOS,
  CAMPOS_OBLIGATORIOS_CONST,
};
