import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { Link, useHistory } from "react-router-dom";
import { Row, Form } from "react-bootstrap";
import { Typography } from "@mui/material";
import { RemoveRedEye, VisibilityOff } from "@mui/icons-material";
import jwtDecode from "jwt-decode";
import { DATE_DEPLOY } from "../../constants";

import { login } from "../../actions/auth";

import "./Login.css";
import getUserLocal, { IUser } from "../../utils/getUserLocal";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { message } = useSelector((state: any) => state.message);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [passwordType, setPasswordType] = useState("password");
  const user: IUser = getUserLocal();

  const togglePassword: React.MouseEventHandler<
    HTMLButtonElement
  > = (): void => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    if (name === "username") {
      setUsername(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const handleLogin = async (e: React.FormEvent<HTMLFormElement> | any) => {
    if (e) e.preventDefault();
    setLoading(true);
    if (username && password) {
      dispatch(login(username, password))
        .then(() => {
          history.push("/empresas");
          window.location.reload();
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user.accessToken) {
      try {
        const decodedToken: any = jwtDecode(user?.accessToken);
        console.log(decodedToken);
        const currentDate = new Date();
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
          localStorage.removeItem("user");
          history.push("/");
        } else {
          history.push("/empresas");
        }
      } catch (err) {
        console.log(err);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className="col-md-12" style={{height: '100%'}}>
      <Row>
        <div className="col-md-7">
          <Row>
            <div className="col-md-5 barraTransversal" />
            <div className="col-md-3 barraUno" />
            <div className="col-md-3 barraDos" />
          </Row>
        </div>
        <div className="col-md-5 rectangulo rectanguloSM rectanguloMD">
          <Form className="row-login-form" onSubmit={handleLogin}>
            <Row>
              <Typography
                id="title"
                sx={{
                  typography: { xl: "h1", xs: "h3" },
                  fontSize: { lg: 75 },
                }}
                align="center"
                className="titleDos"
              >
                {" "}
                ORBEIT{" "}
              </Typography>
            </Row>
            <Form.Group className="m-0">
              <div className="col-md-12 pe-5 ps-5 mt-3">
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  placeholder="Ingrese Usuario"
                  value={username}
                  name="username"
                  onChange={handleInputChange}
                />
              </div>
            </Form.Group>
            <Form.Group className="m-0">
              <div className="col-md-12 pe-5 ps-5 mt-4">
                <input
                  type={passwordType}
                  className="form-control"
                  id="password"
                  placeholder="Ingrese Contraseña"
                  value={password}
                  name="password"
                  onChange={handleInputChange}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      handleLogin(e);
                    }
                  }}
                />
                <div className="input-group-btn estiloBotonEye">
                  <button
                    className="btn btn-outline-primary estiloEye"
                    onClick={togglePassword}
                    type="button"
                  >
                    {passwordType === "password" ? (
                      <RemoveRedEye />
                    ) : (
                      <VisibilityOff />
                    )}
                  </button>
                </div>
              </div>
            </Form.Group>
            <Form.Group className="m-0">
              <div className="col-12 ps-4 ms-4 mt-3">
                <Link to="/recover" className="botonPSW botonPSWSM botonPSWMD">
                  Cambiar contraseña
                </Link>
              </div>
            </Form.Group>
            <Row className="marginBottom">
              <div className="col-md-7">
                <p />
              </div>
              <Row className="col-md-5">
                <button
                  type="submit"
                  className="btn-lg botonLogin botonLoginMD"
                  disabled={loading}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm" />
                  )}
                  <span>Entrar</span>
                </button>
              </Row>
            </Row>
            {message && (
              <Form.Group className="me-1 ms-1">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </Form.Group>
            )}
          </Form>
        </div>
      </Row>
      <div style={{
        position: 'absolute',
        bottom: 0,
        width: '100vw',
        backgroundColor: '#134c5c',
        height: '50px',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '18px',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '20px'
      }}>
        Fecha de despliegue: {DATE_DEPLOY}
      </div>
    </div>
  );
};

export default Login;
