import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {
  VisibilityOutlined,
  EditOutlined,
  DeleteOutlined,
  Circle,
  TaskAlt,
} from "@mui/icons-material";
import PublishedWithChangesOutlinedIcon from "@mui/icons-material/PublishedWithChangesOutlined";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import axios from "axios";
import { API_PORTAL_URL } from "../../constants";
import { insertLog } from "../../actions/log-insert";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./EmployeeTable.css";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "id",
    numeric: true,
    align: "center",
    disablePadding: true,
    label: "ID",
  },
  {
    id: "status",
    numeric: true,
    align: "center",
    disablePadding: true,
    label: "Status",
  },
  {
    id: "name",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "Nombre",
  },
  {
    id: "last_name",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "Apellido(s)",
  },
  {
    id: "department",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "Departamento",
  },
  {
    id: "job",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "Puesto",
  },
  {
    id: "progress",
    numeric: true,
    align: "center",
    disablePadding: false,
    label: "Avance",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead id="header">
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
            className="header_cells"
          >
            <TableSortLabel
              active
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className="header_labels"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell
          align="center"
          padding="normal"
          className="header_labels header_cells"
        >
          Acciones
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const EmployeeTable = (props) => {
  const rows = props.empleados;

  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("id");

  const [idToDelete, setIdToDelete] = React.useState("");
  const [nameToDelete, setNameToDelete] = React.useState("");

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openActivateModal, setOpenActivateModal] = React.useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const onViewClick = async (id_empleado) => {
    try {
      await insertLog(props.id_empresa, id_empleado, "-", "-", "Consulta de Empleado");
      props.history.push({
        pathname: "/info",
        search: "/" + id_empleado,
        state: {
          companies: props.companies,
          image: props.image,
        },
      });
    } catch (error) {
      console.log("Error al agregar log de consulta de empleado");
    }

  };

  const onEditClick = (id_empleado) => {
    props.history.push({
      pathname: "/editar",
      search: "/" + id_empleado,
      state: {
        companies: props.companies,
        image: props.image,
      },
    });
  };

  const onDeleteClick = (id_empleado, name, last_name) => {
    setIdToDelete(id_empleado);
    setNameToDelete(name + " " + last_name);

    setOpenDeleteModal(true);
  };

  const onActivateClick = (id_empleado, name, last_name) => {
    setIdToDelete(id_empleado);
    setNameToDelete(name + " " + last_name);

    setOpenActivateModal(true);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const closeActivateModal = () => {
    setOpenActivateModal(false);
  };

  const deleteEmployee = async () => {
    await axios
      .post(API_PORTAL_URL + "borrar/empleado", {
        id_empleado: idToDelete,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((response) => {
        console.log(response);
      });

    // insertLog(props.id_empresa, idToDelete, '-', '-', 'Baja');

    closeDeleteModal();
    deleteToast();
    props.updateEmployees();
  };

  const activateEmployee = async () => {
    // console.log('Se activa el usuario: ' + idToDelete);

    await axios
      .post(API_PORTAL_URL + "activar/empleado", {
        id_empleado: idToDelete,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((response) => {
        console.log(response);
      });

    // insertLog(props.id_empresa, idToDelete, '-', '-', 'Reactivación');

    closeActivateModal();
    activateToast();
    props.updateEmployees();
  };

  const deleteToast = () => toast.success("Empleado eliminado exitosamente.");
  const activateToast = () => toast.success("Empleado activado exitosamente.");

  return (
    <Box sx={{ width: "100%" }} className="mt-3">
      <Dialog
        open={openDeleteModal}
        onClose={closeDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"¿Borrar el registro de " + nameToDelete + "?"}
        </DialogTitle>
        <DialogContent className="alert-dialog-content">
          <DialogContentText id="alert-dialog-description">
            Se eliminará toda la información relacionada al Empleado(a).
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            ¿Desea Continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="alert-dialog-actions">
          <Button
            className="cancel-btn mx-5 px-5 py-2"
            onClick={closeDeleteModal}
          >
            Cancelar
          </Button>
          <Button
            className="delete-btn mx-5 px-5 py-2"
            onClick={deleteEmployee}
            autoFocus
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openActivateModal}
        onClose={closeActivateModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"¿Activar al empleado " + nameToDelete + "?"}
        </DialogTitle>
        <DialogContent className="alert-dialog-content">
          <DialogContentText id="alert-dialog-description">
            Se re-activará el Empleado y será visible en el sistema para los
            demás usuarios.
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            ¿Desea Continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="alert-dialog-actions">
          <Button
            className="cancel-btn mx-5 px-5 py-2"
            onClick={closeActivateModal}
          >
            Cancelar
          </Button>
          <Button
            className="activate-btn  mx-5 px-5 py-2"
            onClick={activateEmployee}
            autoFocus
          >
            Activar
          </Button>
        </DialogActions>
      </Dialog>

      <ToastContainer
        position="bottom-center"
        theme="colored"
        autoClose="4500"
        closeButton={false}
        closeOnClick
      />

      <Paper sx={{ width: "100%" }}>
        <TableContainer id="tbl-cont">
          <Table stickyHeader className="tbl">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {stableSort(rows, getComparator(order, orderBy)).map(
                (row, index) => {
                  const labelId = `table-${index}`;

                  return (
                    <TableRow
                      tabIndex={-1}
                      key={row.id_empleado}
                      className={
                        "row-style " + (index % 2 === 0 ? "" : "odd-row")
                      }
                    >
                      <TableCell id={labelId} align="center">
                        {row.id}
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title={row.status_label[0]}>
                          <Circle className={row.status_label[1]} />
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">{row.name}</TableCell>
                      <TableCell align="center">{row.last_name}</TableCell>
                      <TableCell align="center">{row.department}</TableCell>
                      <TableCell align="center">{row.job}</TableCell>
                      <TableCell align="center">{row.progress}%</TableCell>
                      <TableCell align="center">
                        <Tooltip title="Consultar">
                          <IconButton
                            size="small"
                            className="icn-btn"
                            onClick={() => onViewClick(row.id_empleado)}
                          >
                            <VisibilityOutlined fontSize="medium" />
                          </IconButton>
                        </Tooltip>

                        {row.status === 1 ? (
                          <Tooltip title="Validar">
                            <span>
                              <IconButton
                                size="small"
                                className="icn-btn-green"
                                onClick={() => onEditClick(row.id_empleado)}
                                disabled={
                                  props.userType ===
                                  "4" /* || row.status === 2 */
                                }
                              >
                                <TaskAlt fontSize="medium" />
                              </IconButton>
                            </span>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Editar">
                            <span>
                              <IconButton
                                size="small"
                                className="icn-btn"
                                onClick={() => onEditClick(row.id_empleado)}
                                disabled={
                                  props.userType ===
                                  "4" /* || row.status === 2 */
                                }
                              >
                                <EditOutlined fontSize="medium" />
                              </IconButton>
                            </span>
                          </Tooltip>
                        )}

                        {props.userType !== "3" &&
                        props.userType !== "4" &&
                        row.status === 6 ? (
                          <Tooltip title="Activar">
                            <IconButton
                              size="small"
                              className="icn-btn-green"
                              onClick={() =>
                                onActivateClick(
                                  row.id_empleado,
                                  row.name,
                                  row.last_name
                                )
                              }
                            >
                              <PublishedWithChangesOutlinedIcon fontSize="medium" />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Eliminar">
                            <span>
                              <IconButton
                                size="small"
                                className="icn-btn"
                                onClick={() =>
                                  onDeleteClick(
                                    row.id_empleado,
                                    row.name,
                                    row.last_name
                                  )
                                }
                                disabled={
                                  props.userType === "4" ||
                                  props.userType === "3"
                                }
                              >
                                <DeleteOutlined fontSize="medium" />
                              </IconButton>
                            </span>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default EmployeeTable;
