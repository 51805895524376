const validarCURP = (curp: string): [boolean, string] => {
  let mensajeError = "";
  let error = false;
  if (curp.length !== 18) {
    mensajeError = "La longitud de la CURP debe ser igual a 18";
    error = true;
  } else {
    const re =
      /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
    const validado = curp.match(re);
    if (validado !== null) {
      if (parseInt(validado[2]) !== digitoVerificador(validado[1])) {
        mensajeError = "La CURP digitada es inválida";
        error = true;
      } else {
        mensajeError = "";
        error = false;
      }
    } else {
      mensajeError = "El formato de la CURP es inválido";
      error = true;
    }
  }
  return [error, mensajeError];
};

/* Función para validar que coincida el dígito verificador */
function digitoVerificador(curp17: string): number {
  const diccionario = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ";
  let lngSuma = 0.0;
  let lngDigito = 0.0;
  for (let i = 0; i < 17; i++) {
    lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
  }
  lngDigito = 10 - (lngSuma % 10);
  if (lngDigito === 10) {
    return 0;
  }
  return lngDigito;
}

export default validarCURP;
