import { toast } from "react-toastify";

export const guardadoToast = () =>
  toast.success("Empleado agregado exitosamente.");

export const envioToast = () =>
  toast.success("Empleado enviado a validar exitosamente.");

export const migradoToast = () =>
  toast.success("Empleado migrado exitosamente.");
