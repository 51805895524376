import { ICompany } from "../../components/companies/ICompany";
import api from "../api.service";
interface ICompanyRequest {
  empresa_seleccionada: string;
}
const getEmpresas = async ({
  empresa_seleccionada,
}: ICompanyRequest): Promise<[empresas: ICompany[], empresaAlias: string]> => {
  const response = await api.get("get/empresas");
  const companySelected: ICompany = response.data.find(
    (company: ICompany) => company.id_empresa === empresa_seleccionada
  );
  return [response.data, companySelected.alias];
};
export default getEmpresas;
