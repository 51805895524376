import React, { useState } from "react";
import NavBar from "../navbar/NavBar";
import DatePickers from "./DatePickers";

import { Row, Col } from "react-bootstrap";
import { Typography } from "@mui/material";

import "./Empresas.css";
import CompanyCard from "./CompanyCard";
import getUserLocal, { IUser } from "../../utils/getUserLocal";
import { useHistory } from "react-router-dom";
// import { ICompany, ICompanyRequest } from "./ICompany";
import useGetEmpresas from "../../hooks/useGetEmpresas";

const Empresas = () => {
  const user: IUser = getUserLocal();

  // const [loading, setLoading] = useState<boolean>(true);
  // const [companies, setCompanies] = useState<ICompany[]>([]);
  const [companies, cargando] = useGetEmpresas(user.id);

  const [start, setStart] = useState<Date>(new Date());
  const [end, setEnd] = useState<Date>(new Date());

  const [errMssg, setErrMssg] = useState<string>("");

  const history = useHistory();

  // React.useEffect(() => {
  //   axios
  //     .post(API_PORTAL_URL + "empresas", {
  //       id_user: user.id,
  //     })
  //     .then((response) => {
  //       const comps: ICompany[] = response.data.map(
  //         (empresa: ICompanyRequest) => ({
  //           id_empresa: empresa.id_empresa,
  //           alias: empresa.alias,
  //           image: empresa.imagen_ruta ? empresa.imagen_ruta : "./hixsa.svg",
  //         })
  //       );
  //       setCompanies(comps);
  //       setLoading(false);
  //     })
  //     .catch((response) => {
  //       setErrMssg(response.response.data.message);
  //       setLoading(false);
  //     });
  // }, [user.id]);

  const updateDates = (dateStart: Date, dateEnd: Date): void => {
    setStart(dateStart);
    setEnd(dateEnd);
  };

  return (
    <div>
      <div className="fixed">
        <NavBar />

        <Row className="my-4">
          <Typography id="title" variant="h1" align="center">
            {" "}
            ORBEIT{" "}
          </Typography>
        </Row>

        <DatePickers onChange={updateDates} />
      </div>

      <Row className="py-md-0" />
      <Row className="my-5 my-md-0" />
      <Row className="my-5 my-md-0" />
      <Row className="my-5" />
      <Row className="my-5" />
      <Row className="my-5" />
      <Row className="my-5" />
      <Row className="my-5" />

      <Row className="mx-4 mb-4">
        {errMssg === "" ? (
          companies.map((empresa) => {
            return (
              <Col key={empresa.id_empresa} md={{ span: 4 }}>
                <CompanyCard
                  key={empresa.id_empresa}
                  empresa={empresa}
                  inicio={start}
                  fin={end}
                  companies={companies}
                />
              </Col>
            );
          })
        ) : (
          <Typography className="mt-5" variant="h5" align="center">
            {" "}
            {errMssg}{" "}
          </Typography>
        )}
      </Row>
    </div>
  );
};

export default Empresas;
