import React, { useState } from "react";
import NavBar from "../navbar/NavBar";
import Subnavbar from "../subnavbar/Subnavbar";
import { Row, Col } from "react-bootstrap";
import { Typography, Button } from "@mui/material";
import { useLocation } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import axios from "axios";
import { API_PORTAL_URL } from "../../constants";

import jwt_decode from "jwt-decode";

import "./Log.css";
import LogTable from "./LogTable";

import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";
import getUserLocal from "../../utils/getUserLocal";
import getEmpresas from "../../services/api/getEmpresas";

interface ILog {
  id: number;
  user: string;
  date: Date;
  employee: string;
  data: string;
  data_prev: string;
  move: string;
}

const Log = () => {
  const location: any = useLocation();
  const history = useHistory();

  // const user = JSON.parse(localStorage.getItem("user"));
  const user = getUserLocal();
  const [userType, setUserType] = useState("4");

  const [image, setImage] = useState("");

  const [errMssg, setErrMssg] = useState("");

  const id_empresa = location.search.replace("?/", "");

  const [LOGS, setLogs] = useState<ILog[]>([]);

  const now = new Date();
  const csvHeaders = [
    {
      label: "ID",
      key: "id",
    },
    {
      label: "Usuario",
      key: "user",
    },
    {
      label: "Fecha",
      key: "date",
    },
    {
      label: "Empleado",
      key: "employee",
    },
    {
      label: "Dato",
      key: "data",
    },
    {
      label: "Dato Anterior",
      key: "data_prev",
    },
    {
      label: "Movimiento",
      key: "move",
    },
  ];

  async function getImage(): Promise<void> {
    try {
      // const comps = location.state.companies;
      const [empresas] = await getEmpresas({
        empresa_seleccionada: id_empresa,
      });

      const emp = empresas.filter(
        (company) => company.id_empresa === id_empresa
      );
      setImage(emp[0].image);
      // return emp[0].image;
    } catch (err) {
      console.log(err);
    }
  }

  React.useEffect(() => {
    console.log("USE EFFECT");

    getImage();

    if (user) {
      const decodedToken: any = jwt_decode(user.accessToken);

      setUserType(decodedToken.rol);
    }

    axios
      .post(API_PORTAL_URL + "get/log", {
        id_empresa,
      })
      .then((response) => {
        const logs: ILog[] = [];

        for (let i = 0; i < response.data.length; i++) {
          const log = response.data[i];

          logs.push({
            id: log.id,
            user: log.user,
            date: new Date(log.fecha_log),
            employee: log.nombre + " " + log.apellido_paterno,
            data: log.dato ? log.dato : "-",
            data_prev: log.dato_anterior ? log.dato_anterior : "-",
            move: log.movimiento,
          });
        }

        setLogs(logs);
      })
      .catch((response) => {
        setErrMssg(response.response.data.message);
      });
  }, [id_empresa]);

  const onReturnClick = () => {
    history.push({
      pathname: "/empleados",
      search: "/" + id_empresa,
      state: {
        companies: location.state.companies,
      },
    });
  };

  return (
    <div>
      <div className="fixed">
        <NavBar />

        <Subnavbar image={image} />

        <Row>
          <Col className="p-0">
            <Button
              className="new-btn"
              onClick={onReturnClick}
              disabled={userType === "3" || userType === "4"}
            >
              <ArrowBackIcon className="mx-2" />
              Listado de Empleados
            </Button>
          </Col>
          <Col className="p-0" hidden={userType === "3" || userType === "4"}>
            <CSVLink
              data={LOGS}
              className="download-btn"
              filename={id_empresa + "-" + now.toISOString() + ".csv"}
              headers={csvHeaders}
              target="_blank"
            >
              <Button className="new-btn log-btn" disabled={LOGS.length === 0}>
                Descargar
                <FileDownloadOutlinedIcon className="mx-2" />
              </Button>
            </CSVLink>
          </Col>
        </Row>

        <Row>
          <Col>
            <Typography className="mt-4 log-title" variant="h4" align="center">
              {" "}
              Registro de Actividades{" "}
            </Typography>
          </Col>
        </Row>

        {errMssg === "" ? (
          <LogTable logs={LOGS} />
        ) : (
          <Typography className="mt-5" variant="h5" align="center">
            {" "}
            {errMssg}{" "}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default Log;
