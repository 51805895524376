const validarCorreo = (correo: string): boolean => {
  let correcto = false;
  if (
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
      correo
    )
  ) {
    correcto = true;
  }
  return correcto;
};

export default validarCorreo;
