import React from "react";
import { Row, Col } from "react-bootstrap";
import { Typography } from "@mui/material";
import { Circle } from "@mui/icons-material";

import "./InfoField.css";

const InfoField = (props) => {
  // console.log(props)

  const circle_color = (num) => {
    if (num === 0) {
      return "cir_gray";
    } else if (num === 1) {
      return "cir_green";
    } else if (num === 2) {
      return "cir_yellow";
    } else {
      return "cir_red";
    }
  };

  const col_width = props.status ? 5 : 12;

  return (
    <Col md={{ span: 12 }}>
      <Row>
        <Col md={{ span: col_width }} className="pt-1">
          <Typography variant="subtitle1" fontWeight="bold">
            {" "}
            {props.label}
            <span className="txt-data" hidden={props.status}>
              {" "}
              {props.data}{" "}
            </span>
          </Typography>
        </Col>

        <Col
          md={{ span: 5 }}
          align="center"
          className="pt-1"
          hidden={!props.status}
        >
          <Typography
            variant="subtitle1"
            fontWeight="bold"
            noWrap
            onClick={props.onClick}
          >
            <span className="txt-link"> {props.data} </span>
          </Typography>
        </Col>
        <Col md={{ span: 2 }} align="center" hidden={!props.status}>
          <Circle className={circle_color(props.status_num)} />
        </Col>
      </Row>
    </Col>
  );
};

export default InfoField;
