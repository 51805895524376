import axios from "axios";
import { API_PORTAL_URL } from "../constants";

const login = (username: string, password: string) => {
  return axios
    .post(API_PORTAL_URL + "auth/signin", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};

const logout = (): void => {
  localStorage.removeItem("user");
};

const recover = (
  username: string,
  actualPassword: string,
  newPassword: string
) => {
  return axios
    .post(API_PORTAL_URL + "auth/recover", {
      username,
      actualPassword,
      newPassword,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};

export default {
  login,
  logout,
  recover,
};
