import axios from "axios";
import getUserLocal from "../utils/getUserLocal";
import setLog from "../services/api/setLog";

export interface ILog {
  id_usuario: string;
  id_empresa: string;
  id_empleado: string;
  fecha_log: string;
  dato: string;
  dato_anterior: string;
  movimiento: string;
}

export const insertLog = (
  id_empresa: string,
  id_empleado: string,
  dato: string,
  dato_anterior: string,
  movimiento: string
) => {
  const user = getUserLocal();
  const user_id = user.id;

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const date = new Date();
  const fecha_log = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .replace("T", " ")
    .replace("Z", "");

  return setLog({
    id_usuario: user_id,
    id_empresa,
    id_empleado,
    fecha_log,
    dato,
    dato_anterior,
    movimiento,
  });
};
