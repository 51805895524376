const validarRFC = (
  rfc: string,
  aceptarGenerico: boolean = true
): [boolean, string] => {
  let mensajeError = "";
  let error = false;
  if (rfc.length !== 13) {
    mensajeError = "La longitud del RFC debe ser igual a 13";
    error = true;
  } else {
    const re =
      /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
    const validado = rfc.match(re);
    if (validado !== null) {
      // Separar el dígito verificador del resto del RFC
      const digitoVerificador = validado.pop() || "";
      const rfcSinDigito = validado.slice(1).join("");
      const len = rfcSinDigito.length;

      // Obtener el digito esperado
      const diccionario = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ";
      const indice = len + 1;
      let suma = 0;
      let digitoEsperado;
      for (let i = 0; i < len; i++) {
        suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
      }
      digitoEsperado = 11 - (suma % 11);
      if (digitoEsperado === 11) digitoEsperado = 0;
      else if (digitoEsperado === 10) digitoEsperado = "A";

      // El dígito verificador coincide con el esperado?
      // o es un RFC Genérico (ventas a público general)?
      if (
        parseInt(digitoVerificador) !== digitoEsperado &&
        (!aceptarGenerico ||
          rfcSinDigito + digitoVerificador !== "XAXX010101000")
      ) {
        mensajeError = "El formato del RFC es inválido";
        error = true;
      } else if (
        !aceptarGenerico &&
        rfcSinDigito + digitoVerificador === "XEXX010101000"
      ) {
        mensajeError = "El formato del RFC es inválido";
        error = true;
      }
    } else {
      mensajeError = "El formato del RFC es inválido";
      error = true;
    }
  }
  return [error, mensajeError];
};

export default validarRFC;
